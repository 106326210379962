import { useRecoilState } from 'recoil';
import Modal from '../Modal';
import { modalSelector } from '@stories/modal';
import { useCallback, useEffect } from 'react';

const ModalContainer = () => {
  const [modal, setModal] = useRecoilState(modalSelector);

  // esc키 입력시 모달 닫기
  const handleKeyEscapeCloseModal = useCallback(
    (e: KeyboardEvent) => {
      if (modal?.close && e.key === 'Escape') {
        modal.onClose && modal.onClose()
        setModal(null);
      }
    },
    [setModal, modal],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyEscapeCloseModal);

    return () => {
      document.removeEventListener('keydown', handleKeyEscapeCloseModal);
    };
  }, [handleKeyEscapeCloseModal]);

  return <Modal />;
};

export default ModalContainer;
