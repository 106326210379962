import styled from 'styled-components';

type Props = {
  templateColor?: string;
};

const Frame = ({ templateColor = '#ccc' }: Props) => {
  const adjustColor = (amount: number): string => {
    // Hex 코드 -> RGB
    const hexToRgb = (hex: string) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return { r, g, b };
    };

    // RGB 색상값을 Hex 코드로 변환
    const rgbToHex = (r: number, g: number, b: number) => {
      return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
    };

    // 색상값을 RGB로 변환
    const { r, g, b } = hexToRgb(templateColor);

    // 각 채널을 조정
    const adjustChannel = (value: number, amount: number) => Math.min(Math.max(0, value + amount), 255);

    // 새로운 색상값 계산
    const newR = adjustChannel(r, amount);
    const newG = adjustChannel(g, amount);
    const newB = adjustChannel(b, amount);

    // 새로운 색상값을 Hex 코드로 변환
    return rgbToHex(newR, newG, newB);
  };

  const generateColors = () => {
    const darkerColor = adjustColor(-30); // 어두운 색상

    return { darkerColor };
  };

  const { darkerColor } = generateColors();

  return (
    <Container>
      <svg width="304" height="428" viewBox="0 0 304 428" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_250_481)">
          <path d="M2 2H302L281.07 22.9113H22.9302L2 2Z" fill={templateColor} />
        </g>
        <path d="M2 2H302L281.07 22.9113H22.9302L2 2Z" stroke={darkerColor} />
        <g filter="url(#filter1_i_250_481)">
          <path d="M2 2L22.9302 22.9113V404.791L2 426.2V2Z" fill={templateColor} />
        </g>
        <path d="M2 2L22.9302 22.9113V404.791L2 426.2V2Z" stroke={darkerColor} />
        <g filter="url(#filter2_i_250_481)">
          <path d="M281.07 22.9113L302 2V426.2L281.07 405.289V22.9113Z" fill={templateColor} />
        </g>
        <path d="M281.07 22.9113L302 2V426.2L281.07 405.289V22.9113Z" stroke={darkerColor} />
        <g filter="url(#filter3_i_250_481)">
          <path d="M22.4319 405.289H281.07L302 426.2H2L22.4319 405.289Z" fill={templateColor} />
        </g>
        <path d="M22.4319 405.289H281.07L302 426.2H2L22.4319 405.289Z" stroke={darkerColor} />
        <defs>
          <filter
            id="filter0_i_250_481"
            x="0.792114"
            y="-3.5"
            width="302.416"
            height="26.9111"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-5" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_250_481" />
          </filter>
          <filter
            id="filter1_i_250_481"
            x="-3.5"
            y="0.793457"
            width="26.9302"
            height="426.633"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-5" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_250_481" />
          </filter>
          <filter
            id="filter2_i_250_481"
            x="280.57"
            y="0.793457"
            width="26.9302"
            height="426.613"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="5" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_250_481" />
          </filter>
          <filter
            id="filter3_i_250_481"
            x="0.812378"
            y="404.789"
            width="302.396"
            height="26.9111"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="5" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_250_481" />
          </filter>
          <linearGradient
            id="paint0_linear_250_481"
            x1="2"
            y1="12.4556"
            x2="302"
            y2="12.4556"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#585858" />
            <stop offset="1" stop-color="#BEBEBE" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
};

export default Frame;

const Container = styled.div<Props>`
  /* width: auto;
  height: auto; */

  width: calc(100% + 3px);
  height: calc(100% + 3px);

  position: absolute;
  top: -1px;
  left: -1px;
  right: 0;
  bottom: 0;

  border-radius: 2px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
