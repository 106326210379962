import { DeviceTypes } from '@typedef/Device/device.types';
import { atom, selector } from 'recoil';

const deviceListAtom = atom<DeviceTypes[]>({
  key: 'deviceListAtom',
  default: [],
});

export const deviceListSelector = selector<DeviceTypes[]>({
  key: 'deviceListSelector',
  get: ({ get }) => {
    return get(deviceListAtom);
  },
  set: ({ set }, value) => {
    set(deviceListAtom, value);
  },
});
