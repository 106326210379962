import React from 'react';
import * as S from './styles/VisitStyle';
import { Login } from '@assets/icons';

type Props = {
  onPrivacyPolicyButtonClicked: () => void;
};

const VisitHome = ({ onPrivacyPolicyButtonClicked }: Props) => {
  return (
    <S.ContentContainer>
      <S.Button onClick={onPrivacyPolicyButtonClicked}><Login />방문 등록</S.Button>
    </S.ContentContainer>
  );
};

export default VisitHome;
