export const REMOTE_PROD = 'cms-api-production.huclo.co.kr';
export const REMOTE_DEV = 'cms-api-staging.huclo.co.kr';
export const REMOTE_LOCAL = 'localhost:8888';

type EnvironmentMode = 'LOCAL' | 'STAGING' | 'PRODUCTION';

const API_ROOTS: Record<EnvironmentMode, string> = {
  LOCAL: REMOTE_LOCAL,
  STAGING: REMOTE_DEV,
  PRODUCTION: REMOTE_PROD,
};

export const apiRoot = API_ROOTS[process.env.REACT_APP_MODE as EnvironmentMode] || REMOTE_PROD;

export const MINIO_URL = 'https://api-minio.huclo.co.kr/cms';

export const API_URL = `${process.env.REACT_APP_MODE === 'LOCAL' ? 'http' : 'https'}://${apiRoot}`;
