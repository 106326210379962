import { useSearchParams } from 'react-router-dom';

export default function useSearch() {
  const [searchParams] = useSearchParams();

  const option = searchParams.get('option');
  const search = searchParams.get('search');

  return { option, search };
}
