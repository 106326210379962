export const isNumberFromString = (value: string, isFloating?: boolean) => {
  const REGEX_ONLY_NUMBER = isFloating ? /^(100|[1-9]?[0-9])$/ : /^[0-9]*$/;

  return REGEX_ONLY_NUMBER.test(value);
};

export const inputNumberOnly = (value: string): string => {
  return value.replace(/\D/g, '').replace(/^0+(\d)/, '$1');
};

export const inputPercentageValue = (value: string): number => {
  const replacedValue = Number(inputNumberOnly(value));

  if (replacedValue > 100) {
    return 100;
  } else {
    return replacedValue;
  }
};

export const formatBusinessNumberInput = (input: string): string => {
  const numbers = input.replace(/[^\d]/g, '');

  const truncated = numbers.slice(0, 10);

  if (truncated.length <= 3) {
    return truncated;
  } else if (truncated.length <= 5) {
    return `${truncated.slice(0, 3)}-${truncated.slice(3)}`;
  } else {
    return `${truncated.slice(0, 3)}-${truncated.slice(3, 5)}-${truncated.slice(5)}`;
  }
};

