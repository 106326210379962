import './styles/GNB.scss';
import { NavLink, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import GNBProfile from './components/GNBProfile';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { ECompanyType } from '@typedef/Company/company-type.enum';
import Logo from '@assets/images/logo.png';

const GNB = () => {
  const account = useRecoilValue(accountSelector);

  const NavLinkButton = ({ path, name }: { path: string; name: string }) => {
    return (
      <NavLink id={path} to={path}>
        {name}
      </NavLink>
    );
  };

  if (ECompanyType.YOUTH_WORKSHOP.equals(account.company.type))
    return (
      <>
        <header className="gnb">
          <section className="navs">
            <NavLink to="/main" className="logo">
              <img src={Logo} alt={'logo'} />
            </NavLink>
            <GNBProfile />
          </section>
        </header>
        <Outlet />
      </>
    );
  else
    return (
      <>
        <header className="gnb">
          <section className="navs">
            <NavLink to="/main" className="logo">
              <img src={Logo} alt={'logo'} />
            </NavLink>
            {(EAccountRole.MASTER.equals(account.role) || EAccountRole.MANAGER.equals(account.role)) &&
              (ECompanyType.YOUTH_WORKSHOP.equals(account.company.type) ||
                ECompanyType.MAKER_SPACE.equals(account.company.type)) && (
                <>
                  <NavLinkButton path="monitoring" name="통합관제모니터링" />
                  <NavLinkButton path="visit" name="방문관리" />
                </>
              )}
            <NavLinkButton key="schedules" path="schedules" name="스케줄링" />
            <NavLinkButton key="devices" path="devices" name="장비관리" />
            <NavLinkButton key="content-setting" path="content-setting" name="컨텐츠설정" />
            {(EAccountRole.ROOT.equals(account.role) || EAccountRole.MASTER.equals(account.role) || EAccountRole.MANAGER.equals(account.role)) && (
              <NavLinkButton path="accounts" name="계정관리" />
            )}
            {EAccountRole.ROOT.equals(account.role) && <NavLinkButton path="company" name="회사관리" />}
            {account.company.isCafe && <NavLinkButton path="manage-order" name="주문 관리" />}
            <GNBProfile />
          </section>
        </header>
        <Outlet />
      </>
    );
};

export default GNB;
