import RoutesNavigation from '@Routes/RoutesNavigation';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { theme } from '@styles/theme';
import { GlobalStyle } from '@styles/global';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { QueryClient, QueryClientProvider } from 'react-query';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.locale('ko');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const queryClient = new QueryClient();

const App = () => {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <QueryClientProvider client={queryClient}>
          <RoutesNavigation />
        </QueryClientProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default App;
