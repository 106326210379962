import styled from 'styled-components';
import { ContainerName } from '@components/CafeOrderList/styles/CafeOrdetListStyle';

export const OrderListContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .container {
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    width: calc(50% - 0.025rem);
    overflow: hidden;

    .order-number {
      width: 100%;
      height: 60px;
      background-color: #fff;
      box-shadow: rgb(125 125 125 / 10%) 0 1px 2px 0;
      border-radius: 8px;
      color: #191919;
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-size: 28px;
      font-weight: 600;
      margin-top: 15px;
    }
  }

  & > .divider {
    background-color: #ccc;
    height: 100%;
    width: 0.05rem;
  }

  .audio-play-button {
    display: none;
  }
`;

export const OrderListHeader = styled(ContainerName)`
  & > .text-header {
    height: 70px;
    font-size: 30px;

    & > svg {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }

    & > div {
        color:#000;
      height: 40px;
      margin-left: auto;
      font-size: 20px;
    }
  }
`;
