import ManageOrder from '@components/CafeOrderList/ManageOrder';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountSelector } from '@stories/account';
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  EOrderStatusType,
  OrderDetailTypes,
  OrderListTypes,
  orderStepList,
  OrderTypes,
  SelectedOrderTypes,
} from '@typedef/CafeOrderList/orderTypes';
import { apiRoute, requestGet, requestSecureDelete, requestSecurePatch, requestSecurePost } from '@libs/api';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { LongPressCallbackMeta, LongPressEventType, useLongPress } from 'use-long-press';
import dayjs from 'dayjs';
import { AbstractionNumbers } from '@libs/abstraction';
import { CompatClient, Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client/dist/sockjs';
import { API_URL } from '@libs/remote';
import { modalSelector } from '@stories/modal';
import NoticeModal from '@components/Common/NoticeModal/NoticeModal';

const ManageOrderContainer = () => {
  const { accessToken, company } = useRecoilValue(accountSelector);
  const setModal = useSetRecoilState(modalSelector);

  const [orderNumber, setOrderNumber] = useState<string>('');
  const [snackbarAlertState, setSnackbarAlertState] = useState<{
    open: boolean;
    message: string;
    type: 'success' | 'delete' | null;
  }>({
    open: false,
    message: '',
    type: null,
  });
  const [selectedOrder, setSelectedOrder] = useState<SelectedOrderTypes | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<OrderListTypes<boolean>>({
    PENDING: true,
    COMPLETED: true,
    PICKED_UP: true,
  });
  const [orderListData, setOrderListData] = useState<OrderListTypes<OrderTypes[]>>({
    PENDING: [],
    COMPLETED: [],
    PICKED_UP: [],
  });
  const [editOrderState, setEditOrderState] = useState<OrderDetailTypes | null>(null);
  const client = useRef<CompatClient>({} as CompatClient);

  const sendMessage = useCallback(
    (message: string) => {
      client.current.send(
        apiRoute.order.sendAlert(company.id),
        {},
        JSON.stringify({
          message: message,
        }),
      );

      setSnackbarAlertState({
        open: true,
        message: '공지가 등록 되었습니다.',
        type: 'success',
      });

      setModal(null);
    },
    [company.id],
  );

  const sendUpdateFlag = useCallback(() => {
    client.current.send(
      apiRoute.order.sendOrderFlag(company.id),
      {},
      JSON.stringify({
        flag: true,
      }),
    );
  }, [company.id]);

  const handleOpenModal = useCallback(() => {
    setModal({
      header: '공지',
      close: true,
      body: <NoticeModal onSubmit={sendMessage} />,
    });
  }, []);

  const loadOrderList = useCallback(
    async (status: EOrderStatusType) => {
      const startDate = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
      const endDate = dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

      const queryParams: Record<string, any> = {
        companyId: company.id,
        paged: false,
        status: status,
        startDate: startDate,
        endDate: endDate,
      };
      const { config, data } = await requestGet<PaginationTypes<OrderTypes>>(
        `${apiRoute.order.orderList}${parseQueryParamsToString(queryParams)}`,
        {},
      );

      if (config.status === 200) {
        setOrderListData((prev) => ({ ...prev, [status]: data.content }));
      }

      setLoadingStatus((prev) => ({ ...prev, [status]: false }));
    },
    [company.id],
  );

  const fetchHighestNumber = useCallback((): string => {
    const orderList = [...orderListData.PENDING, ...orderListData.COMPLETED, ...orderListData.PICKED_UP];

    if (orderList.length === 0) {
      return `${company.orderNumberPrefixValue ?? ''}${company.orderNumberIntValue}`;
    } else {
      const orderNumberList = orderList.map((order) => order.number);
      return `${company.orderNumberPrefixValue ?? ''}${Math.max(...AbstractionNumbers(orderNumberList)) + 1}`;
    }
  }, [company.orderNumberPrefixValue, orderListData]);

  const handleCreateOrder = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const { config, data } = await requestSecurePost<OrderTypes>(
        apiRoute.order.appendOrder,
        {},
        {
          number: company.orderNumberAutoGeneration ? fetchHighestNumber() : orderNumber,
        },
        accessToken,
      );

      if (config.status === 200) {
        loadOrderList(EOrderStatusType.PENDING);
        setSnackbarAlertState({
          open: true,
          message: `주문번호 [${data.number}] (이)가 추가되었습니다.`,
          type: 'success',
        });
        setOrderNumber('');
        sendUpdateFlag();
      }
    },
    [company.orderNumberAutoGeneration, fetchHighestNumber, orderNumber, accessToken, loadOrderList],
  );

  const handleDeleteOrder = useCallback(async () => {
    if (!selectedOrder) return;

    const selectedIds = selectedOrder.ids;

    const { config } = await requestSecureDelete(
      apiRoute.order.deleteOrders.replace('{ids}', selectedIds.join(',')),
      {},
      {},
      accessToken,
    );

    if (config.status === 200) {
      loadOrderList(selectedOrder.status);
      setSelectedOrder(null);
      setSnackbarAlertState({
        open: true,
        message: `${selectedIds.length}개의 주문이 삭제되었습니다.`,
        type: 'delete',
      });
      sendUpdateFlag();
    }
  }, [selectedOrder, accessToken]);

  const handleSwipeDeleteOrder = useCallback((orderId: string) => {
    requestSecureDelete(
      apiRoute.order.deleteOrders.replace('{ids}', orderId),
      {},
      {},
      accessToken,
    ).then(sendUpdateFlag);

    setOrderListData((prev) => {
      const newOrderListData = { ...prev };
      orderStepList.map((v) => {
        newOrderListData[v.status] = newOrderListData[v.status].filter((order) => order.id !== orderId);
      });
      return newOrderListData;
    });

    setSnackbarAlertState({
      open: true,
      message: '주문이 삭제되었습니다.',
      type: 'delete',
    });
  }, [accessToken]);

  const handleUpdateOrder = useCallback(
    async (id: string, number?: string, status?: EOrderStatusType): Promise<number> => {
      const { config } = await requestSecurePatch(
        apiRoute.order.updateOrder.replace('{id}', id),
        {},
        {
          ...(number && { number: number }),
          ...(status && { status: status }),
        },
        accessToken,
      );

      return config.status;
    },
    [accessToken],
  );

  const handleUpdateOrderList = useCallback(
    async (type: 'prev' | 'next') => {
      const currentIndex = orderStepList.findIndex((step) => step.status === selectedOrder?.status);
      const updateIndex = type === 'prev' ? currentIndex - 1 : currentIndex + 1;
      const { config } = await requestSecurePatch(
        apiRoute.order.updateOrderList,
        {},
        {
          ids: selectedOrder?.ids,
          status: orderStepList[updateIndex].status,
        },
        accessToken,
      );

      if (config.status === 200) {
        loadOrderList(orderStepList[currentIndex].status);
        loadOrderList(orderStepList[updateIndex].status);
        setSelectedOrder(null);
        setSnackbarAlertState({
          open: true,
          message: `${selectedOrder?.ids.length}개의 주문이 ${
            type === 'prev' ? '이전' : '다음'
          } 단계로 이동되었습니다.`,
          type: 'success',
        });
        sendUpdateFlag();
      }
    },
    [selectedOrder, accessToken],
  );

  const handleSelectOrder = useCallback(
    (status: EOrderStatusType, id: string) => {
      if (id !== editOrderState?.id || !editOrderState.open) {
        setSelectedOrder((prev) => {
          if (prev?.ids.includes(id)) {
            return {
              status: status,
              ids: prev.ids.filter((v) => v !== id),
            };
          }

          if (prev && prev.status === status) {
            return { status: status, ids: [...prev.ids, id] };
          }

          return { status: status, ids: [id] };
        });
      }
    },
    [editOrderState],
  );

  const handleSnackbarClose = useCallback(() => {
    setSnackbarAlertState((prevState) => ({ ...prevState, open: false }));
  }, []);

  const handleOrderStep = useCallback((e: React.MouseEvent<HTMLElement>, id: string, status: EOrderStatusType) => {
    e.stopPropagation();
    const currentIndex = orderStepList.findIndex((step) => step.status === status);
    const nextIndex = currentIndex + 1;

    handleUpdateOrder(id, undefined, orderStepList[nextIndex].status).then((res) => {
      if (res === 200) {
        loadOrderList(orderStepList[currentIndex].status);

        if (nextIndex < orderStepList.length) {
          loadOrderList(orderStepList[nextIndex].status);
        }

        setSnackbarAlertState({
          open: true,
          message: '주문이 다음 단계로 이동되었습니다.',
          type: 'success',
        });
        sendUpdateFlag();
      }
    });
  }, []);

  const handleEditNumber = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (editOrderState) {
        handleUpdateOrder(editOrderState.id, editOrderState.number).then((res) => {
          if (res === 200) {
            loadOrderList(editOrderState.status);
            setEditOrderState(null);
            setSnackbarAlertState({
              open: true,
              message: '주문번호가 수정되었습니다.',
              type: 'success',
            });
            sendUpdateFlag();
          }
        });
      }
    },
    [editOrderState],
  );

  const handleDetailEditOrder = useCallback(
    (value: OrderTypes, e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      if (!selectedOrder?.ids.includes(value.id)) {
        setEditOrderState({
          ...value,
          open: true,
        });
      }
    },
    [selectedOrder],
  );

  const longPressHandler = useLongPress(
    (_, meta: LongPressCallbackMeta) => handleDetailEditOrder(meta.context as OrderTypes),
    {
      threshold: 400,
      detect: LongPressEventType.Pointer,
      cancelOnMovement: false,
      captureEvent: true,
    },
  );

  const connectWebsocket = () => {
    client.current = Stomp.over(() => new SockJS(API_URL + '/websocket'));

    client.current.connect({},
      () => console.log('Connected'),
      () => {
        setTimeout(connectWebsocket, 5000);
      },
    );

    client.current.onDisconnect = () => {
      console.log('Disconnected');
      setTimeout(connectWebsocket, 5000);
    };
  };
  
  const handleSwipeProgress = useCallback((progress: number, orderId: string) => {
    if (progress > 60) {
      handleSwipeDeleteOrder(orderId);
    }
  }, []);

  useEffect(() => {
    connectWebsocket();
    orderStepList.map((v) => loadOrderList(v.status));
  }, []);

  useEffect(() => {
    if (selectedOrder && selectedOrder.ids.length === 0) {
      setSelectedOrder(null);
    }
  }, [selectedOrder]);

  return (
    <ManageOrder
      handleCreateOrder={handleCreateOrder}
      orderNumber={orderNumber}
      setOrderNumber={setOrderNumber}
      selectedOrder={selectedOrder}
      orderListData={orderListData}
      loadingStatus={loadingStatus}
      editOrderState={editOrderState}
      handleSelectOrder={handleSelectOrder}
      handleUpdateOrderList={handleUpdateOrderList}
      handleDeleteOrder={handleDeleteOrder}
      longPressHandler={longPressHandler}
      handleEditNumber={handleEditNumber}
      setEditOrderState={setEditOrderState}
      handleOrderStep={handleOrderStep}
      handleDetailEditOrder={handleDetailEditOrder}
      snackbarAlertState={snackbarAlertState}
      handleSnackbarClose={handleSnackbarClose}
      company={company}
      handleOpenModal={handleOpenModal}
      handleSwipeProgress={handleSwipeProgress}
    />
  );
};

export default ManageOrderContainer;
