import { DeviceImageMapTypes } from '@typedef/Device/device.types';
import { atom, selector } from 'recoil';

const deviceImageMapAtom = atom<DeviceImageMapTypes>({
  key: 'deviceImageAtom',
  default: new Map(),
});

export const deviceImageMapSelector = selector<DeviceImageMapTypes>({
  key: 'deviceImageSelector',
  get: ({ get }) => {
    return get(deviceImageMapAtom);
  },
  set: ({ set }, value) => {
    set(deviceImageMapAtom, value);
  },
});
