import { CompanyTypes, OrderNumberTypes, ScheduleOptionTypes } from '@typedef/Company/company.types';
import { AccountTypes, UpdatePasswordTypes } from '@typedef/Account/account.types';
import { ProfileContainer, ToggleSwitch } from './styles/ProfileStyle';
import { NoteBook, Profile as ProfileIcon, ScheduleIcon, SortAmountUp } from '@assets/icons';
import React, { FormEvent } from 'react';
import { EAccountRole } from '@typedef/Account/account-role.enum';

type Props = {
  company: CompanyTypes;
  account: AccountTypes;
  userName: string;
  handleChange: (userName: string) => void;
  byteToMegabyte: (bytes: number) => number;
  changeProfile: boolean;
  onClickUpdateProfile: () => void;
  fileFlush: () => void;
  orderNumberData: OrderNumberTypes;
  handleOrderNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changeOrderNumberData: boolean;
  updateOrderNumber: () => void;
  onClickUpdateScheduleOption: () => void;
  handleScheduleOptionChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  scheduleOption: ScheduleOptionTypes;
  updatePassword: (e: FormEvent) => void;
  handlePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  password: UpdatePasswordTypes;
};

const Profile = ({
  company,
  account,
  userName,
  handleChange,
  byteToMegabyte,
  changeProfile,
  onClickUpdateProfile,
  // fileFlush,
  orderNumberData,
  handleOrderNumberChange,
  changeOrderNumberData,
  updateOrderNumber,
  onClickUpdateScheduleOption,
  handleScheduleOptionChange,
  scheduleOption,
  updatePassword,
  handlePassword,
  password,
}: Props) => {
  return (
    <ProfileContainer>
      <article>
        <section>
          <div className="title-headline">
            <ProfileIcon style={{ width: '26px', height: '26px' }} />
            <p>유저 정보</p>
            <div className="highlighter" />
          </div>
          <div className="my-info">
            <div className="profile-data">
              <p className="title">아이디</p>
              <p className="value">{account.userId}</p>
            </div>
            <div className="profile-data">
              <p className="title">유저이름</p>
              <input
                value={userName}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                type="text"
              />
            </div>
            <div className="profile-data">
              <p className="title">권한</p>
              <p className="value">{account.role.name}</p>
            </div>
            <div className="profile-data">
              <p className="title">비밀번호 변경</p>
              <form className={'password-form'} onSubmit={updatePassword}>
                <input
                  placeholder={'현재 비밀번호'}
                  value={password.currentPassword}
                  onChange={handlePassword}
                  type={'password'}
                  required={true}
                  name={'currentPassword'}
                />
                <input
                  placeholder={'새 비밀번호'}
                  value={password.newPassword}
                  onChange={handlePassword}
                  type={'password'}
                  required={true}
                  minLength={4}
                  name={'newPassword'}
                />
                <div className={'btns'}>
                  <button>변경</button>
                </div>
              </form>
            </div>

            {changeProfile && (
              <div className={'btns'}>
                <button className="update" onClick={onClickUpdateProfile}>
                  수정
                </button>
              </div>
            )}
          </div>
        </section>

        <section>
          <div className="title-headline">
            <ScheduleIcon style={{ width: '26px', height: '26px' }} />
            <p>스케줄 미할당 화면</p>
            <div className="highlighter" />
          </div>
          <div className="my-info">
            <div className="profile-data">
              <p className="title">미할당 메시지</p>
              <textarea maxLength={40} value={scheduleOption?.text} placeholder={'스케줄 미할당 텍스트 입력'} name={'text'}
                        onChange={handleScheduleOptionChange} />
            </div>
            <div className="profile-data">
              <p className="title">미할당 텍스트 색상</p>
              <input
                value={scheduleOption?.fontColor}
                name={'fontColor'}
                type="color"
                onChange={handleScheduleOptionChange}
              />
            </div>
            <div className="profile-data">
              <p className="title">미할당 배경 색상</p>
              <input
                name={'backgroundColor'}
                value={scheduleOption?.backgroundColor}
                type="color"
                onChange={handleScheduleOptionChange}
              />
            </div>

            <div className={'btns'}>
              <button className="update" onClick={onClickUpdateScheduleOption}>
                저장
              </button>
            </div>
          </div>
        </section>
      </article>

      <article>
        <section>
          <div className="title-headline">
            <SortAmountUp />
            <p>장비 / 컨텐츠 사용량 ( 사용량 / 최대 )</p>
            <div className="highlighter" />
          </div>
          <div>
            <div className="profile-data">
              <p className="title">장비 생성 갯수</p>
              <p className="value">
                {company.currentDeviceCount} / {company.maxDeviceCount}개
              </p>
            </div>
            <div className="profile-data file">
              <p className="title">컨텐츠 파일 사이즈</p>
              <p className="value">
                <progress
                  id="fileSize"
                  max={byteToMegabyte(company.maxFileSize)}
                  value={byteToMegabyte(company.currentFileSize)}
                />

                <div className="visible-data">
                  <div className="profile-data">
                    <p className="title">총 사용량</p>
                    <p className="value">{byteToMegabyte(company.currentFileSize)} MB</p>
                  </div>
                  <div className="profile-data">
                    <p className="title">최대 사용량</p>
                    <p className="value">{byteToMegabyte(company.maxFileSize)} MB</p>
                  </div>
                </div>
              </p>
              <p className="value">
                {Math.round((byteToMegabyte(company.currentFileSize) / byteToMegabyte(company.maxFileSize)) * 100)}%
              </p>
            </div>

            {EAccountRole.accessRole(account.role) &&
              <div className="file-flush">
                <button className="button"
                  // onClick={() => fileFlush()}
                >
                  파일 정리
                </button>
              </div>
            }
          </div>
        </section>

        <section>
          {account.company.isCafe && (
            <>
              <div className="title-headline">
                <NoteBook />
                <p>주문 번호 관리</p>
                <div className="highlighter" />
              </div>

              <div>
                <div className="profile-data">
                  <p className="title">주문 번호 자동 생성 기능</p>

                  {EAccountRole.accessRole(account.role) &&
                    <ToggleSwitch>
                      <input
                        type="checkbox"
                        name={'orderNumberAutoGeneration'}
                        onChange={handleOrderNumberChange}
                        checked={orderNumberData.orderNumberAutoGeneration}
                      />
                      <div className="toggle-switch-background">
                        <div className="toggle-switch-handle" />
                      </div>
                    </ToggleSwitch>
                  }
                </div>

                {orderNumberData.orderNumberAutoGeneration && (
                  <div className="profile-data">
                    <input
                      type={'text'}
                      name={'orderNumberPrefixValue'}
                      placeholder={'주문번호 접두사 (선택)'}
                      onChange={handleOrderNumberChange}
                      value={orderNumberData.orderNumberPrefixValue ?? ''}
                      readOnly={!EAccountRole.accessRole(account.role)}
                    />
                    <input
                      type={'text'}
                      name={'orderNumberIntValue'}
                      placeholder={'주문번호 숫자 값 (필수)'}
                      className={'order-number-input'}
                      onChange={handleOrderNumberChange}
                      value={orderNumberData.orderNumberIntValue ?? ''}
                      readOnly={!EAccountRole.accessRole(account.role)}
                    />
                  </div>
                )}

                {EAccountRole.accessRole(account.role) && changeOrderNumberData && (
                  <div className={'btns save-order-number'} style={{ marginTop: '10px' }}>
                    <button className="update" onClick={updateOrderNumber}>
                      저장
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </section>
      </article>
    </ProfileContainer>
  );
};

export default Profile;
