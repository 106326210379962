import { Search } from '@assets/icons';
import { DatePickerStyle } from '@components/Common/DatePicker/styles/DatePickerStye';
import dayjs from 'dayjs';

type Props = {
  type?: 'common' | 'startedAt' | 'endedAt';
  value: string;
  minValue?: string;
  maxValue?: string;
  handleOnChange: (value: string) => void;
  handleOnSummit?: (value?: string) => void;
};

const DatePicker = ({ type = 'common', value, minValue, maxValue, handleOnChange, handleOnSummit }: Props) => {
  return (
    <DatePickerStyle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleOnSummit?.();
        }}
      >
        <div className="search-input">
          <label htmlFor="searchInput">
            <Search title="search" />
          </label>
          <input
            id="searchInput"
            type="date"
            min={minValue}
            max={maxValue}
            value={dayjs(value).format('YYYY-MM-DD')}
            onChange={(e) => {
              let date = dayjs(e.target.value);
              if (type === 'startedAt') {
                date = date.startOf('day');
              }
              if (type === 'endedAt') {
                date = date.endOf('day');
              }
              handleOnChange(date.toISOString());
            }}
          />
        </div>
      </form>
    </DatePickerStyle>
  );
};

export default DatePicker;
