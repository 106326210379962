export type ECompanyTypeProps = 'NORMAL' | 'YOUTH_WORKSHOP' | 'MAKER_SPACE';

export class ECompanyType {
  static readonly NORMAL: ECompanyType = new ECompanyType('NORMAL', '일반');
  static readonly YOUTH_WORKSHOP: ECompanyType = new ECompanyType('YOUTH_WORKSHOP', '청춘공작소');
  static readonly MAKER_SPACE: ECompanyType = new ECompanyType('MAKER_SPACE', '메이커스페이스');

  private constructor(
    public readonly value: ECompanyTypeProps,
    public readonly name: string,
  ) {}
  static readonly values = [ECompanyType.NORMAL, ECompanyType.YOUTH_WORKSHOP];

  static valueOf(value: ECompanyTypeProps | string): ECompanyType | undefined {
    return ECompanyType.values.find((v) => v.value === value);
  }

  static isValue(value: string): boolean {
    return !!ECompanyType.values.find((v) => v.value === value);
  }

  equals(value: ECompanyTypeProps | ECompanyType) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
