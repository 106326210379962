import { CustomCheckBoxStyle } from '@components/Common/CustomCheckBox/styles/CustomCheckBoxStyle';
import React from 'react';
import { Check } from '@assets/icons';

type Props = {
  label: string,
  checked?: boolean,
  onChange?: () => void;
}

const CustomCheckBox: React.FC<Props> = ({ label, checked, onChange }) => {
  const handleOnClick = () => {
    onChange && onChange();
  };
  
  return (
    <CustomCheckBoxStyle checked={checked} onClick={handleOnClick}>
      {label}
      <Check />
    </CustomCheckBoxStyle>
  );
};

export default CustomCheckBox;