import styled from 'styled-components';

export const PaginationContainer = styled.div`
  margin: 5vh auto;
  width: auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    cursor: pointer;
  }
`;

export const PaginationButton = styled.button<{ $active: boolean }>`
  margin: 0 10px;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  border: none;
  font-size: 20px;
  transition:
    background 0.25s,
    color 0.25s;
  background-color: ${({ $active }) => ($active ? '#256662' : '#E6E6E6')};
  color: ${({ $active }) => ($active ? '#fff' : '#555')};
`;
