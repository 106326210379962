import styled from 'styled-components';

export const Container = styled.main`
    width: 600px;

    .delete {
        p {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }
    }

    .split {
        ${({ theme }) => theme.flex.row};
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .title {
            span {
                color: red;
            }

            font-weight: 600;
        }
    }
`;

export const Input = styled.div<{ readonly?: boolean }>`
    input {
        border-radius: 4px;
        width: 250px;
        border: 1px solid #ccc;
        padding: 10px 15px;
        background-color: ${({ readonly }) => readonly ? '#eee' : '#fcfcfc'};
        font-size: 16px;
        text-align: left;

        &:focus {
            outline: none;
        }
    }

    .info-message {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
        margin-top: 5px;

        & > svg {
            width: 15px;
            height: 15px;
            margin-right: 3px;
        }
    }

    .input-btn {
        width: 250px;
        display: flex;
        flex-direction: row;
        border: 1px solid #333;
        border-radius: 3px;

        input {
            flex: 3;
            min-width: 150px;
            padding: 5px;
            border: none;

            &:focus {
                outline: none;
            }
        }

        button {
            flex: 2;
            width: 100px;
            height: 100%;
            padding: 5px 0;
            border: none;
            background-color: #256662;
            color: #fff;

            &:active {
                opacity: 0.7;
            }
        }
    }
`;
