import ReactGridLayout from 'react-grid-layout';
import * as S from './styles/WidgetStyle';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { ArrowLeftBlue, ArrowLeftWhite, ArrowRightBlue, Delete, Disable, Plus, Save } from '@assets/icons';
import React, { MutableRefObject } from 'react';
import { ContentTypes, EBackgroundType } from '@typedef/Contents/contents.types';
import CustomContainer from './containers/CustomContainer';
import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import CustomContentContainer from './containers/CustomContentContainer';
import { SelectBoardPostType, WIDGET_LIST, WidgetTypes } from '@typedef/Widget/widget.types';
import { getWidgetIcon } from '@libs/getWidgetIcon';
import CustomWidgetContainer from './containers/CustomWidgetContainer';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import ReactDropdown, { Option } from 'react-dropdown';
import { DropdownStyle } from '@styles/dropdownStyle';
import FileWidget from '@components/Widgets/components/FileWidget';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { StyledTooltip } from '@components/Main/styles/MainStyle';
import CustomPostContainer from '@components/Widgets/containers/CustomPostContainer';
import Slider from 'react-slick';

type Props = {
  fileDropBackground: string | null;
  setFileDropBackground: React.Dispatch<React.SetStateAction<string | null>>;
  selectContentLayoutList: (selectContentId: string) => void;
  contentList: ContentTypes[];
  selectContent: ContentTypes | null;
  selectPost: SelectBoardPostType | null;
  setSelectPost: React.Dispatch<React.SetStateAction<SelectBoardPostType | null>>;
  saveContent: () => void;
  handleLayoutChange: (newLayout: WidgetTypes[]) => void;
  layout: WidgetTypes[];
  layoutRef: React.RefObject<HTMLDivElement>;
  selectLayout: WidgetTypes | null;
  setSelectLayout: React.Dispatch<React.SetStateAction<WidgetTypes | null>>;
  onAddLayout: (type: EWidgetType) => void;
  handleLayoutDelete: () => void;
  onDropFileLayout: (file: File) => void;
  onClickModalOpen: (type: 'add' | 'delete') => void;
  handleOnChange: (id: string, value: any) => void;
  onContentOptionChange: React.Dispatch<React.SetStateAction<ContentTypes | null>>;
  resolution: number;
  customDisplaySize: { width: number; height: number };
  handleWidgetOrder: (widget: WidgetTypes, type: 'front' | 'back') => void;
  WidgetButtonContainerRef: React.RefObject<HTMLDivElement>;
  handleScroll: (direction: 'left' | 'right') => void;
  isScrolled: { isScrolledLeft: boolean; isScrolledRight: boolean };
  onCreateFileSmartBoard: (file: File) => Promise<string | undefined>;
  sliderRef: MutableRefObject<Slider | null>;
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>;
  activeSlide: number;
};

const Widget = ({
  fileDropBackground,
  setFileDropBackground,
  selectContentLayoutList,
  contentList,
  selectContent,
  selectPost,
  setSelectPost,
  saveContent,
  handleLayoutChange,
  layout,
  layoutRef,
  selectLayout,
  setSelectLayout,
  onAddLayout,
  handleLayoutDelete,
  onDropFileLayout,
  onClickModalOpen,
  handleOnChange,
  onContentOptionChange,
  resolution,
  customDisplaySize,
  handleWidgetOrder,
  WidgetButtonContainerRef,
  handleScroll,
  isScrolled,
  onCreateFileSmartBoard,
  sliderRef,
  setActiveSlide,
  activeSlide,
}: Props) => {
  const {
    role: ROLE,
    company: { isCafe },
  } = useRecoilValue(accountSelector);

  return (
    <>
      <S.Header>
        <DropdownStyle width={'270px'} fontSize={'20px'} height={'40px'}>
          <ReactDropdown
            className="react-dropdown-main"
            menuClassName="react-dropdown-menu"
            controlClassName="react-dropdown-control"
            options={contentList.map((v) => {
              return { label: v.name, value: v.id.toString() };
            })}
            onChange={(data: Option) => {
              selectContentLayoutList(data.value);
            }}
            value={selectContent ? selectContent.id.toString() : undefined}
            placeholder={'컨텐츠를 선택해주세요'}
            arrowOpen={undefined}
            arrowClosed={<div></div>}
          />
        </DropdownStyle>
        <div className="btns">
          {EAccountRole.accessRole(ROLE) ? (
            <S.Button
              backgroundColor="#15b9f5"
              width={'130px'}
              onClick={() => {
                onClickModalOpen('add');
              }}
            >
              <Plus />새 컨텐츠
            </S.Button>
          ) : (
            <StyledTooltip title={'해당 기능에 접근 권한이 없습니다.'} placement={'bottom'}>
              <S.Button backgroundColor="#15b9f5" width={'130px'} disabled={true}>
                <Plus />새 컨텐츠
              </S.Button>
            </StyledTooltip>
          )}
          {selectContent && selectContent?.id && EAccountRole.accessRole(ROLE) && (
            <>
              <S.Button
                backgroundColor="#2b3e63"
                onClick={() => {
                  saveContent();
                }}
                width={'110px'}
                id="layoutSave"
              >
                <Save title="save" />
                <p>저장</p>
              </S.Button>

              <S.Button
                backgroundColor="#F35D3C"
                width={'110px'}
                onClick={() => {
                  onClickModalOpen('delete');
                }}
              >
                <Delete title="delete" />
                <p>삭제</p>
              </S.Button>
            </>
          )}
        </div>
      </S.Header>
      <S.Container ref={layoutRef}>
        <S.WidgetContainer>
          <button
            className={'scroll-button'}
            onClick={() => handleScroll('left')}
            disabled={!isScrolled.isScrolledLeft}
          >
            <ArrowLeftBlue />
          </button>
          <S.WidgetButtonContainer
            ref={WidgetButtonContainerRef}
            $isScrolledLeft={isScrolled.isScrolledLeft}
            $isScrolledRight={isScrolled.isScrolledRight}
          >
            {WIDGET_LIST.map((widget) => {
              if (widget.type.equals(EWidgetType.NEWS) || widget.type.equals(EWidgetType.WEATHER)) {
                if (EAccountRole.USER.equals(ROLE)) return <></>;
              }

              if (widget.type.equals(EWidgetType.ORDER_LIST)) {
                if (!isCafe) return <></>;
              }

              return (
                <S.WidgetButton
                  onClick={() => {
                    if (!EAccountRole.accessRole(ROLE)) {
                      alert('해당 기능에 접근 권한이 없습니다.');
                    } else if (!widget.type.equals(EWidgetType.MULTIMEDIA)) {
                      onAddLayout(widget.type);
                    }
                  }}
                >
                  {EAccountRole.accessRole(ROLE) && widget.type.equals(EWidgetType.MULTIMEDIA) && selectContent && (
                    <>
                      <input type={'file'} id={'file'} onChange={(e) => onDropFileLayout(e.target.files![0])} />
                      <label htmlFor={'file'} />
                    </>
                  )}
                  {getWidgetIcon(widget.type)}
                  <div className={'widget-title'}>{widget.name}</div>
                </S.WidgetButton>
              );
            })}
          </S.WidgetButtonContainer>
          <button
            className={'scroll-button'}
            onClick={() => handleScroll('right')}
            disabled={!isScrolled.isScrolledRight}
          >
            <ArrowRightBlue />
          </button>
        </S.WidgetContainer>
        <S.MainCustomContainer
          onDragOver={(e) => {
            setFileDropBackground('#e0f1eb');
            e.preventDefault();
            return;
          }}
          onDrop={(e) => {
            setFileDropBackground(null);
            onDropFileLayout(e.dataTransfer.files[0]);
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            setFileDropBackground(null);
            e.preventDefault();
            return;
          }}
        >
          <S.MainBoard>
            <S.WidgetBoard
              backgroundColor={fileDropBackground ?? selectContent?.backgroundColor}
              width={customDisplaySize.width}
              height={customDisplaySize.height}
              backgroundTheme={
                selectContent?.backgroundType === EBackgroundType.THEME ? selectContent?.backgroundTheme : undefined
              }
            >
              {selectContent && (
                <S.CustomGridLayout
                  layout={layout as ReactGridLayout.Layout[]}
                  isResizable={true}
                  isDraggable={true}
                  cols={selectContent.w / resolution}
                  width={customDisplaySize.width}
                  maxRows={selectContent.h / resolution}
                  rowHeight={customDisplaySize.height / (selectContent.h / resolution)}
                  margin={[0, 0]}
                  verticalCompact={false}
                  allowOverlap={true}
                  onDragStart={(_, oldItem) => {
                    setSelectLayout(layout.find((i) => i.i === oldItem.i) ?? null);
                    setSelectPost(null);
                  }}
                  onDragStop={(layout) => {
                    handleLayoutChange(layout as WidgetTypes[]);
                  }}
                  onResizeStop={(layout) => {
                    handleLayoutChange(layout as WidgetTypes[]);
                  }}
                >
                  {layout.map((data) => (
                    <S.Widget
                      id={data.i}
                      key={data.i}
                      selected={selectLayout?.i === data.i}
                      styleOption={data}
                      fontRatio={customDisplaySize.width / selectContent.w}
                      resizable={data.isResizeable}
                    >
                      {data.type.equals(EWidgetType.FILE) || data.type.equals(EWidgetType.FILE_LIST) ? (
                        <FileWidget widget={data} />
                      ) : data.type.equals(EWidgetType.TIME) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : data.type.equals(EWidgetType.FINEDUST) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : data.type.equals(EWidgetType.WEATHER) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : data.type.equals(EWidgetType.STOCK_INDEX) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : data.type.equals(EWidgetType.SMART_BOARD) ? (
                        <CustomWidgetContainer widget={data} content={selectContent} handleOnChange={handleOnChange}
                                               selectPost={selectPost} setSelectPost={setSelectPost}
                                               isSetting={true} sliderRef={sliderRef} activeSlide={activeSlide}
                                               setActiveSlide={setActiveSlide} />
                      ) : data.type.equals(EWidgetType.IFRAME) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : data.type.equals(EWidgetType.ORDER_LIST) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : (
                        <div className="input" key={data.i}>
                          <S.WidgetText
                            placeholder="글을 입력하세요."
                            spellCheck={false}
                            value={data.text}
                            onChange={(e) => {
                              handleOnChange('text', e.target.value);
                            }}
                            rows={(data?.text?.match(/\n/g)?.filter((item) => item !== '').length ?? 0) + 1}
                            readOnly={data.type.equals(EWidgetType.NEWS)}
                          />
                        </div>
                      )}
                    </S.Widget>
                  ))}
                </S.CustomGridLayout>
              )}
            </S.WidgetBoard>
          </S.MainBoard>
          <S.CustomBoard>
            {selectContent && !EAccountRole.accessRole(ROLE) && (
              <div className={'disabled'}>
                <Disable />
                해당 기능에 접근 권한이 없습니다.
              </div>
            )}
            <div className={'content-setting'}>
              <div>
                {
                  selectPost ? (
                      <div className={'title-text'}>게시물 설정</div>
                    ) :
                    selectLayout ? (
                      <div className={'title-text'}>위젯 설정</div>
                    ) : (
                      selectContent && <div className={'title-text'}>컨텐츠 설정</div>
                    )}
                {
                  selectPost ? (
                      <CustomPostContainer
                        onCreateFileSmartBoard={onCreateFileSmartBoard}
                        setSelectPost={setSelectPost}
                        selectPost={selectPost}
                        selectLayout={selectLayout}
                        activeSlide={activeSlide}
                        handleOnChange={handleOnChange}
                      />
                    ) :
                    selectLayout ? (
                      <CustomContainer
                        selectLayout={selectLayout}
                        selectContent={selectContent}
                        layout={layout}
                        handleOnChange={handleOnChange}
                        customDisplaySize={customDisplaySize}
                        sliderRef={sliderRef}
                        activeSlide={activeSlide}
                        setActiveSlide={setActiveSlide}
                      />
                    ) : (
                      selectContent && (
                        <CustomContentContainer
                          onContentOptionChange={onContentOptionChange}
                          selectContent={selectContent}
                        />
                      )
                    )}
              </div>
              {selectLayout && (
                <div>
                  <S.CustomOrderArea>
                    <S.Button className={'z-index-button'} backgroundColor={'#2b3e63'}
                              onClick={() => handleWidgetOrder(selectLayout, 'front')}>
                      <ArrowLeftWhite className="up-arrow" />
                      <p>앞으로<br />가져오기</p>
                    </S.Button>
                    <S.Button className={'z-index-button'} backgroundColor={'#2b3e63'}
                              onClick={() => handleWidgetOrder(selectLayout, 'back')}>
                      <ArrowLeftWhite className="down-arrow" />
                      <p>뒤로<br />보내기</p>
                    </S.Button>
                  </S.CustomOrderArea>
                  <S.Button backgroundColor={'#f0614f'} onClick={handleLayoutDelete}>
                    <Delete title="delete" />
                    <p>위젯 삭제</p>
                  </S.Button>
                </div>
              )}
            </div>
          </S.CustomBoard>
        </S.MainCustomContainer>
      </S.Container>
    </>
  );
};

export default Widget;
