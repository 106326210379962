import { WidgetTypes } from '@typedef/Widget/widget.types';
import React, { useEffect, useState } from 'react';
import { apiRoute, requestGet } from '@libs/api';
import { FineDustBad, FineDustCommon, FineDustGood, FineDustVeryBad } from '@assets/icons';
import FineDust from '@components/Common/FineDust/FineDust';
import { getResolutionValue } from '@libs/getResolutionValue';
import { ContentTypes } from '@typedef/Contents/contents.types';

type Props = {
  size: {
    width: number;
    height: number;
  };
  isSetting: boolean;
  widget: WidgetTypes;
  content: ContentTypes;
};

export type pmGradeType = {
  pm10Grade: number | undefined;
  pm25Grade: number | undefined;
  pm10Grade1h: number | undefined;
  pm25Grade1h: number | undefined;
};

export type pmValueType = {
  pm10Value: number | undefined;
  pm25Value: number | undefined;
};

const FineDustContainer = ({ size, isSetting, widget, content }: Props) => {
  const [pmGrade, setPmGrade] = useState<pmGradeType>({
    pm10Grade1h: undefined,
    pm25Grade1h: undefined,
    pm10Grade: undefined,
    pm25Grade: undefined,
  });

  const [pmValue, setPmValue] = useState<pmValueType>({ pm10Value: undefined, pm25Value: undefined });
  const [loading, setLoading] = useState<boolean>(true);

  const getFineDustData = async () => {
    const { data, config } = await requestGet<any>(
      apiRoute.weather.getFineDustWithoutJWT.replace('{weatherId}', String(widget.weatherId)),
      {},
    );

    if (config) {
      setPmGrade({
        pm10Grade: Number(data.pm10Grade),
        pm25Grade: Number(data.pm25Grade),
        pm10Grade1h: Number(data.pm10Grade1h),
        pm25Grade1h: Number(data.pm25Grade1h),
      });

      setPmValue({
        pm10Value: Number(data.pm10Value),
        pm25Value: Number(data.pm25Value),
      });
    }
  };

  useEffect(() => {
    getFineDustData().then(() => setLoading(false));
  }, []);

  const getIcon = (grade?: number): React.ReactElement | undefined => {
    switch (grade) {
      case 1:
        return <FineDustGood />;
      case 2:
        return <FineDustCommon />;
      case 3:
        return <FineDustBad />;
      case 4:
        return <FineDustVeryBad />;
    }
  };

  const getText = (grade?: number): string | undefined => {
    switch (grade) {
      case 1:
        return '좋음';
      case 2:
        return '보통';
      case 3:
        return '나쁨';
      case 4:
        return '매우 나쁨';
      default:
        return '조회 정보 없음';
    }
  };
  return (
    <FineDust
      size={size}
      pmGrade={pmGrade}
      pmValue={pmValue}
      loading={loading}
      getIcon={getIcon}
      getText={getText}
      isSetting={isSetting}
      resolution={getResolutionValue(widget.resolution)}
      content={content}
    />
  );
};

export default FineDustContainer;
