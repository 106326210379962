import React, { useState } from 'react';
import * as S from '@components/CafeOrderList/styles/CafeOrdetListStyle';

type Props = {
  onSubmit: (message: string) => void;
};

const NoticeModal: React.FC<Props> = ({ onSubmit }) => {
  const [message, setMessage] = useState<string>('');

  return (
    <S.NoticeModalStyle>
      <textarea placeholder={'공지를 작성해 주세요.'} onChange={(e) => setMessage(e.target.value)} value={message} />
      <button onClick={() => onSubmit(message)}>등록</button>
    </S.NoticeModalStyle>
  );
};

export default NoticeModal;
