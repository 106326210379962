import styled from 'styled-components';
import React, { ReactNode } from 'react';
import Frame from './components/Frame';
import Pin from './components/Pin';
import Tape from './components/Tape';
import { EPostTemplateType } from '@typedef/Widget/widget.types';

type Props = {
  children: ReactNode;
  template?: EPostTemplateType;
  templateColor?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const WidgetTemplate = ({ children, template, templateColor = 'red', onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      {children}
      {template === EPostTemplateType.FRAME && <Frame templateColor={templateColor} />}
      {template === EPostTemplateType.PIN && <Pin templateColor={templateColor} />}
      {template === EPostTemplateType.TAPE && <Tape templateColor={templateColor} />}
    </Container>
  );
};

export default WidgetTemplate;

const Container = styled.div`
  width: 100%;
  height: 100%;

  /* width: auto; */
  aspect-ratio: 1 / 1.414;

  position: relative;
`;
