import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Colors } from 'chart.js';
type Props = {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
    }[];
  };
};

const PieChart = ({ data: { labels, datasets } }: Props) => {
  return (
    <Pie
      data={{
        labels,
        datasets: datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: ['#FF6384', '#FF9E40', '#FFCD56', '#73db9b', '#54c7c7', '#37A2EB', '#4f7fff', '#9A66FF'], // 추후 컬러 추가하면 될 듯
        })),
      }}
      options={{
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              boxWidth: 30,
              font: {
                size: 10,
              },
            },
          },
          title: {
            display: false,
          },
        },
      }}
    />
  );
};

export default PieChart;
