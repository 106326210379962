import Pagination from '@components/Common/Pagination/Pagination';
import { StyledStatusAndPlaybackHistory } from '@components/Monitoring/styles/MonitoringStyle';
import { DeviceTypes } from '@typedef/Device/device.types';
import { ZepetoLogType } from '@typedef/Monitoring/monitoring.type';
import { PageTypes } from '@typedef/libs/pagination.types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';

type Props = {
  deviceList: DeviceTypes[];
  zepetoLogs: ZepetoLogType[];
  loading: { device: boolean; zepeto: boolean };
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  handleDevicePower: (currentStatus: EDeviceStatus, powerOn: EDeviceStatus, ip: string) => void;
};

const StatusAndPlaybackHistory: React.FC<Props> = ({
  deviceList,
  zepetoLogs,
  loading,
  pageInfo,
  currentPage,
  setCurrentPage,
  onPageChange,
  handleDevicePower,
}) => {
  const navigate = useNavigate();

  return (
    <StyledStatusAndPlaybackHistory>
      <div>
        <div className={'title'}>
          장비현황
          <button className={'detail-button'} onClick={() => navigate('/devices')}>
            상세정보
          </button>
        </div>

        {loading.device ? (
          <div className={'status-loading'}>장비 현황을 조회중입니다...</div>
        ) : (
          Array.from({ length: Math.ceil(deviceList.length / 2) }, (_, i) => (
            <div className="device" key={i}>
              {deviceList.slice(i * 2, i * 2 + 2).map((device) => (
                <div key={device.uniqueKey}>
                  <h3>{device.name}</h3>
                  <div className="power-container">
                    {device.status.equals(EDeviceStatus.REQUEST_OFF) ||
                    device.status.equals(EDeviceStatus.REQUEST_ON) ? (
                      <div className={'pending-text'}>{`전원을 ${
                        device.status.equals(EDeviceStatus.REQUEST_ON) ? '키는' : '끄는'
                      } 중입니다.`}</div>
                    ) : (
                      <>
                        <div className="text-container">
                          전원 <span>{device.status.value}</span>
                        </div>
                        <div className="button-container">
                          <button
                            className="button-on"
                            onClick={() => handleDevicePower(device.status, EDeviceStatus.REQUEST_ON, device.ip)}
                          >
                            켜기
                          </button>
                          <button
                            className="button-off"
                            onClick={() => handleDevicePower(device.status, EDeviceStatus.REQUEST_OFF, device.ip)}
                          >
                            끄기
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
      <div>
        <div className={'title'}>컨텐츠 재생 내역</div>
        <div className={'play-history-container'}>
          {loading.zepeto ? (
            <div className={'status-loading'}>컨텐츠 재생 내역을 조회중입니다...</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>일시</th>
                  <th>재생 횟수</th>
                </tr>
              </thead>

              <tbody>
                {zepetoLogs.map((value) => (
                  <tr key={value.id}>
                    <td>{value.createdAt.split('T')[0]}</td>
                    <td>{value.peopleCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <Pagination
            onPageChange={onPageChange}
            pageInfo={pageInfo}
            isPlayHistory={true}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </StyledStatusAndPlaybackHistory>
  );
};

export default StatusAndPlaybackHistory;
