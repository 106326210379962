import styled, { css } from 'styled-components';

export const LoginCompanyModalStyle = styled.div<{ loading: boolean }>`
    width: 100%;
    height: 600px;
    background-color: #fff;
    overflow: auto;
    border-radius: 10px;

    ${({ loading }) => loading && css`
        display: flex;
        justify-content: center;
        align-items: center
    `};

    .empty-list {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
    }
`;

export const CompanyList = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #E5E5E5;
    padding: 0 15px;
    box-sizing: border-box;
    cursor: pointer;

    .company-info {
        margin-left: 10px;

        & > div {
            &:first-child {
                font-size: 18px;
                font-weight: 600;
            }

            &:last-child {
                margin-top: 10px;

                & > * {
                    font-size: 14px;
                    font-weight: 500;
                }

                & > span {
                    color: #999;
                }
            }
        }
    }

    .icon-container {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #ddf6ff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        & > svg {
            width: 20px;
            height: 20px;

            path {
                fill: #15b9f5;
            }
        }
    }

    & > button {
        margin-left: auto;
        width: 70px;
        height: 30px;
        border-radius: 5px;
        border: none;
        background-color: #15b9f5;
        color: #fff;
    }
`;