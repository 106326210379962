import { EAgeGroup } from '@typedef/Visit/age-group.enum';
import { EGender } from '@typedef/Visit/gender.enum';
import { defaultWeatherTypes, WeatherTypes } from '@typedef/Widget/weather.types';
import { EVisitType } from './visit-type.enum';

export type VisitorType = {
  id: string;
  createdAt: string;
  ageGroup: EAgeGroup;
  companionCount: number;
  companyId: string;
  gender: EGender;
  isLivingInUiseong: boolean;
  visitType: EVisitType;
  weather: WeatherTypes;
};

export type VisitorRequestType = Omit<
  VisitorType,
  'id' | 'createdAt' | 'weather' | 'companyId' | 'ageGroup' | 'gender' | 'visitType'
> & {
  weatherId: string;
  subWeatherId?: string;
  gender: string;
  ageGroup: string;
  visitType: string;
};

export type VisitorResponseType = Omit<VisitorType, 'ageGroup' | 'gender'> & {
  ageGroup: string;
  gender: string;
  visitType: string;
};

export const defaultVisitorType: VisitorType = {
  id: '',
  ageGroup: EAgeGroup.UNDER_10,
  isLivingInUiseong: false,
  gender: EGender.MALE,
  weather: defaultWeatherTypes,
  visitType: EVisitType.TRAVEL,
  companionCount: 0,
  companyId: '',
  createdAt: '',
};

export const defaultVisitorRequestType: VisitorRequestType = {
  ageGroup: EAgeGroup.UNDER_10.value,
  isLivingInUiseong: false,
  gender: EGender.MALE.value,
  weatherId: '',
  visitType: '',
  companionCount: 0,
};
