import styled, { css } from 'styled-components';
import { Header } from '@components/Devices/styles/DevicesStyle';
import { EOrderStatusType } from '@typedef/CafeOrderList/orderTypes';
import { SwipeableListItem } from 'react-swipeable-list';

const getCafeOrderStatusColor = (status: EOrderStatusType): string => {
  switch (status) {
    case EOrderStatusType.PENDING:
      return '#15B9F5';
    case EOrderStatusType.COMPLETED:
      return '#FFA800';
    case EOrderStatusType.PICKED_UP:
      return '#5EBA21';
  }
};

export const Container = styled.main`
    width: 100%;
    height: calc(100vh - 220px);
    min-width: 720px;

    @media (max-width: 1200px) {
        padding: 0 30px;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const MainHeader = styled(Header)`
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;

    & > div {
        & > div {
            &:nth-child(1) {
                width: 170px;
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 5px;
                background: linear-gradient(to top, #15b9f566 70%, transparent 50%);
            }

            &:nth-child(2) {
                margin-bottom: 4px;
            }

            &:nth-child(n + 2) {
                font-size: 12px;
                color: #999;
            }
        }
    }

    & > form {
        margin-left: auto;
        width: 40%;
        min-width: 450px;
        max-width: 650px;
        height: 50px;
        display: flex;

        & > .input-container {
            height: 100%;
            width: calc(100% - 110px);
            box-sizing: border-box;
            border-radius: 5px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f3f3f3;

            & > svg {
                width: 25px;
                height: 25px;
            }

            & > input {
                width: calc(100% - 25px);
                -webkit-appearance: none;
                height: 100%;
                padding: 0 10px;
                border: none;
                box-sizing: border-box;
                background: transparent;

                &:focus {
                    outline: none;
                }
            }
        }

        & > button {
            margin-left: auto;
        }
    }

    & button {
        margin-left: 15px;
        width: 100px;
        border: none;
        height: 50px;
        background-color: #2b3e63;
        border-radius: 5px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
`;

export const Main = styled.div`
    width: 100%;
    height: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
`;

export const ListWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;

    & > div {
        width: calc(33.3% - 10px);
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export const ButtonContainer = styled.div<{ $visible: boolean }>`
    ${({ $visible }) =>
            $visible
                    ? css`
                        height: 35px;
                        margin-bottom: 10px;
                        visibility: visible;
                        opacity: 1;
                    `
                    : css`
                        height: 0;
                        margin-bottom: 0;
                        visibility: hidden;
                        opacity: 0;
                    `};

    transition: height 0.25s;
    width: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    & > button {
        width: 80px;
        height: 100%;
        border: none;
        border-radius: 5px;
        color: #eee;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & > .prev-button {
        background-color: #084d90;
    }

    & > .next-button {
        background-color: #2b3e63;
    }

    & > .delete-button {
        background-color: #f0614f;

        & > svg {
            width: 20px;
            height: 20px;
            margin-right: 3px;
        }
    }
`;

export const StyledSwipeableList = styled.div`
    width: 100%;
    height: calc(100% - 80px) !important;
`;

export const OrderListContainer = styled.div<{ $itemClicked: boolean }>`
    height: ${({ $itemClicked }) => ($itemClicked ? 'calc(100% - 50px)' : '100%')};
    transition: height 0.25s;
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    margin-top: auto;
    box-sizing: border-box;

    .number-list {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        margin-top: 15px;
        box-sizing: border-box;

        .pre-container {
            overflow: hidden;
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .loading {
                position: relative;
            }

            p {
                color: #2b3e63;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .none-container {
            width: 100%;
            height: auto;

            & > p {
                width: 100%;
                height: 60px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                font-size: 18px;
                color: #2b3e63;
                font-weight: 600;
            }
        }
    }
`;

export const ContainerName = styled.div<{ $status: EOrderStatusType }>`
    width: 100%;
    height: auto;

    // text 길면 ... 처리
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > .text-header {
        height: 55px;
        padding: 5px 15px;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        color: #fff;

        background-color: ${({ $status }) => getCafeOrderStatusColor($status)};

        & > div {
            margin-left: 20px;
            padding: 0 5px;
            min-width: 22px;
            height: 22px;
            color: ${({ $status }) => getCafeOrderStatusColor($status)};
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            font-weight: 600;
        }
    }

    & > .text-info {
        font-size: 12px;
        color: #ccc;
        height: 12px;
    }
`;

export const StyledSwipeableListItem = styled(SwipeableListItem)`
    width: 100%;
`;

export const DeleteContainer = styled.div`
    width: 100%;
    overflow: hidden;
    height: calc(100% - 12px);
    background-color: rgb(240, 97, 79);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    padding-left: 15px;

    & > svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
`;

export const OrderButton = styled.button<{ $selected: boolean | undefined; $isEdit: boolean | undefined }>`
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    flex: 100% 0 0;
    height: ${({ $isEdit }) => ($isEdit ? '160px' : '60px')};
    border: none;
    border-radius: 8px;
    background-color: ${({ $selected }) => ($selected ? '#2b3e63' : '#fff')};
    transition: background-color 0.2s, color 0.25s, flex 0.25s, height 0.25s;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 12px;
    padding: 0 15px;

    ${({ $isEdit }) =>
            $isEdit &&
            css`
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                padding: 15px;
            `};

    & > .detail-info {
        margin-left: auto;
        width: auto;
        height: auto;

        & > div {
            font-size: 12px;
            color: #aaa;
        }
    }

    & > form {
        width: 100%;

        & > .default-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;

            & > input {
                color: ${({ $selected }) => ($selected ? '#fff' : '#191919')};
                font-size: 18px;
                font-weight: 600;
                padding: 0 20px;
                height: 50px;
                width: 100%;
                background-color: #f3f3f3;
                border-radius: 8px;
                border: none;

                &:focus {
                    outline: none;
                }
            }

            & > input:read-only {
                height: auto;
                width: 40%;
                background-color: transparent;
                padding: 0;
            }

            & > button {
                width: 42px;
                height: 42px;
                background-color: #2b3e63;
                border: none;
                display: ${({ $selected, $isEdit }) => ($selected || $isEdit ? 'none' : 'flex')};
                align-items: center;
                justify-content: center;
                border-radius: 10px;

                & > svg {
                    width: 24px;
                    height: 24px;
                    transform: rotate(180deg);
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            & > .edit-button {
                margin: 0 10px 0 auto;
                background-color: #ddd;

                & > svg {
                    width: 14px;
                    height: 14px;
                    transform: rotate(0);
                }
            }
        }

        & > .button-container {
            margin-top: 10px;
            width: 100%;
            height: 30px;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;

            & > button {
                width: 65px;
                height: 30px;
                border: none;
                border-radius: 5px;
                background-color: #2b3e63;
                color: #fff;
                font-size: 12px;
                font-weight: 600;

                &:first-child {
                    background-color: #ddd;
                    color: #191919;
                }
            }
        }
    }
`;

export const SnackbarAlert = styled.div<{ $type: 'success' | 'delete' | null }>`
    background-color: ${({ $type }) =>
            $type === 'success' ? '#4a934acc' : $type === 'delete' ? '#f0614fcc' : '#2b3e63cc'};
    padding: 15px 25px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
`;

export const OrderListModalStyle = styled.main`
    width: 1000px;
    height: 600px;
    background-color: #fff;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > .numbers {
        font-size: 120px;
        font-weight: 800;
    }

    & > .message {
        width: 100%;
        font-size: 40px;
        font-weight: 700;
        word-wrap: break-word;
        white-space: normal;
        text-align: center;
        padding: 0 20px;
        box-sizing: border-box;
    }

    & > .text {
        margin-top: 100px;
        font-size: 35px;
        font-weight: 600;
    }
`;

export const NoticeModalStyle = styled.main`
    width: 600px;
    height: 400px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    & > textarea {
        background-color: #f6f6f6;
        border: none;
        width: 100%;
        height: 330px;
        resize: none;
        border-radius: 10px;
        padding: 20px;
        font-size: 18px;
        box-sizing: border-box;
    }

    & > button {
        width: 90px;
        height: 40px;
        background-color: #2b3e63;
        border-radius: 5px;
        margin-left: auto;
        color: #fff;
    }
`;
