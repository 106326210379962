export const getBase64ByFile = async (file: File): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onload = (event) => {
      const result = event.target?.result?.toString();
      resolve(result ?? '');
    };
    fileReader.onerror = () => {
      resolve('');
    };
    fileReader.readAsDataURL(file);
  });
};

export const getBase64ByFileUrl = async (fileUrl: string): Promise<string> => {
  const fileReader = new FileReader();
  const res = await fetch(fileUrl);
  const file = await res.blob();

  return new Promise((resolve) => {
    fileReader.onload = (event) => {
      const result = event.target?.result?.toString();
      resolve(result ?? '');
    };
    fileReader.onerror = () => {
      resolve('');
    };
    fileReader.readAsDataURL(file);
  });
};

export const getFileNameFromMinioURL = (fileurl: string) => {
  let splitedUrl = fileurl.split('/');
  return splitedUrl[splitedUrl.length - 1];
};
