import dayjs from 'dayjs';

export const getFullDate = (date?: string, time?: string): Date | null => {
  const sampleDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
  return new Date(`${date ? date : sampleDate}T${time}:00`);
};

export const getFormattedDate = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
};
