// start,end - ex) 2023-01-01T10:30:00

import { EScheduleRepetitionType, EScheduleRepetitionTypeTypes } from '@typedef/Schedule/repetition-type.enum';
import { PaginationFilterInputsDefault } from '@typedef/common.types';

export enum DayOfTheWeek {
  MON = 'MON',
  TUES = 'TUES',
  WED = 'WED',
  THURS = 'THURS',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum RepetitionPeriodType {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
  LAST = 'LAST',
}

export const Weekdays: { day: DayOfTheWeek; ko: string; en: string; }[] = [
  { day: DayOfTheWeek.SUN, ko: '일', en: 'sun' },
  { day: DayOfTheWeek.MON, ko: '월', en: 'mon' },
  { day: DayOfTheWeek.TUES, ko: '화', en: 'tues' },
  { day: DayOfTheWeek.WED, ko: '수', en: 'wed' },
  { day: DayOfTheWeek.THURS, ko: '목', en: 'thurs' },
  { day: DayOfTheWeek.FRI, ko: '금', en: 'fri' },
  { day: DayOfTheWeek.SAT, ko: '토', en: 'sat' },
];

export const RepetitionPeriods: { value: RepetitionPeriodType; name: string }[] = [
  { value: RepetitionPeriodType.ONE, name: '첫번째' },
  { value: RepetitionPeriodType.TWO, name: '두번째' },
  { value: RepetitionPeriodType.THREE, name: '세번째' },
  { value: RepetitionPeriodType.FOUR, name: '네번째' },
  { value: RepetitionPeriodType.FIVE, name: '다섯번째' },
  { value: RepetitionPeriodType.LAST, name: '마지막' },
];

export enum EMonthlyRepeatType {
  NTH_DATE = 'NTH_DATE', // 매월 N번째 N요일
  SPECIFIC_DATE = 'SPECIFIC_DATE', // 매월 N일
}

export type ScheduleResponseTypes = {
  id: string;
  repetitionType: EScheduleRepetitionTypeTypes;
  // yyyy-MM-ddTHH:mm (반복 유형 끝나는 시간)
  repetitionEndTime?: string;
  repetitionDayOfTheWeek?: DayOfTheWeek[];
  monthlyRepeatType?: EMonthlyRepeatType;
  repetitionPeriod?: RepetitionPeriodType;
  repetitionDate?: number;
  // yyyy-MM-dd (반복 유형 or 유형 없음)
  startDate: string;
  // HH:mm (반복 유형 or 유형 없음)
  startTime: string;
  // yyyy-MM-dd (유형 없음)
  endDate?: string;
  // HH:mm (유형 없음)
  endTime?: string;
  contentId: string;
  deviceId: string;
  createdAt: string;
  updatedAt: string;
};

export type ScheduleTypes = Omit<ScheduleResponseTypes, 'repetitionType'> & {
  repetitionType: EScheduleRepetitionType;
  // yyyy-MM-ddTHH:mm (반복 유형)
  dailyStartTime?: string;
  // yyyy-MM-ddTHH:mm (반복 유형)
  dailyEndTime?: string;
};

export type ScheduleActiveTypes = {
  repetitionDayOfTheWeek?: DayOfTheWeek[];
};

export const GetSchedulesInputsDefault = {
  ...PaginationFilterInputsDefault,
  deviceId: '',
  contentId: '',
};

export type GetSchedulesInputsTypes = typeof GetSchedulesInputsDefault;
