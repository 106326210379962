import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import {
  DayOfTheWeek,
  EMonthlyRepeatType,
  RepetitionPeriodType,
  ScheduleTypes,
} from '@typedef/Schedule/schedule.types';
import dayjs from 'dayjs';

/** 스케줄리스트중 지금 시간대에서 사용하고 있는 스케줄 찾아서 return */
export async function getCurrentSchedule(scheduleList: ScheduleTypes[]): Promise<ScheduleTypes | undefined> {
  const now = dayjs();
  const nowTime = dayjs(now.format('HH:mm'), 'HH:mm');

  let currentSchedule: ScheduleTypes | undefined;

  scheduleList.forEach((schedule) => {
    let findSchedule: ScheduleTypes | undefined;

    if (schedule.repetitionEndTime && dayjs(schedule.repetitionEndTime).isSameOrBefore(now)) return;

    const startDate = dayjs(schedule.startDate + ' ' + schedule.startTime);
    const endDate = dayjs(schedule.endDate + ' ' + schedule.endTime);
    const startTime = dayjs(schedule.startTime, 'HH:mm');
    const endTime = dayjs(schedule.endTime, 'HH:mm');

    if (schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)) {
      if (startDate.isSameOrBefore(now) && endDate.isSameOrAfter(now)) findSchedule = schedule;
    } else if (schedule.repetitionType.equals(EScheduleRepetitionType.EVERY_DAY)) {
      if (startTime.isSameOrBefore(nowTime) && endTime.isSameOrAfter(nowTime)) findSchedule = schedule;
    } else if (schedule.repetitionType.equals(EScheduleRepetitionType.EVERY_WEEK)) {
      if (
        schedule.repetitionDayOfTheWeek &&
        schedule.repetitionDayOfTheWeek.includes(getDayOfTheWeekFromDayjs(now)) &&
        startTime.isSameOrBefore(nowTime) && endTime.isSameOrAfter(nowTime)
      ) {
        findSchedule = schedule;
      }
    } else if (schedule.repetitionType.equals(EScheduleRepetitionType.EVERY_MONTH)) {
      if (isCurrentMonthSchedule(schedule, now) && startTime.isSameOrBefore(nowTime) && endTime.isSameOrAfter(nowTime)) {
        findSchedule = schedule;
      }
    }

    if (findSchedule) {
      if (!currentSchedule) {
        currentSchedule = findSchedule;
        return;
      }
      currentSchedule = getWeightierSchedule(findSchedule, currentSchedule);
    }
  });

  return currentSchedule;
}

function isCurrentMonthSchedule(schedule: ScheduleTypes, now: dayjs.Dayjs): boolean {
  if (schedule.monthlyRepeatType === EMonthlyRepeatType.NTH_DATE) {
    const targetDayOfWeek = schedule.repetitionDayOfTheWeek?.[0];
    if (!targetDayOfWeek) return false;

    if (schedule.repetitionPeriod === RepetitionPeriodType.LAST) {
      return isLastSpecificWeekdayOfMonth(now, targetDayOfWeek);
    } else {
      const weekNumber = getWeekNumberFromRepetitionPeriod(schedule.repetitionPeriod);
      return isNthWeekdayOfMonth(now, targetDayOfWeek, weekNumber);
    }
  } else if (schedule.monthlyRepeatType === EMonthlyRepeatType.SPECIFIC_DATE) {
    return now.date() === schedule.repetitionDate;
  }

  return false;
}

function isLastSpecificWeekdayOfMonth(date: dayjs.Dayjs, dayOfWeek: DayOfTheWeek): boolean {
  const lastDayOfMonth = date.endOf('month');
  let lastSpecificWeekday = lastDayOfMonth.day(getDayNumberFromDayOfWeek(dayOfWeek));
  if (lastSpecificWeekday.isAfter(lastDayOfMonth)) {
    lastSpecificWeekday = lastSpecificWeekday.subtract(1, 'week');
  }
  return date.isSame(lastSpecificWeekday, 'day');
}

function isNthWeekdayOfMonth(date: dayjs.Dayjs, dayOfWeek: DayOfTheWeek, n: number): boolean {
  const firstDayOfMonth = date.startOf('month');
  let nthWeekday = firstDayOfMonth.day(getDayNumberFromDayOfWeek(dayOfWeek));
  if (nthWeekday.isBefore(firstDayOfMonth)) {
    nthWeekday = nthWeekday.add(1, 'week');
  }
  nthWeekday = nthWeekday.add(n - 1, 'week');
  return date.isSame(nthWeekday, 'day');
}

function getWeekNumberFromRepetitionPeriod(period?: RepetitionPeriodType): number {
  switch (period) {
    case RepetitionPeriodType.ONE:
      return 1;
    case RepetitionPeriodType.TWO:
      return 2;
    case RepetitionPeriodType.THREE:
      return 3;
    case RepetitionPeriodType.FOUR:
      return 4;
    case RepetitionPeriodType.FIVE:
      return 5;
    default:
      return 1;
  }
}

function getDayNumberFromDayOfWeek(day: DayOfTheWeek): number {
  const dayMap: { [key in DayOfTheWeek]: number } = {
    [DayOfTheWeek.SUN]: 0,
    [DayOfTheWeek.MON]: 1,
    [DayOfTheWeek.TUES]: 2,
    [DayOfTheWeek.WED]: 3,
    [DayOfTheWeek.THURS]: 4,
    [DayOfTheWeek.FRI]: 5,
    [DayOfTheWeek.SAT]: 6,
  };
  return dayMap[day];
}

function getWeightierSchedule(schedule1: ScheduleTypes, schedule2: ScheduleTypes): ScheduleTypes {
  const getScheduleWeight = (schedule: ScheduleTypes): number => {
    if (EScheduleRepetitionType.ONCE.equals(schedule.repetitionType)) return 5;
    if (EScheduleRepetitionType.EVERY_DAY.equals(schedule.repetitionType)) return 4;
    if (EScheduleRepetitionType.EVERY_WEEK.equals(schedule.repetitionType)) return 3;
    if (EScheduleRepetitionType.EVERY_MONTH.equals(schedule.repetitionType)) return 2;
    // if (EScheduleRepetition.EVERY_YEAR.equals(schedule.repetitionType)) return 1;
    return 0;
  };

  const schedule1Weight = getScheduleWeight(schedule1);
  const schedule2Weight = getScheduleWeight(schedule2);

  if (schedule1Weight !== schedule2Weight) {
    return schedule1Weight > schedule2Weight ? schedule1 : schedule2;
  }

  const schedule1UpdatedAt = dayjs(schedule1.updatedAt ?? schedule1.createdAt);
  const schedule2UpdatedAt = dayjs(schedule2.updatedAt ?? schedule2.createdAt);

  return schedule1UpdatedAt.isAfter(schedule2UpdatedAt) ? schedule1 : schedule2;
}

function getDayOfTheWeekFromDayjs(v: dayjs.Dayjs): DayOfTheWeek {
  switch (v.day()) {
    case 0:
      return DayOfTheWeek.SUN;
    case 1:
      return DayOfTheWeek.MON;
    case 2:
      return DayOfTheWeek.TUES;
    case 3:
      return DayOfTheWeek.WED;
    case 4:
      return DayOfTheWeek.THURS;
    case 5:
      return DayOfTheWeek.FRI;
    case 6:
      return DayOfTheWeek.SAT;
    default:
      return DayOfTheWeek.SUN;
  }
}
