import * as S from './styles/MonitoringStyle';
import { useNavigate } from 'react-router-dom';
import LineChart from '@components/Common/Chart/LineChart';
import { ChartItemDateType } from '@typedef/Monitoring/chart.type';
import React from 'react';
import { CommonDateType, PeriodType } from '@components/Common/CommonDate/CommonDate';
import DatePicker from '@components/Common/DatePicker/DatePicker';
import dayjs from 'dayjs';

type Props = {
  chartItemValue: ChartItemDateType | undefined;
  contentsPlaySummariesStatisticsExcelDownload: (name: string, ip: string) => void;
  zoneItems: string[];
  chartLabels: string[];
  commonDate: CommonDateType;
  updateCommonDate: (value: PeriodType) => void;
  onSummit: () => void;
};

const ContentPlaybackStatistics = ({
  chartItemValue,
  contentsPlaySummariesStatisticsExcelDownload,
  zoneItems,
  chartLabels,
  commonDate,
  updateCommonDate,
  onSummit,
}: Props) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.Header>
        통합관제모니터링 | 컨텐츠 재생 통계 조회
        <div className="date-picker-container">
          <DatePicker
            type={'startedAt'}
            value={commonDate.startedAt}
            minValue={dayjs(commonDate.endedAt).subtract(1, 'month').format('YYYY-MM-DD')}
            maxValue={dayjs(commonDate.endedAt).format('YYYY-MM-DD')}
            handleOnChange={(value) => updateCommonDate({ ...commonDate, startedAt: value })}
          />
          <h3>~</h3>
          <DatePicker
            type={'endedAt'}
            value={commonDate.endedAt}
            minValue={dayjs(commonDate.startedAt).format('YYYY-MM-DD')}
            maxValue={dayjs(commonDate.startedAt).add(1, 'month').format('YYYY-MM-DD')}
            handleOnChange={(value) => updateCommonDate({ ...commonDate, endedAt: value })}
          />
          <button onClick={() => onSummit()}>검색</button>
        </div>
      </S.Header>
      <S.Article grid={3}>
        <div className="header">
          <div />
          <button onClick={() => navigate('/monitoring/content-playback-statistics/list')}>상세 데이터</button>
        </div>
        <div className={'contentPlaybackBackground'}>
          {!chartItemValue?.[zoneItems[0]] ? (
            <></>
          ) : (
            <>
              {zoneItems.map((zoneItem, i) => {
                const headName = zoneItem.split('-')[1];
                const chartData = chartItemValue?.[zoneItem] || {};

                const countData = chartData?.count || [];
                const playtimeData = chartData?.playtime || [];

                return (
                  <div className={'container line-chart'} key={i}>
                    <S.ContainerHeader>
                      <h3>{headName}</h3>
                      <button
                        onClick={() => {
                          if (
                            countData.filter((v) => v === 0).length === countData.length &&
                            playtimeData.filter((v) => v === 0).length === playtimeData.length
                          ) {
                            return alert('저장할 데이터가 없습니다.');
                          }
                          contentsPlaySummariesStatisticsExcelDownload(headName, chartData.ip);
                        }}
                      >
                        엑셀로 저장
                      </button>
                    </S.ContainerHeader>

                    <div className={'graph-container'}>
                      <LineChart
                        data={{
                          labels: chartLabels,
                          datasets: [
                            {
                              label: '사용 횟수',
                              data: countData,
                              fill: false,
                              borderColor: '#3b2566',
                              tension: 0.1,
                            },
                          ],
                        }}
                      />

                      <LineChart
                        data={{
                          labels: chartLabels,
                          datasets: [
                            {
                              label: '사용 시간',
                              data: playtimeData,
                              fill: false,
                              borderColor: '#256662',
                              tension: 0.1,
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </S.Article>
    </S.Container>
  );
};

export default ContentPlaybackStatistics;
