export type EVisitStepProps = 'HOME' | 'PRIVACY_POLICY' | 'VISITOR' | 'COMPANION' | 'COMPLETE';

export class EVisitStep {
  constructor(
    readonly value: EVisitStepProps,
    readonly name: string,
  ) {}

  static readonly HOME = new EVisitStep('HOME', '홈');
  static readonly PRIVACY_POLICY = new EVisitStep('PRIVACY_POLICY', '개인정보 수집동의');
  static readonly VISITOR = new EVisitStep('VISITOR', '방문자');
  static readonly COMPLETE = new EVisitStep('COMPLETE', '등록 완료');

  static readonly values = [EVisitStep.HOME, EVisitStep.PRIVACY_POLICY, EVisitStep.VISITOR, EVisitStep.COMPLETE];

  static valueOf(value: EVisitStepProps): EVisitStep {
    return EVisitStep.values.find((v) => v.value === value)!;
  }

  equals(value: EVisitStepProps | EVisitStep) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
