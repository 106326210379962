import { BackgroundThemeList, ContentTypes } from '@typedef/Contents/contents.types';
import '../styles/CustomStyle.scss';
import ReactDropdown, { Option } from 'react-dropdown';
import { BoardSlidesType, WidgetTypes } from '@typedef/Widget/widget.types';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: WidgetTypes | ContentTypes | BoardSlidesType | null;
};

const CustomBackgroundTheme = ({ handleOnChange, selectLayout }: Props) => {
  return (
    <div className="custom">
      <div className="background-theme">
        <p className="title">테마</p>
        <ReactDropdown
          placeholder={'테마 선택'}
          options={BackgroundThemeList}
          className={'theme-dropdown'}
          value={selectLayout?.backgroundTheme}
          onChange={(value: Option) => handleOnChange('backgroundTheme', value.value)}
        />
      </div>
    </div>
  );
};

export default CustomBackgroundTheme;
