import * as S from '@components/Monitoring/styles/MonitoringStyle';
import { InfoMessage } from '@components/Monitoring/styles/MonitoringStyle';
import * as P from './styles/PieChartStyle';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateSurveyCountType, PeopleCount, SurveysType } from '@typedef/Monitoring/monitoring.type';
import PieChart from '@components/Common/Chart/PieChart';
import { EAgeGroupProps } from '@typedef/Visit/age-group.enum';
import { EGenderTypes } from '@typedef/Visit/gender.enum';
import { EVisitTypeProps } from '@typedef/Visit/visit-type.enum';
import DatePicker from '@components/Common/DatePicker/DatePicker';
import { PeriodType } from '@components/Common/CommonDate/CommonDate';

type Props = {
  surveysData: SurveysType[];
  surveyCountData: DateSurveyCountType;
  webGLPeopleCount: PeopleCount;
  zepetoPeopleCount: PeopleCount;
  surveyExcelDownload: (column: string) => void;
  period: PeriodType;
  setPeriod: React.Dispatch<React.SetStateAction<PeriodType>>;
  onSummit: () => void;
};

const MetaCity: React.FC<Props> = ({
  surveysData,
  surveyCountData,
  webGLPeopleCount,
  zepetoPeopleCount,
  surveyExcelDownload,
  period,
  setPeriod,
  onSummit,
}) => {
  const navigate = useNavigate();
  const { weather, ageGroup, gender, visitType } = surveyCountData;

  return (
    <S.Container>
      <S.Header>
        통합관제모니터링 | 메타시티 방문자 통계 조회
        <div className="date-picker-container">
          <DatePicker
            type={'startedAt'}
            value={period.startedAt}
            handleOnChange={(value) => setPeriod((prev) => ({ ...prev, startedAt: value }))}
          />
          <h3>~</h3>
          <DatePicker
            type={'endedAt'}
            value={period.endedAt}
            handleOnChange={(value) => setPeriod((prev) => ({ ...prev, endedAt: value }))}
          />
          <button onClick={() => onSummit()}>검색</button>
        </div>
      </S.Header>
      <S.Article>
        <div className="header">
          <div />
          <button onClick={() => navigate('/monitoring/meta-city/list')}>상세 데이터</button>
        </div>
        <div className={'contentPlaybackBackground'}>
          <div className={'container real-city-container'}>
            <P.AllDetailContainer>
              <P.DetailContainer className="first visitor-of-number">
                <div className={'visited-container'}>
                  <div>
                    {webGLPeopleCount.loading ? (
                      <div className={'loading-message'}>데이터 조회중...</div>
                    ) : (
                      <>
                        <div>WebGL</div>
                        <div>
                          <span>{webGLPeopleCount.data.value}</span>회
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    {zepetoPeopleCount.loading ? (
                      <div className={'loading-message'}>데이터 조회중...</div>
                    ) : (
                      <>
                        <div>제페토</div>
                        <div>
                          <span>{zepetoPeopleCount.data.value}</span>회
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </P.DetailContainer>
              <P.DetailContainer className="last table">
                <h3>최근 데이터</h3>
                <table>
                  <thead>
                    <tr>
                      <div>지역</div>
                      <div>성별</div>
                      <div>연령대</div>
                      <div>방문 목적</div>
                    </tr>
                  </thead>
                  <tbody>
                    {surveysData.map((data, i) => (
                      <tr>
                        <div>
                          {!data.weather ? (
                            <span>데이터 없음</span>
                          ) : (
                            surveyCountData.weather.data?.some((data2) => data2.key === data.weather.location1) &&
                            data.weather.location1
                          )}
                        </div>
                        <div>
                          {!data.gender ? (
                            <span>데이터 없음</span>
                          ) : (
                            gender.data?.some((data2) => data.gender.equals(data2.key as EGenderTypes)) &&
                            data.gender.name
                          )}
                        </div>
                        <div>
                          {!data.ageGroup ? (
                            <span>데이터 없음</span>
                          ) : (
                            ageGroup.data?.some((data2) => data.ageGroup.equals(data2.key as EAgeGroupProps)) &&
                            data.ageGroup.name
                          )}
                        </div>
                        <div>
                          {!data.visitType ? (
                            <span>데이터 없음</span>
                          ) : (
                            visitType.data.some((data2) => data.visitType.equals(data2.key as EVisitTypeProps)) &&
                            data.visitType.name
                          )}
                        </div>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </P.DetailContainer>
            </P.AllDetailContainer>
            <P.AllDetailContainer grid={2}>
              <P.DetailContainer>
                <div>
                  <S.ContainerHeader>
                    <h3>지역</h3>
                    <button
                      onClick={() => {
                        if (weather.loading || weather.data.length <= 0) return alert('저장할 데이터가 없습니다.');
                        surveyExcelDownload('weather');
                      }}
                    >
                      엑셀로 저장
                    </button>
                  </S.ContainerHeader>
                </div>
                <P.PieChartContainer>
                  {weather.loading ? (
                    <InfoMessage $isWeather={true}>데이터를 조회중입니다...</InfoMessage>
                  ) : weather.data.length > 0 ? (
                    <PieChart
                      data={{
                        labels: weather.data.map((data) => data.name),
                        datasets: [
                          {
                            label: '', // 커서를 데이터 위에 올렸을 때 보이는 설명
                            data: weather.data.map((data) => data.value),
                          },
                        ],
                      }}
                    />
                  ) : (
                    <InfoMessage $isWeather={true}>데이터가 존재하지 않습니다.</InfoMessage>
                  )}
                </P.PieChartContainer>
              </P.DetailContainer>
              <P.DetailContainer>
                <div>
                  <S.ContainerHeader>
                    <h3>성별</h3>
                    <button
                      onClick={() => {
                        if (gender.loading || gender.data.length <= 0) return alert('저장할 데이터가 없습니다.');
                        surveyExcelDownload('gender');
                      }}
                    >
                      엑셀로 저장
                    </button>
                  </S.ContainerHeader>
                </div>
                <P.PieChartContainer>
                  {gender.loading ? (
                    <InfoMessage $isWeather={true}>데이터를 조회중입니다...</InfoMessage>
                  ) : gender.data.length > 0 ? (
                    <PieChart
                      data={{
                        labels: gender.data.map((data) => data.name),
                        datasets: [
                          {
                            label: '', // 커서를 데이터 위에 올렸을 때 보이는 설명
                            data: gender.data.map((data) => data.value),
                          },
                        ],
                      }}
                    />
                  ) : (
                    <InfoMessage $isWeather={true}>데이터가 존재하지 않습니다.</InfoMessage>
                  )}
                </P.PieChartContainer>
              </P.DetailContainer>
              <P.DetailContainer>
                <div>
                  <S.ContainerHeader>
                    <h3>방문 목적</h3>
                    <button
                      onClick={() => {
                        if (visitType.loading || visitType.data.length <= 0) return alert('저장할 데이터가 없습니다.');
                        surveyExcelDownload('visitType');
                      }}
                    >
                      엑셀로 저장
                    </button>
                  </S.ContainerHeader>
                </div>
                <P.PieChartContainer>
                  {visitType.loading ? (
                    <InfoMessage $isWeather={true}>데이터를 조회중입니다...</InfoMessage>
                  ) : visitType.data.length > 0 ? (
                    <PieChart
                      data={{
                        labels: visitType.data.map((data) => data.name),
                        datasets: [
                          {
                            label: '', // 커서를 데이터 위에 올렸을 때 보이는 설명
                            data: visitType.data.map((data) => data.value),
                          },
                        ],
                      }}
                    />
                  ) : (
                    <InfoMessage $isWeather={true}>데이터가 존재하지 않습니다.</InfoMessage>
                  )}
                </P.PieChartContainer>
              </P.DetailContainer>
              <P.DetailContainer>
                <div className={'visited-sub-header'}>
                  <S.ContainerHeader>
                    <h3>연령대</h3>
                    <button
                      onClick={() => {
                        if (ageGroup.loading || ageGroup.data.length <= 0) return alert('저장할 데이터가 없습니다.');
                        surveyExcelDownload('ageGroup');
                      }}
                    >
                      엑셀로 저장
                    </button>
                  </S.ContainerHeader>
                </div>
                <P.PieChartContainer>
                  {ageGroup.loading ? (
                    <InfoMessage $isWeather={true}>데이터를 조회중입니다...</InfoMessage>
                  ) : ageGroup.data.length > 0 ? (
                    <PieChart
                      data={{
                        labels: ageGroup.data.map((data) => data.name),
                        datasets: [
                          {
                            label: '', // 커서를 데이터 위에 올렸을 때 보이는 설명
                            data: ageGroup.data.map((data) => data.value),
                          },
                        ],
                      }}
                    />
                  ) : (
                    <InfoMessage $isWeather={true}>데이터가 존재하지 않습니다.</InfoMessage>
                  )}
                </P.PieChartContainer>
              </P.DetailContainer>
            </P.AllDetailContainer>
          </div>
        </div>
      </S.Article>
    </S.Container>
  );
};

export default MetaCity;
