import React, { useEffect, useRef, useState } from 'react';
import NewsSub from '../NewsSub';
import { WidgetTypes } from '@typedef/Widget/widget.types';

type Props = {
  widget: WidgetTypes;
};

const NewsSubContainer = ({ widget }: Props) => {
  const subtitleRef = useRef<HTMLParagraphElement>(null);
  const [width, setWidth] = useState<number>(0);

  // 자막 컴포넌트를 사용하는 div의 width값 가져오기
  useEffect(() => {
    if (subtitleRef) {
      const subtitleWidth = subtitleRef.current?.clientWidth;
      setWidth(subtitleWidth ?? 0);
    }
  }, [widget]);

  const replacedWidget: WidgetTypes = {
    ...widget,
    text: widget.text?.replaceAll('//', widget.newsSeparator?.length ? widget.newsSeparator : 'ㅤㅤ'),
  };

  return <NewsSub widget={replacedWidget} width={width} subtitleRef={subtitleRef} />;
};

export default NewsSubContainer;
