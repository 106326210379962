import styled from 'styled-components';

export const Container = styled.main`
  width: 600px;

  a {
    flex: 1;
    color: #fff;
    margin: 0 5px;
  }

  p {
    text-align: center;
  }

  .main {
    font-size: 20px;
    font-weight: bold;
  }

  .sub {
    color: #b6b6b6;
    margin-top: 15px;
    font-size: 14px;
  }
`;
