import ReactDropdown, { Option } from 'react-dropdown';
import { BoardPostType, PostTemplateList } from '@typedef/Widget/widget.types';
import React from 'react';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectPost: BoardPostType | null;
  globalStyle?: boolean;
};

const CustomTemplate = ({ handleOnChange, selectPost, globalStyle }: Props) => {
  return (
    <>
      <div className="custom">
        <div className="background-theme">
          <p className="title">템플릿</p>
          <ReactDropdown
            placeholder={'선택'}
            options={PostTemplateList}
            className={'theme-dropdown'}
            value={selectPost?.template}
            onChange={(value: Option) => handleOnChange('template', value.value === '' ? null : value.value)}
          />
        </div>
      </div>

      {(selectPost?.template || globalStyle) &&
        <>
          <div className={'subtitle-text'}>
            템플릿 상세 설정
          </div>
          <div className="custom">
            <div className="background-color">
              <p className="title">템플릿 색상</p>
              <input
                type="color"
                value={selectPost?.templateColor}
                onChange={(e) => {
                  handleOnChange('templateColor', e.target.value);
                }}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

export default CustomTemplate;