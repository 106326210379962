import styled from 'styled-components';

const space = '15px';

export const Container = styled.main`
    header {
        ${({ theme }) => theme.flex.row}
        align-items: center;
        justify-content: space-between;
        margin-bottom: calc(${space} * 2);

        .left {
            ${({ theme }) => theme.flex.row}
            align-items: center;
        }

        .title {
            ${({ theme }) => theme.flex.row}
            align-items: center;

            & > span {
                font-size: 1.5rem;
                font-weight: 600;
                width: 100px;
                background: linear-gradient(to top, #15b9f566 70%, transparent 50%);
            }

            &:after {
                content: ' ';
                width: 3px;
                height: 1rem;
                display: block;
                margin: 0 15px;
                background-color: #191919;
            }
        }

        .react-dropdown-main {
            width: 300px;
        }

        .react-dropdown-control {
            width: 100%;
            padding: 0 15px;
            background-color: #fff;
            border-radius: 5px;

            font-size: 24px;
            font-weight: bold;
        }

        .react-dropdown-menu {
            font-size: 18px;
            border-radius: 5px;
            max-height: 300px;
        }

        .btns {
            width: auto;

            button {
                height: 45px;
                padding: 0 15px;
                background-color: #15b9f5;
                border: 1px solid #15b9f5;
                border-radius: 5px;
                color: #fff;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;

                & > svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }

                &:active {
                    opacity: 0.7;
                }

                &:disabled {
                    background-color: #d3d3d3;
                    color: #fff;
                    cursor: not-allowed;
                }
            }
        }
    }
`;

export const TimeLineContainer = styled.section`
    width: 100%;
    background-color: #fff;
    padding: 15px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    /* 스케줄링 모듈 디자인 변경 */

    .rbc-event-content {
        color: #fff;
    }

    .react-calendar-timeline {
        z-index: 0;

        * {
            border: 0;
        }

        // 헤더

        .rct-header-root {
            border-bottom: 1px solid #ddd;

            div {
                height: 60px;
                line-height: 60px;
                background-color: #f9f9f9;
                font-weight: 600;
                text-align: center;
            }
        }

        // 본문

        .rct-outer {
            * {
                border-color: #e9e9e9;
            }

            // 사이드

            .rct-sidebar {
                * {
                    font-weight: 600;
                    text-align: center;
                }

                &:nth-child(1) {
                    border: 0;
                }
            }

            .rct-sidebar-row-odd {
                background-color: #f1f1f1;
            }
        }

        // 아이템

        .rct-items {
            .rct-item {
                padding: 0 5px;
                background: none !important;
                border: none !important;
            }

            .rct-item-content {
                width: 100%;
                text-align: center;
                background-color: #55a397 !important;
                border-radius: 5px;
                color: #fff;
                font-weight: 600;
                font-size: 0.85rem !important;
            }
        }
    }

    .rbc-month-header {
        height: 50px;
        font-weight: 700 !important;
        font-family: 'Pretendard', 'Apple SD Gothic Neo', serif !important;
    }

    .rbc-header {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        background-color: #2b3e63;

        & > span {
            font-family: 'Pretendard', 'Apple SD Gothic Neo', serif;
            font-weight: 600;
            font-size: 18px;
            color: #fff;
        }
    }

    .rbc-button-link {
        padding: 5px 3px;
    }

    .rbc-month-sunday-bg, .rbc-month-saturday-bg {
        height: calc(100% - 50px);
    }
`;
