import { ManipulateType } from 'dayjs';

export type EScheduleRepetitionTypeTypes = 'ONCE' | 'DAY' | 'WEEK' | 'MONTH';
// export type EScheduleRepetitionTypeType = 'ONCE' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
export type EScheduleRepetitionDayjsValue = ManipulateType | undefined;

// 스케줄 유형이 없을 때, undefined 로 처리하기 위해 dayjs - ManipulateType 에 undefined 타입 추가

export class EScheduleRepetitionType {
  constructor(
    readonly value: EScheduleRepetitionTypeTypes,
    readonly dayjsValue: EScheduleRepetitionDayjsValue,
    readonly name: string,
  ) {
  }

  static readonly ONCE = new EScheduleRepetitionType('ONCE', undefined, '유형 없음');
  // 스케줄 유형이 없을 때, dayjsValue를 undefined 로 처리
  static readonly EVERY_DAY = new EScheduleRepetitionType('DAY', 'day', '매일');
  static readonly EVERY_WEEK = new EScheduleRepetitionType('WEEK', 'week', '매주');
  static readonly EVERY_MONTH = new EScheduleRepetitionType('MONTH', 'month', '매월');
  // static readonly EVERY_YEAR = new EScheduleRepetitionType('YEAR', 'year', '매년');

  static readonly values = [
    EScheduleRepetitionType.ONCE,
    EScheduleRepetitionType.EVERY_DAY,
    EScheduleRepetitionType.EVERY_WEEK,
    EScheduleRepetitionType.EVERY_MONTH,
    // EScheduleRepetitionType.EVERY_YEAR,
  ];

  static valueOf(value: EScheduleRepetitionTypeTypes): EScheduleRepetitionType {
    return EScheduleRepetitionType.values.find((v) => v.value === value)!;
  }

  equals(value: EScheduleRepetitionTypeTypes | EScheduleRepetitionType) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
