import { StockIndexLoadingOrError, StockIndexStyle } from '@components/Common/StockIndex/styles/StockIndexStyle';
import React from 'react';
import { ContentTypes } from '@typedef/Contents/contents.types';
import { ResolutionType } from '@typedef/Widget/widget.types';
import { EFluctuationDivisionType, StockIndexStateType } from '@typedef/Widget/stock-index.type';
import { StockArrowDown, StockArrowUp, StockEqual } from '@assets/icons';

type Props = {
  size: {
    width: number;
    height: number;
  };
  loading: boolean;
  isSetting: boolean;
  resolution: ResolutionType;
  content: ContentTypes;
  stockIndex: StockIndexStateType;
  error: boolean;
};

const StockItem = ({
  code,
  name,
  isIncrease,
  indexInfo,
  fluctuationFigures,
  highestPrice,
  lowestPrice,
  resolution,
}: {
  code: string;
  name: string;
  isIncrease: EFluctuationDivisionType;
  indexInfo: string;
  fluctuationFigures: string;
  highestPrice: string;
  lowestPrice: string;
  resolution: string;
}) => {
  const priceVisibility = { visible: ['16:9', '32:9', '4:3'], hidden: ['1:1', '9:16'] };

  return (
    <div className={'index'}>
      <div className={'title'}>
        <div>{code}</div>
        <div>{name}</div>
      </div>

      <div className={'stock-info'}>
        <div>
          {isIncrease === EFluctuationDivisionType.INCREASE ? (
            <StockArrowUp />
          ) : isIncrease === EFluctuationDivisionType.DECREASE ? (
            <StockArrowDown />
          ) : (
            <StockEqual />
          )}
          {indexInfo}
        </div>
        <div className={`${code.toLowerCase()}-fluctuation`}>
          {isIncrease === EFluctuationDivisionType.INCREASE
            ? '+'
            : isIncrease === EFluctuationDivisionType.DECREASE
              ? '-'
              : ''}
          {fluctuationFigures}
        </div>
      </div>

      {priceVisibility.visible.includes(resolution) && (
        <div className={'price'}>
          <div>
            <div>장중최고</div>
            <div className={'high'}>{highestPrice}</div>
          </div>

          <div>
            <div>장중최저</div>
            <div className={'low'}>{lowestPrice}</div>
          </div>
        </div>
      )}
    </div>
  );
};

const StockIndex: React.FC<Props> = ({ size, loading, isSetting, resolution, content, stockIndex, error }) => {
  if (!isSetting && loading) {
    return <StockIndexLoadingOrError>주식 지수를 조회중입니다...</StockIndexLoadingOrError>;
  } else if (!isSetting && error) {
    return <StockIndexLoadingOrError>주식 지수를 조회에 실패했습니다.</StockIndexLoadingOrError>;
  } else {
    return (
      <StockIndexStyle
        height={size.height}
        width={size.width}
        isSetting={isSetting}
        resolution={resolution}
        contentWidth={content.w}
        contentHeight={content.h}
        isIncrease={{
          kospi: isSetting ? EFluctuationDivisionType.INCREASE : stockIndex.kospi.fluctuationDivision,
          kosdaq: isSetting ? EFluctuationDivisionType.DECREASE : stockIndex.kosdaq.fluctuationDivision,
        }}
      >
        <StockItem
          code={'KOSPI'}
          name={'코스피'}
          isIncrease={isSetting ? EFluctuationDivisionType.INCREASE : stockIndex.kospi.fluctuationDivision}
          indexInfo={isSetting ? '2,752.41' : stockIndex.kospi.indexInfo}
          fluctuationFigures={isSetting ? '4.55' : stockIndex.kospi.fluctuationFigures}
          highestPrice={isSetting ? '2,760.33' : stockIndex.kospi.highestPrice}
          lowestPrice={isSetting ? '2,737.19' : stockIndex.kospi.lowestPrice}
          resolution={resolution}
        />
        <div className={'divider'} />
        <StockItem
          code={'KOSDAQ'}
          name={'코스닥'}
          isIncrease={isSetting ? EFluctuationDivisionType.DECREASE : stockIndex.kosdaq.fluctuationDivision}
          indexInfo={isSetting ? '876.75' : stockIndex.kosdaq.indexInfo}
          fluctuationFigures={isSetting ? '4.55' : stockIndex.kosdaq.fluctuationFigures}
          highestPrice={isSetting ? '2,760.33' : stockIndex.kosdaq.highestPrice}
          lowestPrice={isSetting ? '2,737.19' : stockIndex.kosdaq.lowestPrice}
          resolution={resolution}
        />
      </StockIndexStyle>
    );
  }
};

export default StockIndex;
