import Schedule from '../Schedule';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalSelector } from '@stories/modal';
import ScheduleManageContainer from './ScheduleManageContainer';
import {
  DayOfTheWeek,
  EMonthlyRepeatType,
  GetSchedulesInputsDefault,
  GetSchedulesInputsTypes,
  ScheduleResponseTypes,
  ScheduleTypes,
  Weekdays,
} from '@typedef/Schedule/schedule.types';
import { apiRoute, requestSecureGet } from '@libs/api';
import { convertDeviceResponseTypeToType, DeviceResponseTypes, DeviceTypes } from '@typedef/Device/device.types';
import { accountSelector } from '@stories/account';
import { ContentTypes } from '@typedef/Contents/contents.types';
import dayjs from 'dayjs';
import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { EDeviceType } from '@typedef/Device/device-type.enum';

const ScheduleContainer = () => {
  const [reload, setReload] = useState<number>(0);
  // DB 스케줄 리스트
  const [scheduleList, setScheduleList] = useState<ScheduleTypes[]>([]);
  // DB 컨텐츠 리스트
  const [contentList, setContentList] = useState<ContentTypes[]>([]);
  // 전광판 리스트
  const [deviceList, setDeviceList] = useState<DeviceTypes[]>([]);
  // 전광판 선택 리스트
  const [selectDeviceList, setSelectDeviceList] = useState<{ id: string; name: string }[]>([]);
  // 현재 선택한 전광판
  const [selectDevice, setSelectDevice] = useState<DeviceTypes | undefined>();
  const [date, setDate] = useState<Date>(new Date());

  const account = useRecoilValue(accountSelector);
  const setModal = useSetRecoilState(modalSelector);

  // 수정 모달 오픈
  const onClickManage = useCallback(
    async (type: '추가' | '수정', selectScheduleId?: string) => {
      setModal({
        header: `스케줄 ${type}하기`,
        close: true,
        body: (
          <ScheduleManageContainer
            account={account}
            type={type}
            reload={() => setReload((prev) => prev + 1)}
            deviceId={selectDevice?.id}
            contentList={contentList}
            schedule={scheduleList.find((schedule) => schedule.id === selectScheduleId)}
          />
        ),
      });
    },
    [selectDevice, scheduleList, contentList],
  );

  // 장비 조회
  const loadDevices = useCallback(async () => {
    if (account.accessToken.length === 0) return;

    const queryParams: Record<string, any> = { type: EDeviceType.BOARD.value, paged: false };

    const {
      config,
      data: { content },
    } = await requestSecureGet<PaginationTypes<DeviceResponseTypes>>(
      `${apiRoute.devices.get}${parseQueryParamsToString(queryParams)}`,
      {},
      account.accessToken,
    );

    if (config.status === 200) {
      setDeviceList(content.map((v) => convertDeviceResponseTypeToType(v)));

      const sortingDataList = content.map((device) => {
        return {
          id: device.id.toString(),
          name: device.name,
        };
      });

      setSelectDeviceList(sortingDataList);

      if (content.length) setSelectDevice(convertDeviceResponseTypeToType(content[0]) ?? null);
    } else {
      alert(config.errorMessage);
    }
  }, [account.accessToken]);

  const mapDayOfWeekToDayjsDay = useCallback((day: DayOfTheWeek): number => {
    const dayMap: { [key in DayOfTheWeek]: number } = {
      [DayOfTheWeek.MON]: 1,
      [DayOfTheWeek.TUES]: 2,
      [DayOfTheWeek.WED]: 3,
      [DayOfTheWeek.THURS]: 4,
      [DayOfTheWeek.FRI]: 5,
      [DayOfTheWeek.SAT]: 6,
      [DayOfTheWeek.SUN]: 0,
    };
    return dayMap[day];
  }, []);

  const getNthDayOfWeekInMonth = useCallback((date: dayjs.Dayjs, dayOfWeek: DayOfTheWeek, n: number): dayjs.Dayjs | null => {
    const firstDayOfMonth = date.startOf('month');
    const targetDayNumber = mapDayOfWeekToDayjsDay(dayOfWeek);
    let targetDate = firstDayOfMonth.day(targetDayNumber);

    if (targetDate.isBefore(firstDayOfMonth)) {
      targetDate = targetDate.add(1, 'week');
    }

    targetDate = targetDate.add(n - 1, 'week');

    if (targetDate.month() !== date.month()) {
      return null;
    }

    return targetDate;
  }, []);

  const getLastDayOfWeekInMonth = useCallback((date: dayjs.Dayjs, dayOfWeek: DayOfTheWeek): dayjs.Dayjs => {
    const lastDayOfMonth = date.endOf('month');
    const targetDayNumber = mapDayOfWeekToDayjsDay(dayOfWeek);
    let targetDate = lastDayOfMonth.day(targetDayNumber);
    if (targetDate.isAfter(lastDayOfMonth)) {
      targetDate = targetDate.subtract(1, 'week');
    }
    return targetDate;
  }, []);

  // 컨텐츠 조회
  const loadContent = useCallback(async () => {
    if (account.accessToken.length === 0) return;

    const {
      config,
      data: { content },
    } = await requestSecureGet<PaginationTypes<ContentTypes>>(apiRoute.contents.get + '?paged=false', {}, account.accessToken);

    if (config.status === 200) {
      setContentList(content);
    }
  }, [account.accessToken]);

  // 스케줄 조회
  const loadSchedule = useCallback(async () => {
    if (!selectDevice) {
      return;
    }
    const getSchedulesInputs: GetSchedulesInputsTypes = {
      ...GetSchedulesInputsDefault,
      deviceId: selectDevice.id,
    };

    const {
      config,
      data: { content },
    } = await requestSecureGet<PaginationTypes<ScheduleResponseTypes>>(
      `${apiRoute.schedule.get}${parseQueryParamsToString(getSchedulesInputs)}`,
      {},
      account.accessToken,
    );

    if (config.status !== 200) {
      return;
    }
    const newScheduleList = content.map((v) => {
      return {
        ...v,
        repetitionType: EScheduleRepetitionType.valueOf(v.repetitionType),
      };
    });

    setScheduleList((_) => {
      const repeatScheduleList: ScheduleTypes[] = [];

      newScheduleList.forEach((schedule) => {
        if (schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)) {
          repeatScheduleList.push({
            ...schedule,
            startDate: schedule.startDate,
            startTime: schedule.startTime,
            endDate: schedule.endDate,
            endTime: schedule.endTime,
          });
          return;
        } else if (schedule.repetitionType.equals(EScheduleRepetitionType.EVERY_MONTH)) {
          const repetitionEndTime = !schedule.repetitionEndTime ? undefined : dayjs(schedule.repetitionEndTime);
          const previous = dayjs(date).startOf('month');
          const next = dayjs(date).endOf('month');

          let targetDate: dayjs.Dayjs | null = null;

          if (schedule.monthlyRepeatType === EMonthlyRepeatType.NTH_DATE) {
            let startDate = dayjs(schedule.startDate);
            while (startDate.isSameOrBefore(next, 'month')) {
              if (repetitionEndTime && startDate.isAfter(repetitionEndTime, 'day')) {
                break;
              }

              const targetDayOfWeek = schedule.repetitionDayOfTheWeek?.[0] as DayOfTheWeek;
              if (schedule.repetitionPeriod === 'LAST') {
                targetDate = getLastDayOfWeekInMonth(startDate, targetDayOfWeek);
              } else {
                const weekNumber = ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE'].indexOf(schedule.repetitionPeriod!) + 1;
                targetDate = getNthDayOfWeekInMonth(startDate, targetDayOfWeek, weekNumber);
              }

              if (targetDate && targetDate.isSameOrAfter(previous) && targetDate.isSameOrBefore(next)) {
                repeatScheduleList.push({
                  ...schedule,
                  startDate: targetDate.format('YYYY-MM-DD'),
                  startTime: schedule.startTime,
                  endDate: targetDate.format('YYYY-MM-DD'),
                  endTime: schedule.endTime,
                  repetitionEndTime: schedule.repetitionEndTime,
                  dailyStartTime: schedule.startTime,
                  dailyEndTime: schedule.endTime,
                });
              }

              startDate = startDate.add(1, 'month');
            }
          } else if (schedule.monthlyRepeatType === EMonthlyRepeatType.SPECIFIC_DATE) {
            targetDate = dayjs(date).date(schedule.repetitionDate!);

            if (targetDate.month() !== dayjs(date).month()) {
              targetDate = null;
            }

            if (targetDate &&
              targetDate.isSameOrAfter(dayjs(schedule.startDate), 'day') &&
              (!repetitionEndTime || targetDate.isSameOrBefore(repetitionEndTime, 'day')) &&
              targetDate.isSameOrAfter(previous) &&
              targetDate.isSameOrBefore(next)) {
              repeatScheduleList.push({
                ...schedule,
                startDate: targetDate.format('YYYY-MM-DD'),
                startTime: schedule.startTime,
                endDate: targetDate.format('YYYY-MM-DD'),
                endTime: schedule.endTime,
                repetitionEndTime: schedule.repetitionEndTime,
                dailyStartTime: schedule.startTime,
                dailyEndTime: schedule.endTime,
              });
            }
          }
        } else {
          let startDate = dayjs(schedule.startDate + schedule.startTime);
          let endDate = dayjs(schedule.endDate! + schedule.endTime!);
          const repetitionEndTime = !schedule.repetitionEndTime ? undefined : dayjs(schedule.repetitionEndTime);
          const previous = dayjs(date).subtract(1, 'month');
          const next = dayjs(date).add(1, 'month');

          if (
            !(repetitionEndTime === undefined && !schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)) &&
            previous.isAfter(repetitionEndTime, 'month')
          ) {
            return;
          }

          if (startDate.isBefore(previous, 'month')) {
            endDate = endDate.add(previous.diff(startDate, 'month'), 'month');
            startDate = startDate.add(previous.diff(startDate, 'month'), 'month');
          }

          const dailyStartTime = schedule.startTime?.split(':').map(Number)!;
          const dailyEndTime = schedule.endTime?.split(':').map(Number)!;

          while (startDate.isSameOrBefore(next, 'month')) {
            if (
              !(repetitionEndTime === undefined && !schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)) &&
              startDate.isAfter(repetitionEndTime, 'day')
            ) {
              break;
            }

            const startDateAndTime = dayjs(startDate)
              .hour(dailyStartTime[0])
              .minute(dailyStartTime[1])
              .second(0)
              .toDate();

            if (schedule.repetitionType.equals(EScheduleRepetitionType.EVERY_DAY)) {
              repeatScheduleList.push({
                ...schedule,
                startDate: startDate.format('YYYY-MM-DD'),
                startTime: schedule.startTime,
                endDate: !repetitionEndTime
                  ? startDate.format('YYYY-MM-DD')
                  : startDate.isSame(repetitionEndTime, 'date')
                    ? repetitionEndTime?.format('YYYY-MM-DD')
                    : startDate.format('YYYY-MM-DD'),
                endTime: startDate.isSame(repetitionEndTime, 'date')
                  ? repetitionEndTime?.format('HH:mm')
                  : schedule.endTime,
                repetitionEndTime: schedule.repetitionEndTime,
                dailyStartTime: schedule.startTime,
                dailyEndTime: !repetitionEndTime
                  ? schedule.endTime
                  : startDate.isSame(repetitionEndTime, 'date')
                    ? repetitionEndTime?.format('HH:mm')
                    : schedule.endTime,
              });
            } else {
              const dayOfWeek = Weekdays.map(v => v.en.toUpperCase())[startDateAndTime.getDay()];
              if (schedule.repetitionDayOfTheWeek?.includes(dayOfWeek as DayOfTheWeek)) {
                repeatScheduleList.push({
                  ...schedule,
                  startDate: startDate.format('YYYY-MM-DD'),
                  startTime: schedule.startTime,
                  endDate: startDate.format('YYYY-MM-DD'),
                  endTime: schedule.endTime,
                  repetitionEndTime: schedule.repetitionEndTime,
                  dailyStartTime: schedule.startTime,
                  dailyEndTime: schedule.endTime,
                });
              }
            }
            startDate = startDate.add(1, 'day');
            endDate = endDate.add(1, 'day');
          }
        }
      });
      return repeatScheduleList;
    });
  }, [account.accessToken, selectDevice, date]);

  useEffect(() => {
    if (!selectDevice?.id) {
      return;
    }

    loadSchedule();
    loadContent();
  }, [selectDevice, reload]);

  useEffect(() => {
    loadSchedule();
  }, [date]);

  useEffect(() => {
    loadDevices();
  }, []);

  return (
    <Schedule
      account={account}
      onClickManage={onClickManage}
      scheduleList={scheduleList}
      contentList={contentList}
      deviceList={deviceList}
      selectDeviceList={selectDeviceList}
      selectDevice={selectDevice}
      setSelectDevice={setSelectDevice}
      setDate={setDate}
    />
  );
};

export default ScheduleContainer;
