import { useEffect, useState } from 'react';
import ContentPlaybackStatistics from '../ContentPlaybackStatistics';
import { apiRoute, requestGetFile, requestSecureGet } from '@libs/api';
import { accountSelector } from '@stories/account';
import { useRecoilValue } from 'recoil';
import { MonitoringDeviceType } from '@typedef/Monitoring/monitoring.type';
import dayjs, { Dayjs } from 'dayjs';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { convertMillisecondsToTime } from '@libs/convertMillisecondsToTime';
import { ContentsPlayStatisticsType } from '@typedef/Monitoring/monitoringContents.type';
import { ChartItemDateType, ChartItemType } from '@typedef/Monitoring/chart.type';
import {
  CommonDateType,
  DateRangeType,
  PeriodDefaultValue,
  PeriodType,
} from '@components/Common/CommonDate/CommonDate';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { EDeviceType } from '@typedef/Device/device-type.enum';
import { range } from 'lodash';

const ContentPlaybackStatisticsContainer = () => {
  const profileData = useRecoilValue(accountSelector);

  const [commonDate, setCommonDate] = useState<CommonDateType>({
    ...PeriodDefaultValue,
    startedAt: dayjs().subtract(1, 'month').toISOString(),
    dateRange: 'MONTH',
  });
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [zoneItems, setZoneItems] = useState<string[]>([]);
  const [chartItemValue, setChartItemValue] = useState<ChartItemDateType>({});

  const updateCommonDate = (value: PeriodType) => {
    const dateDiff = dayjs(value.endedAt).diff(dayjs(value.startedAt), 'day') + 1;
    let dateRange: DateRangeType = 'DAY';
    if (dateDiff > 1) {
      dateRange = 'MONTH';
    }
    setCommonDate({
      ...commonDate,
      ...value,
      dateRange,
    });
  };

  const loadDevice = async () => {
    const { config, data } = await requestSecureGet<PaginationTypes<MonitoringDeviceType>>(
      `${apiRoute.monitoring.loadDeviceList}${parseQueryParamsToString({
        paged: false,
        type: EDeviceType.COMPUTER.value,
      })}`,
      {},
      profileData.accessToken,
    );

    if (config.status !== 200) {
      return;
    }
    const filteredData = data.content.filter((item) => item.zone != null && !item.name.includes('프린터'));

    const result = filteredData
      .sort((a, b) => a.zone.name.localeCompare(b.zone.name))
      .map((value) => value.name + '-' + value.zone.name);

    setZoneItems(result);

    loadContentsPlaySummariesStatistics(filteredData);
  };

  const loadContentsPlaySummariesStatistics = async (deviceData: MonitoringDeviceType[]) => {
    const queryParams: Record<string, any> = {
      startedAt: `${commonDate.startedAt}~${commonDate.endedAt}`,
      endedAt: `${commonDate.startedAt}~${commonDate.endedAt}`,
      dateRange: commonDate.dateRange,
    };
    const { config, data } = await requestSecureGet<ContentsPlayStatisticsType[]>(
      `${apiRoute.monitoring.contentsPlaySummariesStatistics}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status !== 200) {
      return;
    }
    configChartData(data, deviceData);
  };

  const getChartLabels = (): string[] => {
    const startedAt = dayjs(commonDate.startedAt).startOf('day');
    if (commonDate.dateRange === 'DAY') {
      return range(24).map((i) => `${startedAt.add(i, 'hour').hour()}:00`);
    }

    const endedAt = dayjs(commonDate.endedAt).startOf('day');
    const dayDiff = endedAt.diff(startedAt, 'day') + 1;
    return range(dayDiff).map((i) => startedAt.add(i, 'day').format('D일'));
  };

  const getChartLabelDates = (): Dayjs[] => {
    const startedAt = dayjs(commonDate.startedAt).startOf('day');
    if (commonDate.dateRange === 'DAY') {
      return range(24).map((i) => startedAt.add(i, 'hour'));
    }

    const endedAt = dayjs(commonDate.endedAt).startOf('day');
    const dayDiff = endedAt.diff(startedAt, 'day') + 1;
    return range(dayDiff).map((i) => startedAt.add(i, 'day'));
  };

  const configChartData = (chartData: ContentsPlayStatisticsType[], deviceData: MonitoringDeviceType[]) => {
    const chartConfigData: { [key: string]: ChartItemType } = {};
    const reverseData: { [key: string]: ChartItemType } = {};
    const currentDate = dayjs(commonDate.startedAt).startOf('day');
    const labelDates: Dayjs[] = getChartLabelDates();
    setChartLabels(getChartLabels());

    deviceData
      .filter((item: MonitoringDeviceType) => item.isPowerManageable)
      .forEach((device) => {
        labelDates.forEach((_, index) => {
          const date = currentDate.add(index, commonDate.dateRange === 'DAY' ? 'hour' : 'day');
          const zoneName = device?.name + '-' + device?.zone.name;
          const deviceIp = device?.ip;

          if (!chartConfigData[zoneName]) {
            chartConfigData[zoneName] = { count: [], playtime: [], ip: deviceIp };
          }

          chartConfigData[zoneName].count.push(0);
          chartConfigData[zoneName].playtime.push(0);

          chartData.forEach((chartRow) => {
            if (!chartRow.createdAt || device.ip !== chartRow.ip) {
              return;
            }
            const createdAt = dayjs(chartRow.createdAt);
            if (commonDate.dateRange === 'DAY') {
              if (createdAt.hour() !== date.hour()) {
                return;
              }
            } else {
              if (createdAt.format('YYYY-MM-DD') !== date.format('YYYY-MM-DD')) {
                return;
              }
            }

            chartConfigData[zoneName].count[index] = chartRow.count;
            chartConfigData[zoneName].playtime[index] = convertMillisecondsToTime(
              Number(chartRow.playMilliSec),
            ).minutes;
          });
        });
      });

    Object.entries(chartConfigData).forEach(([key, value]) => {
      reverseData[key] = {
        count: value.count,
        playtime: value.playtime,
        ip: value.ip,
      };
    });

    setChartItemValue((prevState) => {
      return { ...prevState, ...chartConfigData };
    });
  };

  const contentsPlaySummariesStatisticsExcelDownload = async (name: string, ip: string) => {
    const queryParams: Record<string, any> = {
      startedAt: `${commonDate.startedAt}~${commonDate.endedAt}`,
      endedAt: `${commonDate.startedAt}~${commonDate.endedAt}`,
      dateRange: commonDate.dateRange,
      ip,
    };
    const { config, data, headers } = await requestGetFile<BlobPart>(
      `${apiRoute.monitoring.contentsPlaySummariesStatistics}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      const url = window.URL.createObjectURL(new Blob([data], { type: String(headers['Content-Type']) }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name} 데이터.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    void loadDevice();
  }, []);

  return (
    <ContentPlaybackStatistics
      chartItemValue={chartItemValue}
      contentsPlaySummariesStatisticsExcelDownload={contentsPlaySummariesStatisticsExcelDownload}
      zoneItems={zoneItems}
      chartLabels={chartLabels}
      commonDate={commonDate}
      updateCommonDate={updateCommonDate}
      onSummit={loadDevice}
    />
  );
};

export default ContentPlaybackStatisticsContainer;
