import { ContentTypes, defaultContentTypes } from '@typedef/Contents/contents.types';
import React, { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountSelector } from '@stories/account';
import { modalSelector } from '@stories/modal';
import { apiRoute, requestSecureDelete, requestSecurePost } from '@libs/api';
import WidgetManage from '../components/WidgetManage';
import { WidgetTypes } from '@typedef/Widget/widget.types';

type Props = {
  setSelectContent: React.Dispatch<React.SetStateAction<ContentTypes | null>>;
  setLayout: React.Dispatch<React.SetStateAction<WidgetTypes[]>>;
  setWidgetList: React.Dispatch<React.SetStateAction<WidgetTypes[]>>;
  setSelectLayout: React.Dispatch<React.SetStateAction<WidgetTypes | null>>;
  type: 'add' | 'delete';
  contentId?: string;
  setReload: React.Dispatch<React.SetStateAction<number>>;
  widgets: WidgetTypes[];
};

const WidgetManageContainer = ({
  setSelectContent,
  setLayout,
  setWidgetList,
  setSelectLayout,
  type,
  contentId,
  setReload,
  widgets,
}: Props) => {
  const token = useRecoilValue(accountSelector).accessToken;
  const setModal = useSetRecoilState(modalSelector);
  const [inputs, setInputs] = useState<ContentTypes>(defaultContentTypes);

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked } = e.target;

      setInputs({
        ...inputs,
        [name]: name === 'w' || name === 'h' ? Number(value) : name === 'offlineAvailable' ? checked : value,
      });
    },
    [inputs],
  );

  const onClickSubmit = useCallback(async () => {
    if (type === 'add') {
      if (!inputs.name) {
        alert('컨텐츠 이름을 설정해주세요');
        return;
      }

      const { config } = await requestSecurePost(apiRoute.contents.post, {}, inputs, token);

      if (config.status === 200) {
        setReload((prev) => prev + 1);
        setModal(null);
      }
    }

    if (type === 'delete') {
      const { config } = await requestSecureDelete(apiRoute.contents.delete + contentId, {}, {}, token);

      if (config.status === 200) {
        setReload((prev) => prev + 1);
        setModal(null);
      }
    }
    setSelectContent(null);
    setLayout([]);
    setWidgetList([]);
    setSelectLayout(null);
  }, [token, contentId, type, inputs, widgets]);

  return <WidgetManage onClickSubmit={onClickSubmit} type={type} handleInput={handleInput} inputs={inputs} />;
};

export default WidgetManageContainer;
