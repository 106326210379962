import { PaginationFilterInputsDefault } from '@typedef/common.types';

export type WeatherBody = {
  response: {
    header: {
      resultCode: string;
      resultMsg: string;
    };
    body?: {
      dataType: string;
      items: {
        item: {
          baseDate: string;
          baseTime: string;
          category: string;
          nx: number;
          ny: number;
          obsrValue: string; //실제값
        }[];
      };
      pageNo: number;
      numOfRows: number;
      totalCount: number;
    };
  };
};

export type WeatherTypes = {
  id: string;
  location1: string;
  location2: string;
  location3: string;
  locationCode: string;
  x: string;
  y: string;
  weather: string;
  createdAt: string;
};

export type SearchWeatherTypes = {
  id: string;
  value: string;
};

export const defaultWeatherTypes: WeatherTypes = {
  id: '',
  location1: '',
  location2: '',
  location3: '',
  locationCode: '',
  weather: '',
  x: '',
  y: '',
  createdAt: '',
};

export enum WeatherCategoryE {
  TEMP = 'T1H',
  RAINFALL = 'RN1', //강우량
  HUMINITY = 'REH', //습도
  PRECIPITATION = 'PTY',
  WINDSPEED = 'WSD', // m/s
}

export type WeatherDataTypes = {
  temperature: number; //기온
  rainfall: number; //강우량
  humidity: number; //습도
  precipitationForm: precipitationFormCodeE;
  // sky: SkyCodeE; //
  windSpeed: number; // m/s
};

export enum precipitationFormCodeE {
  UKNNOWN = -1,
  NONE = 0,
  RAIN = 1,
  RAINSNOW = 2,
  SNOW = 3,
  DRIZZLE = 5,
  DRIZZLESNOWFALL = 6,
  SNOWFALL = 7,
}

export const skyCodeType = [];

export const GetWeathersInputsDefault = {
  ...PaginationFilterInputsDefault,
  size: '50',
  location1: undefined as string | undefined,
  location2: undefined as string | undefined,
  location3: undefined as string | undefined,
};

export type GetWeatherInputsTypes = typeof GetWeathersInputsDefault;
