import { DeviceTypes } from '@typedef/Device/device.types';
import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import { EAgeGroup } from '@typedef/Visit/age-group.enum';
import { EGender } from '@typedef/Visit/gender.enum';
import { EVisitType } from '@typedef/Visit/visit-type.enum';
import { VisitorType } from '@typedef/Visit/visitor.types';
import { ZoneTypes } from './zone.type';
import { PageTypes } from '@typedef/libs/pagination.types';

export type SurveyCountType = {
  name: string;
  key: string;
  value: number;
};
export type SurveyResponseCountType = Omit<SurveyCountType, 'name'>;

export const SurveyCountDefaultValue: SurveyCountType = {
  key: '',
  name: '',
  value: 0,
};
export type LoaderExtendType<T> = {
  data: T;
  loading: boolean;
};
export type DateSurveyCountType = {
  weather: LoaderExtendType<SurveyCountType[]>;
  gender: LoaderExtendType<SurveyCountType[]>;
  ageGroup: LoaderExtendType<SurveyCountType[]>;
  visitType: LoaderExtendType<SurveyCountType[]>;
};

export type PeopleCount = LoaderExtendType<SurveyCountType>;

export type ZepetoLogResponseType = PageTypes & {
  content: ZepetoLogType[];
};

export type ZepetoLogType = {
  id: string;
  createdAt: string;
  updatedAt: null | string;
  peopleCount: number;
  totalPeopleCount: number;
};

export type MonitoringDeviceType = Omit<DeviceTypes, 'zoneId'> & {
  zone: ZoneTypes;
};

export type MonitoringResponseDeviceType = Omit<MonitoringDeviceType, 'displayType' | 'status' | 'type'> & {
  displayType?: string;
  status: string;
  type: string;
};

export type SurveysType = Omit<VisitorType, 'companionCount'>;

export type SurveyResponseType = Omit<SurveysType, 'ageGroup' | 'gender'> & {
  ageGroup: string;
  gender: string;
  visitType: string;
};

export const RealCityPeopleCountDefaultValue: LoaderExtendType<SurveyCountType> = {
  data: SurveyCountDefaultValue,
  loading: true,
};

export const DateSurveyCountDefaultValue: DateSurveyCountType = {
  weather: { data: [], loading: true },
  gender: { data: [], loading: true },
  ageGroup: { data: [], loading: true },
  visitType: { data: [], loading: true },
};

export const realCitySearchOptions: SearchTableType<VisitorType & { weatherId: string; startedAt: string }>[] = [
  {
    value: 'gender',
    name: '성별',
    type: 'dropdown',
    dropdownValues: EGender.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
  {
    value: 'createdAt',
    name: '방문 날짜',
    type: 'date',
  },
  {
    value: 'weatherId',
    name: '거주지역',
    type: 'weather',
  },
  {
    value: 'visitType',
    name: '방문 목적',
    type: 'dropdown',
    dropdownValues: EVisitType.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
  {
    value: 'ageGroup',
    name: '나이대',
    type: 'dropdown',
    dropdownValues: EAgeGroup.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
];

export const metaCitySearchOptions: SearchTableType<SurveysType & { weatherId: string; startedAt: string }>[] = [
  {
    value: 'gender',
    name: '성별',
    type: 'dropdown',
    dropdownValues: EGender.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
  {
    value: 'createdAt',
    name: '방문 날짜',
    type: 'date',
  },
  {
    value: 'weatherId',
    name: '거주지역',
    type: 'weather',
  },
  {
    value: 'visitType',
    name: '방문 목적',
    type: 'dropdown',
    dropdownValues: EVisitType.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
  {
    value: 'ageGroup',
    name: '나이대',
    type: 'dropdown',
    dropdownValues: EAgeGroup.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
];
