import '../styles/CustomStyle.scss';
import { FontStyleTypes } from '@typedef/Widget/font.types';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import ReactDropdown, { Option } from 'react-dropdown';
import { FontDropdownContainer } from '@components/Widgets/styles/WidgetStyle';
import { isNumberFromString } from '@libs/inputValidator';

type Props = {
  textStyleList: FontStyleTypes[];
  handleOnChange: (id: string, value: any) => void;
  selectLayout: WidgetTypes | null;
};

const FONT_FAMILY_LIST: { id: string; name: string }[] = [
  {
    id: 'default',
    name: '기본',
  },
  {
    id: 'IBM Plex Sans KR',
    name: 'IBM Plex Sans KR',
  },
  {
    id: 'Dongle',
    name: 'Dongle',
  },
  {
    id: 'Gowun Batang',
    name: 'Gowun Batang',
  },
  {
    id: 'Hahmlet',
    name: 'Hahmlet',
  },
  {
    id: 'Noto Serif KR',
    name: 'Noto Serif KR',
  },
  {
    id: 'Gaegu',
    name: 'Gaegu',
  },
  {
    id: 'Gothic A1',
    name: 'Gothic A1',
  },
  {
    id: 'Noto Sans KR',
    name: 'Noto Sans KR',
  },
  {
    id: 'Nanum Myeongjo',
    name: 'Nanum Myeongjo',
  },
  {
    id: 'Nanum Gothic Coding',
    name: 'Nanum Gothic Coding',
  },
  {
    id: 'Nanum Gothic',
    name: 'Nanum Gothic',
  },
];

const DROPDOWN_FONT_FAMILY_LIST = FONT_FAMILY_LIST.map((font) => ({
  value: font.id,
  label: font.name,
  className: font.name.replaceAll(' ', '-'),
}));

const FontStyle = ({ textStyleList, handleOnChange, selectLayout }: Props) => {
  return (
    <div className="custom">
      <div className="font-style">
        <p className="title">폰트</p>
        <div className="container">
          <div className="family">
            <p className="subtitle">글꼴</p>
            <FontDropdownContainer
              font={
                FONT_FAMILY_LIST.find((data) => data.id === (selectLayout?.textFamily ?? ''))?.name ??
                FONT_FAMILY_LIST[0]?.name
              }
            >
              <ReactDropdown
                options={DROPDOWN_FONT_FAMILY_LIST}
                className={'font-style-dropdown'}
                value={
                  FONT_FAMILY_LIST.find((data) => data.id === (selectLayout?.textFamily ?? ''))?.name ??
                  FONT_FAMILY_LIST[0]?.name
                }
                onChange={(value: Option) => {
                  handleOnChange('textFamily', value.value);
                }}
              />
            </FontDropdownContainer>
          </div>

          <div className="color">
            <p className="subtitle">폰트 색상</p>
            <input
              type="color"
              value={selectLayout?.textColor ?? '#000000'}
              onChange={(e) => {
                handleOnChange('textColor', e.target.value);
              }}
            />
          </div>

          <div className="style">
            <p className="subtitle">디자인</p>
            {textStyleList.map((data) => (
              <div className="option" key={data.id} style={{ backgroundColor: data.value ? '#97EBF4' : '#ffffff' }}>
                <label htmlFor={data.id}>{data.image}</label>

                <input
                  id={data.id}
                  type="checkbox"
                  checked={data.value}
                  onChange={(e) => {
                    handleOnChange(data.id, e.target.checked);
                  }}
                />
              </div>
            ))}
          </div>
          <div className="size">
            <p className="subtitle">크기(pt)</p>
            <input
              type="text"
              placeholder="0"
              value={selectLayout?.textSize ?? 0}
              onChange={(e) => {
                if (isNumberFromString(e.target.value)) handleOnChange('textSize', Number(e.target.value));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FontStyle;
