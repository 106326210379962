import { ContentTypes, EBackgroundType } from '@typedef/Contents/contents.types';
import React, { useCallback } from 'react';
import CustomSize from '../components/CustomSize';
import CustomOfflineMode from '@components/Widgets/components/CustomOfflineMode';
import CustomBackgroundColor from '@components/Widgets/components/CustomBackgroundColor';
import CustomBackgroundType from '@components/Widgets/components/CustomBackgroundType';
import CustomBackgroundTheme from '@components/Widgets/components/CustomBackgroundTheme';

type Props = {
  onContentOptionChange: React.Dispatch<React.SetStateAction<ContentTypes | null>>;
  selectContent: ContentTypes | null;
};

const CustomContentContainer = ({ onContentOptionChange, selectContent }: Props) => {
  const handleOnChange = useCallback(
    (id: string, value: any) => {
      if (selectContent) onContentOptionChange({ ...selectContent, [id]: value });
    },
    [selectContent, onContentOptionChange],
  );

  return (
    <>
      <CustomSize handleOnChange={handleOnChange} selectLayout={selectContent} />
      <CustomOfflineMode handleOnChange={handleOnChange} selectLayout={selectContent} />
      <div className={'subtitle-text'}>배경 설정</div>
      <CustomBackgroundType handleOnChange={handleOnChange} selectLayout={selectContent} />
      {selectContent?.backgroundType === EBackgroundType.COLOR &&
        <CustomBackgroundColor handleOnChange={handleOnChange} selectLayout={selectContent} />}
      {selectContent?.backgroundType === EBackgroundType.THEME &&
        <CustomBackgroundTheme handleOnChange={handleOnChange} selectLayout={selectContent} />}
    </>
  );
};

export default CustomContentContainer;
