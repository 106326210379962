import { ContentTypes } from '@typedef/Contents/contents.types';
import React from 'react';
import { StyledTooltip } from '@components/Main/styles/MainStyle';
import { Info } from '@assets/icons';
import { ToggleSwitch } from '@components/Profile/styles/ProfileStyle';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: ContentTypes | null;
};

const CustomOfflineMode: React.FC<Props> = ({ handleOnChange, selectLayout }) => {
  return (
    <div className="custom">
      <div className={'flex-container'}>
        <p className="title">
          오프라인
          <StyledTooltip
            title="오프라인 모드: 초기 데이터 로딩 이후 네트워크 없이 사용 가능, 실시간 업데이트 없음."
            placement={'bottom'}>
            <Info />
          </StyledTooltip>
        </p>
        <div className={'toggle-container'}>
          <ToggleSwitch
            checkedColor={'#15B9F5'}
            checkedBorderColor={'#1490bc'}
          >
            <input
              type="checkbox"
              name={'offlineAvailable'}
              checked={selectLayout?.offlineAvailable}
              onChange={() => handleOnChange('offlineAvailable', !selectLayout?.offlineAvailable)}
            />
            <div className="toggle-switch-background">
              <div className="toggle-switch-handle" />
            </div>
          </ToggleSwitch>
          {selectLayout?.offlineAvailable ? '사용' : '사용 안함'}
        </div>
      </div>
    </div>
  );
};

export default CustomOfflineMode;