export type EWidgetTypeProps =
  | 'MULTIMEDIA'
  | 'HEADER'
  | 'MAIN'
  | 'SUB'
  | 'BLOCK'
  | 'NEWS'
  | 'WEATHER'
  | 'TIME'
  | 'FINEDUST'
  | 'FILE'
  | 'STOCK_INDEX'
  | 'SMART_BOARD'
  | 'IFRAME'
  | 'ORDER_LIST'
  | 'FILE_LIST';

export class EWidgetType {
  constructor(
    readonly value: EWidgetTypeProps,
    readonly name: string,
  ) {
  }

  static readonly MULTIMEDIA = new EWidgetType('MULTIMEDIA', '멀티미디어');
  static readonly HEADER = new EWidgetType('HEADER', '헤더');
  static readonly MAIN = new EWidgetType('MAIN', '메인');
  static readonly SUB = new EWidgetType('SUB', '자막');
  static readonly BLOCK = new EWidgetType('BLOCK', '위젯');
  static readonly NEWS = new EWidgetType('NEWS', '뉴스');
  static readonly WEATHER = new EWidgetType('WEATHER', '날씨');
  static readonly TIME = new EWidgetType('TIME', '시간');
  static readonly FINEDUST = new EWidgetType('FINEDUST', '미세먼지');
  static readonly FILE = new EWidgetType('FILE', '파일');
  static readonly STOCK_INDEX = new EWidgetType('STOCK_INDEX', '주식 지수');
  static readonly SMART_BOARD = new EWidgetType('SMART_BOARD', '스마트 게시판');
  static readonly IFRAME = new EWidgetType('IFRAME', '웹 컨텐츠');
  static readonly ORDER_LIST = new EWidgetType('ORDER_LIST', '주문 목록');
  static readonly FILE_LIST = new EWidgetType('FILE_LIST', '다중파일');

  static readonly values = [
    EWidgetType.HEADER,
    EWidgetType.MAIN,
    EWidgetType.SUB,
    EWidgetType.BLOCK,
    EWidgetType.NEWS,
    EWidgetType.WEATHER,
    EWidgetType.TIME,
    EWidgetType.FINEDUST,
    EWidgetType.FILE,
    EWidgetType.FILE_LIST,
    EWidgetType.STOCK_INDEX,
    EWidgetType.SMART_BOARD,
    EWidgetType.IFRAME,
    EWidgetType.ORDER_LIST,
  ];

  static valueOf(value: EWidgetTypeProps | string): EWidgetType | undefined {
    return EWidgetType.values.find((v) => v.value === value);
  }

  static isValue(value: string): boolean {
    return !!EWidgetType.values.find((v) => v.value === value);
  }

  equals(value: EWidgetTypeProps | EWidgetType) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
