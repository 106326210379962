import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import { ECompanyType } from './company-type.enum';

export type CompanyTypes = OrderNumberTypes & {
  type: ECompanyType;
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  number: string; //사업자번호
  currentFileSize: number;
  maxFileSize: number;
  currentDeviceCount: number;
  maxDeviceCount: number;
  isCafe: boolean;
  scheduleOption: ScheduleOptionTypes;
};

export type DropdownCompanyType = {
  value: string;
  label: string;
  companyId?: string;
};

export type OrderNumberTypes = {
  orderNumberAutoGeneration: boolean;
  orderNumberPrefixValue: string | null;
  orderNumberIntValue: number | null;
};

export type ScheduleOptionTypes = {
  text?: string;
  fontColor?: string;
  backgroundColor?: string
}

export type CompanyRequestType = {
  name: string;
  number: string;
  type: ECompanyType;
  isCafe: boolean;
};

export const CompanyRequestDefault: CompanyRequestType = {
  name: '',
  number: '',
  type: ECompanyType.NORMAL,
  isCafe: false,
};

export type CompanyResponseTypes = Omit<CompanyTypes, 'type'> & { type: string };

export const CompanyTypesDefault: CompanyTypes = {
  type: ECompanyType.NORMAL,
  createdAt: '',
  currentDeviceCount: -1,
  currentFileSize: -1,
  id: '',
  maxDeviceCount: -1,
  maxFileSize: -1,
  name: '',
  number: '',
  updatedAt: '',
  isCafe: false,
  orderNumberAutoGeneration: false,
  orderNumberPrefixValue: '',
  orderNumberIntValue: 0,
  scheduleOption: {},
};
export const companySearchOptions: SearchTableType<CompanyTypes>[] = [
  {
    value: 'name',
    name: '회사 이름',
    type: 'string',
  },
  {
    value: 'number',
    name: '사업자번호',
    type: 'string',
  },
  {
    value: 'type',
    name: '유형',
    type: 'dropdown',
    dropdownValues: ECompanyType.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
];

export type CompanySearchOptionTypes = 'name' | 'number';
