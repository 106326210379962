import styled, { css } from 'styled-components';
import { BoardStyleProps } from '@typedef/Widget/widget.types';
import { EBackgroundThemeType } from '@typedef/Contents/contents.types';
import { getThemeBackground } from '@libs/getThemeBackground';
import { getFontSize } from '@libs/getFontStyle';
import { MINIO_URL } from '@libs/remote';

const PostActiveStyle = css`
    border: 2px solid #15b9f5;
    background: #15b9f5;

    & > .message {
        color: #fff !important;
    }

    & > svg {
        path, circle {
            fill: #fff !important;
        }
    }
`;

export const SmartBoardStyle = styled.div<{
  backgroundTheme?: EBackgroundThemeType;
  padding?: number;
  gap?: number;
  backgroundColor?: string;
}>`
    width: 100%;
    height: 100%;
    padding: ${({ padding }) => padding ?? 0}px;
    gap: ${({ gap }) => gap ?? 0}px;
    background-color: ${({ backgroundColor, backgroundTheme }) => backgroundColor ?? '#fff'};
    overflow: hidden;
    box-sizing: border-box;
    white-space: pre-wrap;
    display: flex !important;
    justify-content: space-between;
    flex-direction: column;

    ${({ backgroundTheme }) =>
            backgroundTheme &&
            css`
                background-image: url(${getThemeBackground(backgroundTheme)});
                background-size: cover;
            `};
`;

export const SmartBoardRow = styled.div<{ gap?: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${({ gap }) => gap ?? 0}px;
`;

export const SmartBoardPost = styled.div<BoardStyleProps>`
    border-radius: 5px;

    ${({ boxShadow }) =>
            boxShadow &&
            css`
                box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
            `}

    ${({ fileUrl, active, width, height, contentWidth, contentHeight, isSetting }) =>
            fileUrl?.length
                    ? css`
                        background: url('${MINIO_URL}/${fileUrl}');
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    `
                    : isSetting &&
                    css`
                        border: 2px dashed #15b9f5;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        transition: all 0.25s;

                        ${active && PostActiveStyle};

                        &:hover {
                            ${!fileUrl && PostActiveStyle}
                        }

                        & > .message {
                            margin-top: 4%;
                            font-size: ${css`
                            calc(0.5 * ${getFontSize(
                                    width,
                                    height,
                                    {
                                        w: contentWidth,
                                        h: contentHeight,
                                    },
                                    true,
                            )})`};

                            color: #15b9f5;
                            font-weight: 500;
                            text-align: center;
                        }

                        & > svg {
                            width: 35%;
                            height: auto;
                            margin-bottom: 5%;

                            path,
                            circle {
                                fill: #15b9f5;
                            }
                        }
                    `};
`;
