export type EFileTypeProps = 'VIDEO' | 'IMAGE' | 'SLIDEIMAGE';

export class EFileType {
  static readonly VIDEO: EFileType = new EFileType('VIDEO', ['mp4']);
  static readonly IMAGE: EFileType = new EFileType('IMAGE', ['jpg', 'jpeg', 'png', 'gif']);
  static readonly SLIDEIMAGE: EFileType = new EFileType('SLIDEIMAGE', ['jpg', 'jpeg', 'png', 'gif']);

  private constructor(
    public readonly code: EFileTypeProps,
    public readonly fileExtensions: string[],
  ) {
  }

  validate(filename: string): boolean {
    const extension = filename.split('.').pop()?.toLowerCase();
    if (!extension) return false;
    return this.fileExtensions.includes(extension);
  }
}
