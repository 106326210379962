import { apiRoute, requestSecureDelete, requestSecurePatch, requestSecurePost } from '@libs/api';
import { accountSelector } from '@stories/account';
import { modalSelector } from '@stories/modal';
import { CompanyTypes, CompanyTypesDefault } from '@typedef/Company/company.types';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import CompanyManage from '../components/CompanyManage';

type Props = {
  id: string;
  type: string;
  checkedList: CompanyTypes[] | null;
  reload: () => void;
};

const CompanyManageContainer = ({ id, type, checkedList, reload }: Props) => {
  const [companyData, setCompanyData] = useState<CompanyTypes>(CompanyTypesDefault);

  const token = useRecoilValue(accountSelector).accessToken;
  const setModal = useSetRecoilState(modalSelector);

  // 회사 수정
  const updateCompany = useCallback(async () => {
    if (token.length === 0) return;

    const { config } = await requestSecurePatch(
      apiRoute.company.update + companyData.id,
      {},
      { ...companyData, type: companyData.type.value },
      token,
    );

    if (config.status === 200) {
      setModal(null);

      reload();
    } else {
      alert(config.message);
    }
  }, [companyData, token, reload, setModal]);

  // 회사 추가
  const addCompany = useCallback(async () => {
    if (token.length === 0) return;

    const { config } = await requestSecurePost(
      apiRoute.company.post,
      {},
      { ...companyData, type: companyData.type.value },
      token,
    );

    if (config.status === 200) {
      setModal(null);

      reload();
    } else {
      alert(config.message);
    }
  }, [companyData, token, reload, setModal]);
  // 회사 삭제
  const deleteCompany = useCallback(async () => {
    let response: boolean = true;
    let message: string = '';

    if (token.length === 0) return;

    if (checkedList) {
      await Promise.all(
        checkedList.map(async (item) => {
          const { config } = await requestSecureDelete(apiRoute.company.delete + item.id, {}, {}, token);
          if (config.status !== 200) {
            response = false;
            message = config.message;
          }
        }),
      );
    }

    setModal(null);

    reload();

    if (!response) alert(message);
  }, [token, checkedList, reload, setModal]);

  // 버튼 클릭시 함수 실행
  const onClickBtn = useCallback(() => {
    if (id === 'update') {
      updateCompany();
    } else if (id === 'delete') {
      deleteCompany();
    } else if (id === 'add') {
      addCompany();
    }
  }, [id, updateCompany, deleteCompany, addCompany]);

  useEffect(() => {
    if (checkedList?.length === 1) {
      setCompanyData({
        id: checkedList[0].id,
        name: checkedList[0].name,
        number: checkedList[0].number, //사업자번호
        currentDeviceCount: checkedList[0].currentDeviceCount,
        maxDeviceCount: checkedList[0].maxDeviceCount,
        currentFileSize: checkedList[0].currentFileSize,
        maxFileSize: checkedList[0].maxFileSize,
        createdAt: checkedList[0].createdAt,
        updatedAt: checkedList[0].updatedAt,
        type: checkedList[0].type,
        isCafe: checkedList[0].isCafe,
        orderNumberAutoGeneration: checkedList[0].orderNumberAutoGeneration,
        orderNumberPrefixValue: checkedList[0].orderNumberPrefixValue,
        orderNumberIntValue: checkedList[0].orderNumberIntValue,
        scheduleOption: checkedList[0].scheduleOption,
      });
    }
  }, [checkedList]);

  return (
    <CompanyManage
      id={id}
      type={type}
      companyData={companyData}
      setCompanyData={setCompanyData}
      onClickBtn={onClickBtn}
    />
  );
};

export default CompanyManageContainer;
