import { css } from '@emotion/react';
import { MoonLoader } from 'react-spinners';
import './styles/Spinner.scss';

type Props = {
  width?: number;
  height?: number;
  message?: string;
  size?: number;
  color?: string;
};

const override = css`
  display: block;
  margin: 0 auto;
`;

const Spinner = ({ width, height, message, size, color }: Props) => {
  return (
    <div
      className="loading"
      style={{
        width: width ? width + 'px' : '100vw',
        height: height ? height + 'px' : '100vh',
      }}
    >
      <MoonLoader color={color ?? '#256662'} loading={true} css={override} size={size} />
      <p>{message}</p>
    </div>
  );
};

export default Spinner;
