import dayjs from 'dayjs';

export const PeriodDefaultValue = {
  startedAt: dayjs().startOf('day').toISOString(),
  endedAt: dayjs().endOf('day').toISOString(),
};

export type PeriodType = typeof PeriodDefaultValue;

export type DateRangeType = 'DAY' | 'WEEK' | 'MONTH';

export type CommonDateType = {
  startedAt: string;
  endedAt: string;
  dateRange: DateRangeType;
};
