import styled from 'styled-components';
import { DayOfTheWeek, ScheduleActiveTypes } from '@typedef/Schedule/schedule.types';
import { dayStyles } from '@libs/getActiveDateStyle';

export const Container = styled.main`
    width: 600px;

    .split {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        margin-top: 5px;

        .preview-btn {
            margin-left: auto;
            margin-right: 5px;
            font-weight: 500;
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8ab4f8;

            & > svg {
                margin-right: 4px;
                width: 18px;
                height: 18px;

                path {
                    fill: #8ab4f8;
                }
            }

            &:hover {
                color: #578ce3;

                & > svg {

                    path {
                        fill: #578ce3;
                    }
                }
            }
        }

        select {
            width: auto;
            max-width: 50%;
            padding: 5px;
        }

        .subtitle {
            font-weight: 600;
        }
    }

    .warning {
        margin-bottom: 10px;
        margin-top: 5px;
        justify-content: end;
        min-height: 2vh;

        p {
            color: red;
            font-size: 13px;
            font-weight: bold;
        }

        svg {
            width: 13px;
            margin-bottom: 2px;
            margin-right: 3px;
        }
    }

    .time {
        margin-bottom: 0;
    }

    .error-phrases {
        float: right;
        width: 265px;
        color: #d32f2f;
        text-align: right;
        font-size: 13px;
        padding-top: 15px;
    }
`;

export const Weeks = styled.div`
    ${({ theme }) => theme.flex.row}
    flex-wrap: wrap;
    gap: 15px;
`;

export const Week = styled.button<{ active: boolean }>`
    //width: 60px;
    padding: 0 1rem;
    height: 30px;
    background-color: ${({ active }) => (active ? '#FFA800' : '#fff')};
    border: 1px solid #FFA800;
    border-radius: 3px;
    color: ${({ active }) => active ? '#fff' : '#333'};
    font-weight: ${({ active }) => active && 600};
`;

export const Times = styled.div`
    ${({ theme }) => theme.flex.row};
    align-items: center;
    flex-wrap: wrap;
    width: 265px;

    .datepicker-custom-input {
        width: 185px;
        height: 25px;
    }

    & fieldset {
        border: 1px solid rgb(37, 102, 98);
    }

    & legend,
    .MuiInputLabel-shrink {
        color: rgb(37, 102, 98);
    }

    & .MuiStack-root,
    .MuiInputBase-root {
        width: 265px;
    }
`;

export const SelectWeeks = styled.div<ScheduleActiveTypes>`
    ${({ theme }) => theme.flex.row};
    align-items: center;
    flex-wrap: wrap;
    height: 40px;
    width: auto;
    gap: 10px;

    & > div {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        border: 1px solid #2b3e63;
        color: #2b3e63;
        font-size: 12px;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.2s;
    }

    & > .sun {
        ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.SUN))}
    }

    & > .mon {
        ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.MON))}
    }

    & > .tues {
        ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.TUES))}
    }

    & > .wed {
        ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.WED))}
    }

    & > .thurs {
        ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.THURS))}
    }

    & > .fri {
        ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.FRI))}
    }

    & > .sat {
        ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.SAT))}
    }
`;

export const SelectMonth = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: ${({ active }) => active ? '#1976d211' : '#fff'};
    border: ${({ active }) => active ? '2px solid #1976d2' : '1px solid rgba(0, 0, 0, 0.87)'};
    padding: 5px 10px;
    width: 265px;
    height: 50px;
    cursor: pointer;

    &:last-child {
        margin-top: 5px;
    }

    & > input[type=radio] {
        margin-right: auto;
    }

    .custom-value {
        font-size: 15px;

        .repetition-period-select {
            width: 85px;
        }

        & > select, input {
            font-size: 14px;
            font-weight: 600;
            width: 45px;
            margin: 0 5px;
            border-radius: 4px;
            border: 1px solid #ccc;
            text-align: center;
            height: 30px;
        }

        & > input {
            width: 30px;
        }
    }
`;
