import { WeatherContainer, WeatherHandlingContainer } from '@components/Common/Weather/styles/WeatherStyles';
import { weatherProps } from '@components/Common/Weather/containers/WeatherContainer';
import { WeatherSunnyDay } from '@assets/icons';
import { ContentTypes } from '@typedef/Contents/contents.types';
import Spinner from '@components/Common/Spinner/Spinner';
import React from 'react';
import { ResolutionType } from '@typedef/Widget/widget.types';

type Props = {
  isSetting: boolean;
  size: {
    width: number;
    height: number;
  };
  getIcon: () => React.ReactElement | null;
  loading: boolean;
  weatherData: weatherProps;
  error: boolean;
  resolution: ResolutionType;
  content: ContentTypes;
  resolutionIconLocation: { top: string[]; right: string[] };
};

const isResolutionMatch = (resolution: string, resolutionArray: string[]): boolean => {
  return resolutionArray.includes(resolution);
};

const WeatherIcon = ({ isSetting, getIcon }: { isSetting: boolean; getIcon: () => React.ReactElement | null }) => {
  if (isSetting) {
    return (
      <div className={'icon-container'}>
        <WeatherSunnyDay />
      </div>
    );
  } else {
    return <div className={'icon-container'}>{getIcon() || <WeatherSunnyDay />}</div>;
  }
};

const WeatherInfo = ({ isSetting, weatherData }: { isSetting: boolean; weatherData: weatherProps }) => {
  if (isSetting) {
    return (
      <div className={'temperature'}>
        <div>
          <div>0°C</div>
          <div>0°C / 0°C</div>
        </div>
        <div>강수량 0mm</div>
      </div>
    );
  } else {
    return (
      <div className={'temperature'}>
        <div>
          <div>{weatherData.temperaturePerHour}°C</div>
          <div>
            {weatherData.minTemperatureInDay}°C / {weatherData.maxTemperatureInDay}°C
          </div>
        </div>
        <div>강수량 {weatherData.precipitationForm}mm</div>
      </div>
    );
  }
};

const Weather = ({
  isSetting,
  size,
  loading,
  error,
  getIcon,
  weatherData,
  resolution,
  content,
  resolutionIconLocation,
}: Props) => {
  if (error && !isSetting) {
    return <WeatherHandlingContainer>날씨 정보를 조회에 실패했습니다.</WeatherHandlingContainer>;
  } else if (loading && !isSetting) {
    return (
      <WeatherHandlingContainer>
        <Spinner message={'날씨 정보를 조회중입니다...'} color={'#fff'} />
      </WeatherHandlingContainer>
    );
  } else {
    const containerStyle = {
      width: size.width,
      height: size.height,
      isSetting: isSetting,
      resolution: resolution,
      contentHeight: content.h,
      contentWidth: content.w,
    };

    return (
      <WeatherContainer {...containerStyle}>
        <div>
          <div>
            <div className={'title'}>날씨</div>

            {isResolutionMatch(resolution, resolutionIconLocation.top) && (
              <WeatherIcon isSetting={isSetting} getIcon={getIcon} />
            )}

            <WeatherInfo isSetting={isSetting} weatherData={weatherData} />
          </div>

          {isResolutionMatch(resolution, resolutionIconLocation.right) && (
            <div>{isSetting ? <WeatherSunnyDay /> : getIcon() || <WeatherSunnyDay />}</div>
          )}
        </div>
      </WeatherContainer>
    );
  }
};

export default Weather;
