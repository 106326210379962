export type EVisitTypeProps = '교육' | '업무' | '체험' | '거주자' | '여행';
//  | '개인업무' | '기타' | ''

export class EVisitType {
  constructor(
    readonly value: EVisitTypeProps,
    readonly name: string,
  ) {}

  static readonly EDUCATION = new EVisitType('교육', '교육');
  static readonly WORK = new EVisitType('업무', '업무');
  // static readonly PERSONAL_WORK = new EVisitType('개인업무', '개인업무');
  static readonly EXPERIENCE = new EVisitType('체험', '체험');
  static readonly RESIDENT = new EVisitType('거주자', '거주자');
  static readonly TRAVEL = new EVisitType('여행', '여행');
  // static readonly ETC = new EVisitType('기타', '기타');
  // static readonly EMPTY = new EVisitType('', '응답없음');

  static readonly values = [
    EVisitType.EDUCATION,
    EVisitType.WORK,
    // EVisitType.PERSONAL_WORK,
    EVisitType.EXPERIENCE,
    EVisitType.RESIDENT,
    EVisitType.TRAVEL,
    // EVisitType.ETC,
    // EVisitType.EMPTY,
  ];

  static valueOf(value: EVisitTypeProps | string): EVisitType {
    return EVisitType.values.find((v) => v.value === value)!;
  }

  equals(value: EVisitTypeProps | EVisitType) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
