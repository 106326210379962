import styled from 'styled-components';

export const DropdownStyle = styled.div<{
  width?: string | number;
  fontSize?: string | number;
  height?: string | number;
}>`
    width: ${({ width }) => (width ? width : '100%')};

    height: ${({ height }) => (height ? height : '100%')};

    .Dropdown-placeholder {
        font-size: ${({ fontSize }) => fontSize};
        font-weight: bold;
    }

    .Dropdown-option {
        font-size: ${({ fontSize }) => fontSize};
        font-weight: normal;
    }

    .react-dropdown-main {
        width: 100%;
        font-size: ${({ fontSize }) => fontSize};
        font-weight: normal;
        height: 100%;
    }

    .react-dropdown-control {
        display: flex;
        width: 100%;
        padding: 0 15px;
        background-color: #fff;
        border-radius: 5px;
        height: 100%;

        font-size: ${({ fontSize }) => fontSize};
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
        border: #ffffffff;
    }

    .react-dropdown-menu {
        width: 100%;
        font-size: ${({ fontSize }) => fontSize};
        border-radius: 5px;
        font-weight: normal;
    }

    .Dropdown-arrow {
        border-width: 0 0 0;
    }
`;
