export type EAgeGroupProps =
  | 'UNDER_10'
  | 'TEEN'
  | 'TWENTIES'
  | 'THIRTIES'
  | 'FORTIES'
  | 'FIFTIES'
  | 'SIXTIES'
  | 'SEVENTIES'
  | 'EIGHTIES'
  | 'NINETIES'
  | 'OVER_100'
  | 'UNKNOWN';

export class EAgeGroup {
  constructor(
    readonly value: EAgeGroupProps,
    readonly name: string,
  ) {}

  static readonly UNDER_10 = new EAgeGroup('UNDER_10', '10대 미만');
  static readonly TEEN = new EAgeGroup('TEEN', '10대');
  static readonly TWENTIES = new EAgeGroup('TWENTIES', '20대');
  static readonly THIRTIES = new EAgeGroup('THIRTIES', '30대');
  static readonly FORTIES = new EAgeGroup('FORTIES', '40대');
  static readonly FIFTIES = new EAgeGroup('FIFTIES', '50대');
  static readonly SIXTIES = new EAgeGroup('SIXTIES', '60대');
  static readonly SEVENTIES = new EAgeGroup('SEVENTIES', '70대');
  static readonly EIGHTIES = new EAgeGroup('EIGHTIES', '80대');
  static readonly NINETIES = new EAgeGroup('NINETIES', '90대');
  static readonly OVER_100 = new EAgeGroup('OVER_100', '100대 이상');
  static readonly UNKNOWN = new EAgeGroup('UNKNOWN', '미상');

  static readonly values = [
    EAgeGroup.UNDER_10,
    EAgeGroup.TEEN,
    EAgeGroup.TWENTIES,
    EAgeGroup.THIRTIES,
    EAgeGroup.FORTIES,
    EAgeGroup.FIFTIES,
    EAgeGroup.SIXTIES,
    EAgeGroup.SEVENTIES,
    EAgeGroup.EIGHTIES,
    EAgeGroup.NINETIES,
    EAgeGroup.OVER_100,
    EAgeGroup.UNKNOWN,
  ];

  static valueOf(value: EAgeGroupProps | string): EAgeGroup {
    return EAgeGroup.values.find((v) => v.value === value)!;
  }

  equals(value: EAgeGroupProps | EAgeGroup) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
