import { apiRoute, requestSecureDelete, requestSecureGet, requestSecurePatch } from '@libs/api';
import { accountSelector } from '@stories/account';
import { modalSelector } from '@stories/modal';
import { AccountTypes, AccountTypesDefault } from '@typedef/Account/account.types';
import { ApiDataResponseType } from '@typedef/libs/api.types';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AccountManage from '../components/AccountManage';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { Option } from 'react-dropdown';

type Props = {
  id: string;
  type: string;
  checkedList: AccountTypes[] | null;
  reload: () => void;
};

const AccountManageContainer = ({ id, type, checkedList, reload }: Props) => {
  const [duplicateFlag, setDuplicateFlag] = useState<boolean>(false);
  const [accountData, setAccountData] = useState<AccountTypes>(AccountTypesDefault);
  const [roleList, setRoleList] = useState<Option[]>([]);

  const token = useRecoilValue(accountSelector).accessToken;
  const role = useRecoilValue(accountSelector).role;
  const setModal = useSetRecoilState(modalSelector);

  // 아이디 중복확인
  const accountIdDuplicate = useCallback(async () => {
    if (token.length === 0) return;

    if (accountData.userId.length === 0) return alert('아이디를 입력해주세요');

    const { config, data } = await requestSecureGet<ApiDataResponseType>(
      apiRoute.account.checkIdGet + accountData.userId,
      {},
      token,
    );

    if (config.status === 200) {
      alert(data.message);

      if (data.response) setDuplicateFlag(true);
    } else {
      alert(config.message);
    }
  }, [token, accountData]);

  // 계정 수정
  const updateAccount = useCallback(async () => {
    if (token.length === 0) return;

    if (!duplicateFlag) return alert('아이디 중복확인을 해주세요.');

    const { config } = await requestSecurePatch(
      apiRoute.account.updateWithRootMaster + accountData.id,
      {},
      {
        ...accountData,
        role: accountData.role.value,
        company: { ...accountData.company, type: accountData.company.type.value },
      },
      token,
    );

    if (config.status === 200) {
      setModal(null);

      setDuplicateFlag(true);

      reload();
    } else {
      alert(config.message);
    }
  }, [accountData, token, duplicateFlag, reload]);

  // 계정 삭제
  const deleteAccounts = useCallback(async () => {
    let response: boolean = true;
    let message: string = '';

    if (token.length === 0) return;

    if (checkedList) {
      await Promise.all(
        checkedList.map(async (item) => {
          const { config } = await requestSecureDelete(apiRoute.account.delete + item.id, {}, {}, token);

          if (config.status !== 200) {
            response = false;
            message = config.message;
          }
        }),
      );
    }

    setModal(null);

    reload();

    if (!response) alert(message);
  }, [token, checkedList, reload, setModal]);

  // 버튼 클릭시 함수 실행
  const onClickBtn = useCallback(() => {
    if (id === 'update') {
      updateAccount();
    } else if (id === 'delete') {
      deleteAccounts();
    }
  }, [id, updateAccount, deleteAccounts]);

  useEffect(() => {
    if (checkedList?.length === 1) {
      setDuplicateFlag(true);

      setAccountData({
        userId: checkedList[0].userId,
        password: checkedList[0].password,
        accessToken: '',
        userName: checkedList[0].userName,
        company: checkedList[0].company,
        loginType: checkedList[0].loginType,
        id: checkedList[0].id,
        role: checkedList[0].role,
        createdAt: checkedList[0].createdAt,
        updatedAt: checkedList[0].updatedAt,
      });
    }

    setRoleList(() => {
      let clone = EAccountRole.values.map((v) => {
        return { label: v.name, value: v.value };
      });

      if (EAccountRole.ROOT.equals(role) || EAccountRole.MASTER.equals(role)) {
        clone = clone.filter((item) => item.value !== 'ROOT');
      } else if (EAccountRole.MANAGER.equals(role)) {
        clone = clone.filter((item) => item.value !== 'ROOT' && item.value !== 'MASTER');
      } else {
        return [];
      }
      return clone;
    });
  }, [checkedList]);

  return (
    <AccountManage
      id={id}
      type={type}
      accountData={accountData}
      setAccountData={setAccountData}
      setDuplicateFlag={setDuplicateFlag}
      onClickBtn={onClickBtn}
      accountIdDuplicate={accountIdDuplicate}
      roleList={roleList}
    />
  );
};

export default AccountManageContainer;
