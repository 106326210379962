import { ContentTypes, EBackgroundType } from '@typedef/Contents/contents.types';
import React from 'react';
import CustomCheckBox from '@components/Common/CustomCheckBox/CustomCheckBox';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: ContentTypes | null;
};

const CustomBackgroundType: React.FC<Props> = ({ handleOnChange, selectLayout }) => {
  return (
    <div className="custom">
      <div className="background-type">
        <p className="title">타입</p>
        <div className={'checkbox-container'}>
          <CustomCheckBox label={'색상'} checked={selectLayout?.backgroundType === EBackgroundType.COLOR}
                          onChange={() => handleOnChange('backgroundType', EBackgroundType.COLOR)} />
          <CustomCheckBox label={'테마'} checked={selectLayout?.backgroundType === EBackgroundType.THEME}
                          onChange={() => handleOnChange('backgroundType', EBackgroundType.THEME)} />
        </div>
      </div>
    </div>
  );
};

export default CustomBackgroundType;