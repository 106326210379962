import React from 'react';
import { CustomCheckbox } from '@components/Widgets/styles/WidgetStyle';
import { BoardPostType } from '@typedef/Widget/widget.types';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectPost: BoardPostType | null;
};

const CustomShadow = ({ handleOnChange, selectPost }: Props) => {
  return (
    <div className="custom">
      <div className={'flex-container'}>
        <p className="title">
          그림자 효과
        </p>
        <div>
          <CustomCheckbox checked={selectPost?.shadow} onChange={e => handleOnChange('shadow', e.target.checked)} />
        </div>
      </div>
    </div>
  );
};

export default CustomShadow;