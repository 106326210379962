export enum EOrderStatusType {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  PICKED_UP = 'PICKED_UP',
}

export type OrderStepTypes = {
  status: EOrderStatusType;
  name: string;
};

export type SelectedOrderTypes = {
  status: EOrderStatusType;
  ids: string[];
};

export type OrderTypes = {
  id: string;
  number: string;
  status: EOrderStatusType;
  createdAt: string;
  updatedAt: string | null;
};

export type OrderDetailTypes = OrderTypes & { open: boolean };

export type OrderListTypes<T> = {
  [key in EOrderStatusType]: T;
};

export type OrderListWidgetTypes<T> = {
  [key in keyof typeof EOrderStatusType as Exclude<key, 'PICKED_UP'>]: T;
};

export type AlertMessageType = {
  body: { message: string };
};

export const orderStepList: OrderStepTypes[] = [
  { status: EOrderStatusType.PENDING, name: '대기' },
  { status: EOrderStatusType.COMPLETED, name: '픽업' },
  { status: EOrderStatusType.PICKED_UP, name: '완료' },
];

export const orderWidgetStepList = orderStepList.slice(0, -1);
