import * as S from '@components/Login/styles/LoginCompanyStyle';
import React, { useCallback, useEffect, useState } from 'react';
import { apiRoute, requestGet } from '@libs/api';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { CompanyTypes } from '@typedef/Company/company.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import Spinner from '@components/Common/Spinner/Spinner';
import { LoadingModalContainer } from '@components/Widgets/styles/WidgetStyle';
import { Company } from '@assets/icons';
import { useSetRecoilState } from 'recoil';
import { modalSelector } from '@stories/modal';

type Props = {
  companyName: string;
  onSelect: React.Dispatch<React.SetStateAction<CompanyTypes | null>>;
};

const LoginCompanyModal: React.FC<Props> = ({ companyName, onSelect }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [companyList, setCompanyList] = useState<CompanyTypes[]>([]);
  const setModal = useSetRecoilState(modalSelector);

  const searchCompany = useCallback(async () => {
    const params = { paged: false, name: companyName, required: true };

    const { config, data } = await requestGet<PaginationTypes<CompanyTypes>>(
      `${apiRoute.company.getList}${parseQueryParamsToString(params)}`,
      {},
    );

    if (config.status === 200) {
      setCompanyList(data.content);
    } else {
      alert(config.message);
    }

    setLoading(false);
  }, [companyName]);

  const handleSelect = useCallback((company: CompanyTypes) => {
    onSelect(company);
    setModal(null);
  }, []);

  useEffect(() => {
    searchCompany();
  }, []);

  return (
    <S.LoginCompanyModalStyle className={'modalBody'} loading={loading}>
      {loading ? (
          <LoadingModalContainer>
            <Spinner width={100} height={100} size={50} />
            <div className={'loading-text'}>검색하신 회사를 조회 중입니다...</div>
          </LoadingModalContainer>
        ) :
        companyList.length ?
          companyList.map((v, i) => (
            <S.CompanyList key={`company-${i}`}>
              <div className={'icon-container'}><Company /></div>
              <div className={'company-info'}>
                <div>{v.name}</div>
                <div><span>사업자 등록번호:</span> {v.number ?? '-'}</div>
              </div>
              <button onClick={() => handleSelect(v)}>선택</button>
            </S.CompanyList>
          ))
          : <div className={'empty-list'}>검색하신 회사가 존재하지 않습니다.</div>
      }
    </S.LoginCompanyModalStyle>
  );
};

export default LoginCompanyModal;