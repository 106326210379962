import React from 'react';
import styled from 'styled-components';
import PieChart from '@components/Common/Chart/PieChart';
import LineChart from '@components/Common/Chart/LineChart';
import { getDateWeeks } from '@components/Monitoring/tempChartData';

const Guide = () => {
  return (
    <div>
      <Container>
        <PieChart
          data={{
            labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
            datasets: [
              {
                label: '# of Votes',
                data: [1, 2, 3, 4, 5],
              },
            ],
          }}
        />
      </Container>
      <Container>
        <LineChart
          data={{
            labels: getDateWeeks().dateWeek,
            datasets: [
              {
                label: '사용 횟수',
                data: [300, 50, 100, 200, 100, 300, 50, 100, 200, 100, 300, 50, 100],
                fill: false,
                borderColor: '#3b2566',
                tension: 0.1
              },
              {
                label: '사용 시간',
                data: [200, 150, 230, 40, 60, 100, 150, 130, 240, 60, 130, 240, 60],
                fill: false,
                borderColor: '#256662',
                tension: 0.1
              },
            ],
          }}
        />
      </Container>
    </div>
  );
};

export default Guide;

const Container = styled.div`
  width: 400px;
  padding: 40px;
  background-color: #fff;
`;
