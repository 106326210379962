import styled, { css } from 'styled-components';
import { Header } from '@components/Devices/styles/DevicesStyle';
import Tooltip from '@mui/material/Tooltip';

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const MainHeader = styled(Header)`
    & > div {
        width: 160px;
        font-size: 1.5rem;
        font-weight: 600;

        background: linear-gradient(to top, #15b9f566 70%, transparent 50%);
    }
`;

export const Main = styled.div<{ loading: boolean }>`
    width: calc(100% - 40px);
    height: auto;
    min-height: 70vh;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
    border-radius: 7px;
    margin: 20px;
    background-color: #fff;

    ${({ loading }) => loading && css`
        display: flex;
        align-items: center;
        justify-content: center;
    `};
`;

export const DeviceNothing = styled.div`
    width: 100%;
    height: 100%;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    font-weight: bold;
`;

export const LoadingText = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DeviceList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: stretch;
    align-content: start;
    padding: 20px;
`;

export const Device = styled.button`
    //display: flex;
    //flex-direction: column;
    width: 100%;
    border: 1px solid #eee;
    overflow: hidden;
    border-radius: 7px;
    padding: 0;
    position: relative;
`;

export const DeviceName = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, .9) 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(3px);
    height: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: opacity 0.3s ease;
    padding: 0 12px;

    &:hover {
        opacity: 1;
    }

    & .device-name {
        & > div {
            color: #fff;
            text-align: left;

            &:first-child {
                font-size: 18px;
                font-weight: 600;
            }

            &:last-child {
                font-size: 14px;
                opacity: 0.8;
            }
        }
    }
`;

export const DeviceImageBox = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    background-color: #000;
    color: #fff;
`;

export const DeviceImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: fill;
`;

export const DeviceNotWork = styled.div`
    width: 100%;
    height: 100%;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        color: #474747;
        font-size: 16px;
        font-weight: 500;
    }
`;

export const ImageSize = styled.div`
    top: 10px;
    left: 10px;
    width: auto;
    height: auto;

    font-size: 12px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 3px 5px;
    border-radius: 5px;

    position: absolute;
`;

export const ContentName = styled.div`
    width: auto;
    height: auto;

    margin-top: 10px;
    font-size: 20px;
`;

export const DeviceStatus = styled.button<{ status: boolean }>`
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background-color: ${({ status }) => (status ? '#ECF9F0' : '#8f8f8f')};
    color: ${({ status }) => (status ? '#3DC061' : '#ededed')};
    float: right;
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
    font-size: 12px;
    margin-left: auto;
`;

export const StyledTooltip = styled(Tooltip)`
    height: auto;
`;