import '@components/Common/Modal/styles/ModalBody.scss';
import * as S from '../styles/ScheduleManageStyle';
import React, { forwardRef } from 'react';
import {
  DayOfTheWeek,
  EMonthlyRepeatType,
  RepetitionPeriods,
  RepetitionPeriodType,
  ScheduleTypes,
  Weekdays,
} from '@typedef/Schedule/schedule.types';
import { ContentTypes } from '@typedef/Contents/contents.types';
import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import dayjs from 'dayjs';
import { getFullDate } from '@libs/dateUtil';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import ko from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateTimeValidationError, DesktopDateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { Eye } from '@assets/icons';
import { AccountTypes } from '@typedef/Account/account.types';
import { EAccountRole } from '@typedef/Account/account-role.enum';

dayjs.extend(ko);
dayjs.extend(timezone);

type Props = {
  account: AccountTypes;
  type: '추가' | '수정';
  inputs: ScheduleTypes;
  onRepetitionTypeChanged: (repetitionType: EScheduleRepetitionType) => void;
  onClickAddSchedule: () => void;
  onClickUpdateSchedule: () => void;
  onClickDeleteSchedule: () => void;
  onDayWeeksChanged: (day: DayOfTheWeek) => void;
  setScheduleData: React.Dispatch<React.SetStateAction<ScheduleTypes>>;
  timeTransform: (time: string) => number;
  contentList: ContentTypes[];
  onClickPreview: () => void;
  handleErrorMessage: (error: DateTimeValidationError) => void;
  errorMessage: string | null;
  handleSelectMonth: () => void;
};

const ScheduleManage = ({
  account,
  type,
  inputs,
  onRepetitionTypeChanged,
  onClickAddSchedule,
  onClickUpdateSchedule,
  onClickDeleteSchedule,
  setScheduleData,
  onDayWeeksChanged,
  contentList,
  onClickPreview,
  handleErrorMessage,
  errorMessage,
  handleSelectMonth,
}: Props) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button className="datepicker-custom-input" onClick={onClick} ref={ref}>
      {(value as string).length !== 0 ? value : '날짜를 선택해주세요.'}
    </button>
  ));

  return (
    <S.Container className="modalBody">
      <article className="contents">
        <div className="split">
          <p className="subtitle">컨텐츠 선택</p>
          {inputs.contentId && (
            <button onClick={onClickPreview} className="preview-btn">
              <Eye /> 미리보기
            </button>
          )}
          <select
            id={inputs.contentId.toString()}
            value={inputs.contentId}
            onChange={(e) => {
              setScheduleData({
                ...inputs,
                contentId: e.target.value,
              });
            }}
          >
            <option>선택</option>
            {contentList.map((content) => (
              <option value={content.id}>{content.name}</option>
            ))}
          </select>
        </div>
        <div className="split">
          <p className="subtitle">반복 유형</p>
          <S.Weeks>
            {EScheduleRepetitionType.values.map((repetitionType) => (
              <S.Week
                key={repetitionType.value}
                active={inputs.repetitionType.equals(repetitionType.value)}
                onClick={() => onRepetitionTypeChanged(repetitionType)}
              >
                {repetitionType.name}
              </S.Week>
            ))}
          </S.Weeks>
        </div>

        {inputs.repetitionType && (
          <>
            <div className="split time">
              <p className="subtitle">
                {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) ? '시작시간' : '시작날짜'}
              </p>
              <S.Times>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                  {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label={'시작시간'}
                        format={'HH:mm'}
                        maxTime={
                          inputs.endTime
                            ? dayjs(getFullDate(inputs.endDate, inputs.endTime)).subtract(1, 'minute')
                            : null
                        }
                        value={inputs.startDate ? dayjs(getFullDate(inputs.startDate, inputs.startTime)) : null}
                        onChange={(value) => {
                          setScheduleData({
                            ...inputs,
                            startDate: dayjs(value).format('YYYY-MM-DD'),
                            startTime: dayjs(value).format('HH:mm'),
                          });
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <DesktopDateTimePicker
                      label={'시작날짜'}
                      format={'YYYY년 MM월 DD일 HH:mm'}
                      maxDateTime={
                        inputs.endTime ? dayjs(getFullDate(inputs.endDate, inputs.endTime)).subtract(1, 'minute') : null
                      }
                      value={inputs.startDate ? dayjs(getFullDate(inputs.startDate, inputs.startTime)) : null}
                      onChange={(value) => {
                        setScheduleData({
                          ...inputs,
                          startDate: dayjs(value).format('YYYY-MM-DD'),
                          startTime: dayjs(value).format('HH:mm'),
                        });
                      }}
                    />
                  )}
                </LocalizationProvider>
              </S.Times>
            </div>

            <div className={'split'} />

            <div className="split time">
              <p className="subtitle">
                {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) ? '종료시간' : '종료날짜'}
              </p>
              <S.Times>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                  {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label={'종료시간'}
                        format={'HH:mm'}
                        minTime={
                          inputs.startTime
                            ? dayjs(getFullDate(inputs.startDate, inputs.startTime)).add(1, 'minute')
                            : null
                        }
                        onError={(err: DateTimeValidationError) => handleErrorMessage(err)}
                        value={
                          inputs.endDate || inputs.dailyEndTime
                            ? dayjs(getFullDate(inputs.endDate, inputs.endTime ? inputs.endTime : inputs.dailyEndTime))
                            : null
                        }
                        onChange={(value) => {
                          setScheduleData({
                            ...inputs,
                            endDate: dayjs(value).format('YYYY-MM-DD'),
                            endTime: dayjs(value).format('HH:mm'),
                          });
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <DesktopDateTimePicker
                      label={'종료날짜'}
                      format={'YYYY년 MM월 DD일 HH:mm'}
                      minDateTime={
                        inputs.startTime
                          ? dayjs(getFullDate(inputs.startDate, inputs.startTime)).add(1, 'minute')
                          : null
                      }
                      onError={(err: DateTimeValidationError) => handleErrorMessage(err)}
                      value={inputs.endTime ? dayjs(getFullDate(inputs.endDate, inputs.endTime)) : null}
                      onChange={(value) => {
                        setScheduleData({
                          ...inputs,
                          endDate: dayjs(value).format('YYYY-MM-DD'),
                          endTime: dayjs(value).format('HH:mm'),
                        });
                      }}
                    />
                  )}
                </LocalizationProvider>
              </S.Times>
            </div>

            {inputs.repetitionType.equals(EScheduleRepetitionType.EVERY_WEEK) && (
              <>
                <div className={'split'} />
                <div className="split time">
                  <p className="subtitle">요일 설정</p>
                  <S.SelectWeeks repetitionDayOfTheWeek={inputs.repetitionDayOfTheWeek}>
                    {Weekdays.map((day, i) => (
                      <div className={day.en} onClick={() => onDayWeeksChanged(day.day)}>
                        {day.ko}
                      </div>
                    ))}
                  </S.SelectWeeks>
                </div>
              </>
            )}

            {inputs.repetitionType.equals(EScheduleRepetitionType.EVERY_MONTH) && (
              <>
                <div className={'split'} />
                <div className="split time">
                  <p className="subtitle">요일 설정</p>
                  <div>
                    <S.SelectMonth
                      active={inputs.monthlyRepeatType === EMonthlyRepeatType.NTH_DATE}
                      onClick={() => {
                        setScheduleData({
                          ...inputs,
                          monthlyRepeatType: EMonthlyRepeatType.NTH_DATE,
                        });
                        handleSelectMonth();
                      }}>
                      <input type={'radio'} name={'monthlyRepeatType'}
                             value={EMonthlyRepeatType.NTH_DATE}
                             checked={inputs.monthlyRepeatType === EMonthlyRepeatType.NTH_DATE}
                      />
                      <div className={'custom-value'}>
                        매월
                        <select
                          className={'repetition-period-select'}
                          onChange={e => setScheduleData({
                            ...inputs,
                            repetitionPeriod: e.target.value as unknown as RepetitionPeriodType,
                          })}
                          value={inputs.repetitionPeriod}
                        >
                          {RepetitionPeriods.map((v, i) => (
                            <option value={v.value} key={`month-select-${i}`}>{v.name}</option>
                          ))}
                        </select>
                        <select
                          onChange={e => setScheduleData({
                            ...inputs,
                            repetitionDayOfTheWeek: [e.target.value as DayOfTheWeek],
                          })}
                          value={inputs.repetitionDayOfTheWeek ? inputs.repetitionDayOfTheWeek[0] : DayOfTheWeek.MON}
                        >
                          {Weekdays.map((day, i) => (
                            <option value={day.day} key={`select-date-${i}`}>{day.ko}</option>
                          ))}
                        </select>
                        요일
                      </div>
                    </S.SelectMonth>

                    <S.SelectMonth
                      active={inputs.monthlyRepeatType === EMonthlyRepeatType.SPECIFIC_DATE}
                      onClick={() => {
                        setScheduleData({
                          ...inputs,
                          monthlyRepeatType: EMonthlyRepeatType.SPECIFIC_DATE,
                        });
                      }}>
                      <input type={'radio'} name={'monthlyRepeatType'}
                             value={EMonthlyRepeatType.SPECIFIC_DATE}
                             checked={inputs.monthlyRepeatType === EMonthlyRepeatType.SPECIFIC_DATE}
                      />
                      <div className={'custom-value'}>
                        매월
                        <input
                          value={inputs.repetitionDate}
                          min={0}
                          max={31}
                          maxLength={2}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            if (value <= 31) {
                              setScheduleData({
                                ...inputs,
                                repetitionDate: value,
                              });
                            }
                          }}
                        />
                        일
                      </div>
                    </S.SelectMonth>
                  </div>
                </div>
              </>
            )}

            {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) && (
              <>
                <div className="split" />
                <div className="split time">
                  <p className="subtitle">반복 종료 시간</p>
                  <S.Times>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                      <DesktopDateTimePicker
                        label={'종료날짜'}
                        format={'YYYY년 MM월 DD일 HH:mm'}
                        minDateTime={
                          inputs.endTime ? dayjs(getFullDate(inputs.endDate, inputs.endTime)).add(1, 'minute') : null
                        }
                        onError={(err: DateTimeValidationError) => handleErrorMessage(err)}
                        value={inputs.repetitionEndTime ? dayjs(inputs.repetitionEndTime) : null}
                        onChange={(value) => {
                          setScheduleData({
                            ...inputs,
                            repetitionEndTime: dayjs(value).format('YYYY-MM-DDTHH:MM'),
                          });
                        }}
                      />
                    </LocalizationProvider>
                  </S.Times>
                </div>
              </>
            )}
          </>
        )}

        {errorMessage !== null && <p className={'error-phrases'}>{errorMessage}</p>}
      </article>
      {
        EAccountRole.accessRole(account.role) &&
        <article className="bottoms">
          {type === '수정' ? (
            <>
              <button onClick={onClickUpdateSchedule}>{type}하기</button>
              <button className="danger" onClick={onClickDeleteSchedule}>
                삭제하기
              </button>
            </>
          ) : (
            <button onClick={onClickAddSchedule}>{type}하기</button>
          )}
        </article>
      }
    </S.Container>
  );
};

export default ScheduleManage;
