import { FineDustContainer } from '@components/Common/FineDust/styles/FineDustStyle';
import { pmGradeType, pmValueType } from '@components/Common/FineDust/containers/FineDustContainer';
import { FineDustCommon, FineDustGood } from '@assets/icons';
import React from 'react';
import { ContentTypes } from '@typedef/Contents/contents.types';
import { ResolutionType } from '@typedef/Widget/widget.types';

type Props = {
  size: {
    width: number;
    height: number;
  };
  loading: boolean;
  getIcon: (pm10Grade: number | undefined) => React.ReactElement | undefined;
  getText: (pm25Grade: number | undefined) => string | undefined;
  pmGrade: pmGradeType;
  pmValue: pmValueType;
  isSetting: boolean;
  resolution: ResolutionType;
  content: ContentTypes;
};

const FineDustItem = ({
  title,
  grade,
  value,
  getIcon,
  getText,
  isSetting,
  isPm10,
  resolution,
}: {
  title: string;
  grade: number | undefined;
  value: number | undefined;
  getIcon: (grade: number | undefined) => React.ReactElement | undefined;
  getText: (grade: number | undefined) => string | undefined;
  isSetting: boolean;
  isPm10: boolean;
  resolution: string;
}) => {
  const gradesList = ['좋음', '보통', '나쁨', '매우 나쁨'];

  return (
    <div>
      <div>
        <div className={'title'}>{title}</div>
        {resolution === '4:3' && (
          <div className={'icon-container'}>
            {isSetting ? isPm10 ? <FineDustGood /> : <FineDustCommon /> : getIcon(grade)}
          </div>
        )}
        <div className={`${isPm10 ? 'grade10' : 'grade25'}`}>{isSetting ? gradesList[grade! - 1] : getText(grade)}</div>
        <div className={'unit'}>
          <span>{value}</span>㎍/㎥
        </div>
      </div>
      {resolution !== '4:3' && (
        <div className={'icon-container'}>
          {isSetting ? isPm10 ? <FineDustGood /> : <FineDustCommon /> : getIcon(grade)}
        </div>
      )}
    </div>
  );
};

const renderFineDustContent = (
  isSetting: boolean,
  loading: boolean,
  getIcon: (pm10Grade: number | undefined) => React.ReactElement | undefined,
  getText: (pm25Grade: number | undefined) => string | undefined,
  pmGrade: pmGradeType,
  pmValue: pmValueType,
  resolution: string,
) => {
  if (isSetting) {
    return (
      <>
        <FineDustItem
          title="미세먼지"
          grade={1}
          value={12}
          getIcon={getIcon}
          getText={getText}
          isSetting={isSetting}
          isPm10={true}
          resolution={resolution}
        />
        <div className={'divider'} />
        <FineDustItem
          title="초미세먼지"
          grade={2}
          value={16}
          getIcon={getIcon}
          getText={getText}
          isSetting={isSetting}
          isPm10={false}
          resolution={resolution}
        />
      </>
    );
  } else if (loading) {
    return <div className={'loading'}>Loading ...</div>;
  }

  return (
    <>
      <FineDustItem
        title="미세먼지"
        grade={pmGrade.pm10Grade === 0 ? pmGrade.pm10Grade1h : pmGrade.pm10Grade}
        value={pmValue.pm10Value}
        getIcon={getIcon}
        getText={getText}
        isSetting={isSetting}
        isPm10={true}
        resolution={resolution}
      />
      <div className={'divider'} />
      <FineDustItem
        title="초미세먼지"
        grade={pmGrade.pm25Grade === 0 ? pmGrade.pm25Grade1h : pmGrade.pm25Grade}
        value={pmValue.pm25Value}
        getIcon={getIcon}
        getText={getText}
        isSetting={isSetting}
        isPm10={false}
        resolution={resolution}
      />
    </>
  );
};

const FineDust = ({ size, loading, getIcon, getText, pmGrade, pmValue, isSetting, resolution, content }: Props) => {
  return (
    <FineDustContainer
      height={size.height}
      width={size.width}
      grade10={isSetting ? 1 : pmGrade.pm10Grade === 0 ? pmGrade.pm10Grade1h : pmGrade.pm10Grade}
      grade25={isSetting ? 2 : pmGrade.pm25Grade === 0 ? pmGrade.pm25Grade1h : pmGrade.pm25Grade}
      isSetting={isSetting}
      resolution={resolution}
      contentWidth={content.w}
      contentHeight={content.h}
    >
      <div>
        <div className={'icon-container'}>
          {renderFineDustContent(isSetting, loading, getIcon, getText, pmGrade, pmValue, resolution)}
        </div>
      </div>
    </FineDustContainer>
  );
};

export default FineDust;
