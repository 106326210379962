export type EDeviceStatusTypes = 'OFF' | 'ON' | 'REQUEST_OFF' | 'REQUEST_ON';

export class EDeviceStatus {
  static readonly OFF: EDeviceStatus = new EDeviceStatus('OFF', false);
  static readonly ON: EDeviceStatus = new EDeviceStatus('ON', true);
  static readonly REQUEST_OFF: EDeviceStatus = new EDeviceStatus('REQUEST_OFF', true); // still on
  static readonly REQUEST_ON: EDeviceStatus = new EDeviceStatus('REQUEST_ON', false); // still off

  private constructor(
    public readonly value: EDeviceStatusTypes,
    public readonly data: boolean,
  ) {}
  static readonly values = [EDeviceStatus.OFF, EDeviceStatus.ON, EDeviceStatus.REQUEST_OFF, EDeviceStatus.REQUEST_ON];

  static valueOf(value: EDeviceStatusTypes | string): EDeviceStatus | undefined {
    return EDeviceStatus.values.find((v) => v.value === value);
  }

  static isValue(value: string): boolean {
    return !!EDeviceStatus.values.find((v) => v.value === value);
  }

  equals(value: EDeviceStatusTypes | EDeviceStatus) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
