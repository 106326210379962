import { saveStatusSelector } from '@stories/contentSetting';
import { modalSelector } from '@stories/modal';
import { useSetRecoilState } from 'recoil';
import * as S from '../styles/ContentModalStyle';

type props = {
  onSave: ()=>void;
  onCancle: ()=>void;
}

const ContentWarning = ({onSave,onCancle} : props) => {
  const setSaveStatus = useSetRecoilState(saveStatusSelector);

  const setModal = useSetRecoilState(modalSelector);

  return (
    <S.Container className="modalBody">
      <article className="contents">
        <div>
          <p className="main">컨텐츠 설정 값들이 저장되지 않았습니다. 저장하시겠습니까?</p>
          <p className="sub">컨텐츠를 선택하셨거나 위젯을 수정하였다면 경고가 발생합니다.</p>
        </div>
      </article>
      <article className="bottoms">
        <button
          onClick={() => {
            setModal(null);
            onSave();
          }}
        >
          저장
        </button>
        <button
          className="danger"
          onClick={() => {
            setModal(null);
            onCancle();
          }}
        >
          아니요
        </button>
      </article>
    </S.Container>
  );
};

export default ContentWarning;
