import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import { useCallback, useEffect, useState } from 'react';
import {
  DateSurveyCountDefaultValue,
  DateSurveyCountType,
  LoaderExtendType,
  PeopleCount,
  RealCityPeopleCountDefaultValue,
  SurveyCountType,
  SurveyResponseCountType,
  SurveyResponseType,
  SurveysType,
} from '@typedef/Monitoring/monitoring.type';
import { apiRoute, requestGetFile, requestSecureGet } from '@libs/api';
import MetaCity from '@components/Monitoring/MetaCity';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { EAgeGroup } from '@typedef/Visit/age-group.enum';
import { EGender } from '@typedef/Visit/gender.enum';
import { EVisitType } from '@typedef/Visit/visit-type.enum';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { PeriodDefaultValue, PeriodType } from '@components/Common/CommonDate/CommonDate';

const MetaCityContainer = () => {
  const profileData = useRecoilValue(accountSelector);

  const [period, setPeriod] = useState<PeriodType>(PeriodDefaultValue);

  const [surveysData, setSurveysData] = useState<SurveysType[]>([]);
  const [surveyCountData, setSurveyCountData] = useState<DateSurveyCountType>(DateSurveyCountDefaultValue);
  const [webGLPeopleCount, setWebGLPeopleCount] = useState<PeopleCount>(RealCityPeopleCountDefaultValue);
  const [zepetoPeopleCount, setZepetoPeopleCount] = useState<PeopleCount>(RealCityPeopleCountDefaultValue);

  const loadSurveyCountByColumn = async (column: keyof DateSurveyCountType) => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyResponseCountType[]>(
      `${apiRoute.monitoring.surveyColumnCount.replace('{column}', column)}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      const updateData = (targetData: DateSurveyCountType) => {
        let updateData: LoaderExtendType<SurveyCountType[]> = { data: [], loading: false };
        switch (column) {
          case 'ageGroup':
            updateData.data = data?.map((v) => {
              return { ...v, name: EAgeGroup.valueOf(v.key).name };
            });
            break;
          case 'gender':
            updateData.data = data?.map((v) => {
              return { ...v, name: EGender.valueOf(v.key).name };
            });
            break;
          case 'visitType':
            updateData.data = data?.map((v) => {
              // if (v.key.length === 0) return { ...v, name: EVisitType.EMPTY.name };
              // else
              return { ...v, name: EVisitType.valueOf(v.key).name };
            });
            break;
          case 'weather':
            updateData.data = data?.map((v) => {
              return { ...v, name: v.key };
            });
            break;
          default:
            break;
        }

        return {
          ...targetData,
          [column]: updateData,
        };
      };

      setSurveyCountData((prevState) => updateData(prevState));
    }
  };

  const loadWebGLPeopleCount = async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType>(
      `${apiRoute.monitoring.webGLPeopleCount}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status !== 200) {
      return;
    }
    setWebGLPeopleCount((prevState) => ({
      ...prevState,
      data: data,
      loading: false,
    }));
  };

  const loadZepetoPeopleCount = useCallback(async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType>(
      `${apiRoute.monitoring.zepetoPeopleCount}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status !== 200) {
      return;
    }
    setZepetoPeopleCount((prevState) => ({
      ...prevState,
      data: data,
      loading: false,
    }));
  }, [period, profileData.accessToken]);

  const loadSurveys = async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
      size: 7,
    };
    const {
      config,
      data: { content },
    } = await requestSecureGet<PaginationTypes<SurveyResponseType>>(
      `${apiRoute.monitoring.getSurveys}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status !== 200) {
      return;
    }
    setSurveysData(
      content?.map((v) => {
        return {
          ...v,
          ageGroup: EAgeGroup.valueOf(v.ageGroup),
          gender: EGender.valueOf(v.gender),
          visitType: EVisitType.valueOf(v.visitType),
        };
      }),
    );
  };

  const surveyExcelDownload = async (column: string) => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data, headers } = await requestGetFile<BlobPart>(
      `${apiRoute.excel.surveyExcelDownload.replace('{column}', column)}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status !== 200) {
      return;
    }

    const url = window.URL.createObjectURL(new Blob([data], { type: String(headers['Content-Type']) }));
    const link = document.createElement('a');
    link.href = url;
    let koColumn;
    if (column === 'ageGroup') {
      koColumn = '나이대';
    } else if (column === 'weather') {
      koColumn = '지역';
    } else if (column === 'visitType') {
      koColumn = '방문 목적';
    } else if (column === 'gender') {
      koColumn = '성별';
    }
    link.setAttribute('download', `${koColumn} 데이터.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  const onSummit = async () => {
    setSurveysData([]);

    await Promise.all([
      loadWebGLPeopleCount(),
      loadZepetoPeopleCount(),
      loadSurveys(),
      Object.keys(DateSurveyCountDefaultValue).map(async (column) =>
        loadSurveyCountByColumn(column as keyof DateSurveyCountType),
      ),
    ]);
  };

  useEffect(() => {
    onSummit();
  }, []);

  return (
    <MetaCity
      surveysData={surveysData}
      surveyCountData={surveyCountData}
      webGLPeopleCount={webGLPeopleCount}
      zepetoPeopleCount={zepetoPeopleCount}
      surveyExcelDownload={surveyExcelDownload}
      period={period}
      setPeriod={setPeriod}
      onSummit={onSummit}
    />
  );
};

export default MetaCityContainer;
