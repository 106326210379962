import { apiRoute, requestSecureDelete, requestSecureGet, requestSecurePatch, requestSecurePost } from '@libs/api';
import { accountSelector } from '@stories/account';
import { modalSelector } from '@stories/modal';
import { convertDeviceTypeToResponseType, defaultDeviceTypes, DeviceTypes } from '@typedef/Device/device.types';
import { ApiDataResponseType } from '@typedef/libs/api.types';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DeviceManage from '../components/DeviceManage';
import { EDeviceType } from '@typedef/Device/device-type.enum';

type Props = {
  id: 'add' | 'update' | 'delete';
  type: '추가' | '수정' | '삭제';
  devices?: DeviceTypes[];
  reload: () => void;
};

const DeviceManageContainer = ({ id, type, devices, reload }: Props) => {
  const [deviceData, setDeviceData] = useState<DeviceTypes>(defaultDeviceTypes);

  const account = useRecoilValue(accountSelector);
  const token = account.accessToken;
  const setModal = useSetRecoilState(modalSelector);
  const [originalIP, setOriginalIP] = useState('');
  const [originalMAC, setOriginalMAC] = useState<string | undefined>(undefined);

  // 장비 추가
  const onClickAddDeviceData = useCallback(async () => {
    const deviceUniqueKey: string =
      Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5);

    if (token.length === 0) return;
    if (!deviceData.width && !deviceData.type.equals(EDeviceType.COMPUTER)) return alert('가로 길이를 입력하세요.');
    if (!deviceData.height && !deviceData.type.equals(EDeviceType.COMPUTER)) return alert('세로 길이를 입력하세요.');
    if (!deviceData.name) return alert('이름을 입력하세요.');
    if (!deviceData.type) return alert('장비 유형을 선택하세요');
    if (!deviceData.displayType && !deviceData.type.equals(EDeviceType.COMPUTER)) return alert('방향을 선택하세요');
    if (!deviceData.location) return alert('위치를 입력하세요');
    if (deviceData.ip.length === 0) return alert('ip 정보를 입력하세요.');
    if (deviceData.isPowerManageable === undefined) return alert('전원관리여부를 선택하세요.');
    if (deviceData.isPowerManageable && deviceData.macAddress?.length === 0) return alert('mac 정보를 입력하세요.');

    if (!(await onClickIpDuplicateInspection())) return;
    if (deviceData.isPowerManageable && !(await onClickMacDuplicateInspection())) return;

    const { config } = await requestSecurePost(
      apiRoute.devices.post,
      {},
      {
        ...convertDeviceTypeToResponseType(deviceData),
        uniqueKey: deviceUniqueKey,
      },
      token,
    );

    if (config.status === 200) {
      setModal(null);

      reload();
    } else {
      alert(config.message);
    }
  }, [token, deviceData]);

  // 장비 업데이트
  const onClickUpdateDeviceData = useCallback(async () => {
    if (token.length === 0) return;
    if (!deviceData) return;
    if (!deviceData.width && !deviceData.type.equals(EDeviceType.COMPUTER)) return alert('가로 길이를 입력하세요.');
    if (!deviceData.height && !deviceData.type.equals(EDeviceType.COMPUTER)) return alert('세로 길이를 입력하세요.');
    if (!deviceData.name) return alert('장비 이름을 입력하세요.');
    if (!deviceData.type) return alert('장비 유형을 선택하세요');
    if (!deviceData.displayType && !deviceData.type.equals(EDeviceType.COMPUTER)) return alert('방향을 선택하세요');
    if (deviceData.ip.length === 0) return alert('ip 정보를 입력하세요.');
    if (deviceData.isPowerManageable === undefined) return alert('전원관리여부를 선택하세요.');
    if (deviceData.isPowerManageable && deviceData.macAddress?.length === 0) return alert('mac 정보를 입력하세요.');

    if (originalIP !== deviceData.ip && !(await onClickIpDuplicateInspection())) return;
    if (originalMAC !== deviceData.macAddress && !(await onClickMacDuplicateInspection())) return;

    const { config } = await requestSecurePatch<DeviceTypes>(
      apiRoute.devices.update + deviceData.id,
      {},
      convertDeviceTypeToResponseType(deviceData),
      token,
    );

    if (config.status === 200) {
      setModal(null);

      reload();
    } else {
      alert('입력한 데이터가 잘못되었습니다.');
    }
  }, [token, deviceData, setModal, reload]);

  // 장비 삭제
  const onClickDeleteDeviceDatas = useCallback(() => {
    if (token.length === 0) return;

    if (!devices) return;

    // 선택한 장비 수만큼 forEach로 반복하여 장비 삭제 ( 선택한 장비 수만큼 api 호출이 늘어남 )
    devices.forEach(async (device) => {
      const { config } = await requestSecureDelete(apiRoute.devices.delete + device.id, {}, {}, token);

      if (config.status === 200) {
        setModal(null);

        reload();
      }
    });
  }, [token, devices, setModal, reload]);

  // 조건에 맞는 함수 실행
  const onClickBtn = useCallback(() => {
    if (id === 'add') onClickAddDeviceData();
    if (id === 'update') onClickUpdateDeviceData();
    if (id === 'delete') onClickDeleteDeviceDatas();
  }, [id, onClickAddDeviceData, onClickUpdateDeviceData, onClickDeleteDeviceDatas]);

  // Mac 중복 체크
  const onClickMacDuplicateInspection = useCallback(async () => {
    if (token.length === 0) return false;

    const macPattern: RegExp = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;

    if (!macPattern.test(deviceData.macAddress ?? '')) {
      alert('Mac 주소 형식이 잘못되었습니다 \n 영어가 대문자가 아니거나 구분자 \':\' 가 올바르게 들어갔는지 확인하세요');
      return false;
    }

    const { config, data } = await requestSecureGet<ApiDataResponseType>(
      apiRoute.devices.checkMacGet + deviceData?.macAddress,
      {},
      token,
    );

    if (config.status === 200) {
      return true;
    }

    alert('중복된 Mac 정보가 존재합니다.');
    return false;
  }, [token, deviceData]);

  // IP 중복 체크
  const onClickIpDuplicateInspection = useCallback(async () => {
    if (token.length === 0) return false;

    const ipv4Pattern: RegExp =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (!ipv4Pattern.test(deviceData.ip ?? '')) {
      alert('IP 주소 형식이 잘못되었습니다');
      return false;
    }

    const { config, data } = await requestSecureGet<ApiDataResponseType>(
      apiRoute.devices.checkIpGet + deviceData?.ip,
      {},
      token,
    );

    if (config.status === 200) {
      return true;
    }

    alert('중복된 IP 정보가 존재합니다.');
    return false;
  }, [token, deviceData]);

  useEffect(() => {
    if (id === 'update' && devices) {
      setDeviceData(devices[0]);
      setOriginalIP(devices[0].ip);
      setOriginalMAC(devices[0].macAddress);
    }
  }, [id, devices]);

  return (
    <DeviceManage
      id={id}
      type={type}
      deviceData={deviceData}
      setDeviceData={setDeviceData}
      onClickBtn={onClickBtn}
      onClickMacDuplicateInspection={onClickMacDuplicateInspection}
      onClickIpDuplicateInspection={onClickIpDuplicateInspection}
    />
  );
};

export default DeviceManageContainer;
