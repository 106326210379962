import { SelectBoardPostType, WidgetTypes } from '@typedef/Widget/widget.types';
import React, { useCallback } from 'react';
import CustomShadow from '@components/Widgets/components/CustomShadow';
import CustomTemplate from '@components/Widgets/components/CustomTemplate';
import CustomBackgroundImage from '@components/Widgets/components/CustomBackgroundImage';

type Props = {
  setSelectPost: React.Dispatch<React.SetStateAction<SelectBoardPostType | null>>;
  selectPost: SelectBoardPostType | null;
  selectLayout: WidgetTypes | null;
  handleOnChange: (id: string, value: any) => void;
  activeSlide: number;
  onCreateFileSmartBoard: (file: File) => Promise<string | undefined>;
}

const CustomPostContainer = ({
  selectPost,
  handleOnChange,
  setSelectPost,
  selectLayout,
  activeSlide,
  onCreateFileSmartBoard,
}: Props) => {
  const handlePostChange = useCallback((id: string, value: any) => {
    if (selectPost && selectLayout) {
      const { row, column } = selectPost.index;
      const targetLayout = activeSlide === 0 ? selectLayout : selectLayout.boardSlides?.[activeSlide - 1];

      if (targetLayout && targetLayout.boardFiles) {
        const newBoardFiles = targetLayout.boardFiles.map((rowArray, rowIndex) => {
          if (rowIndex === row) {
            return rowArray.map((item, colIndex) => {
              if (colIndex === column) {
                return { ...item, [id]: value };
              }
              return item;
            });
          }
          return rowArray;
        });

        setSelectPost({ ...selectPost, [id]: value });

        if (activeSlide === 0) {
          handleOnChange('boardFiles', newBoardFiles);
        } else {
          const newBoardSlides = selectLayout.boardSlides?.map((slide, index) => {
            if (index === activeSlide - 1) {
              return { ...slide, boardFiles: newBoardFiles };
            }
            return slide;
          });
          handleOnChange('boardSlides', newBoardSlides);
        }
      }
    }
  }, [activeSlide, handleOnChange, selectLayout, selectPost, setSelectPost]);

  return (
    <>
      <CustomShadow handleOnChange={handlePostChange} selectPost={selectPost} />
      <CustomBackgroundImage
        handleOnChange={handlePostChange}
        selectPost={selectPost}
        onCreateFileSmartBoard={onCreateFileSmartBoard}
      />
      <CustomTemplate handleOnChange={handlePostChange} selectPost={selectPost} />
    </>
  );
};

export default CustomPostContainer;