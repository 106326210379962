import { CompanyTypes, CompanyTypesDefault } from '@typedef/Company/company.types';
import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import { EAccountRole } from './account-role.enum';

export type AccountTypes = {
  id: string;
  userId: string;
  userName: string;
  role: EAccountRole;
  company: CompanyTypes;
  loginType: string;
  accessToken: string;
  createdAt: string;
  updatedAt: string;
  password?: string;
};

export type UpdatePasswordTypes = {
  currentPassword: string;
  newPassword: string;
}

export const AccountTypesDefault: AccountTypes = {
  id: '',
  role: EAccountRole.NONE,
  company: CompanyTypesDefault,
  loginType: 'NONE',
  createdAt: '',
  accessToken: '',
  updatedAt: '',
  userId: '',
  userName: '',
};

export type AccountResponseTypes = Omit<AccountTypes, 'role'> & { role: string };

export const accountSearchOptions: SearchTableType<AccountTypes & { companyName: string }>[] = [
  {
    value: 'userId',
    name: '아이디',
    type: 'string',
  },
  {
    value: 'userName',
    name: '사용자명',
    type: 'string',
  },
  {
    value: 'role',
    name: '권한',
    type: 'dropdown',
    dropdownValues: EAccountRole.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
  {
    value: 'companyName',
    name: '회사 이름',
    type: 'string',
  },
];
