import * as S from '../styles/WidgetManageStyle';
import { ToggleSwitch } from '@components/Profile/styles/ProfileStyle';
import React from 'react';
import { Info } from '@assets/icons';
import { StyledTooltip } from '@components/Main/styles/MainStyle';
import { ContentTypes } from '@typedef/Contents/contents.types';

type Props = {
  onClickSubmit: () => void;
  type: 'add' | 'delete';
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputs: ContentTypes;
};

const WidgetManage = ({ onClickSubmit, type, handleInput, inputs }: Props) => {
  if (type === 'add') {
    return (
      <S.Container className="modalBody">
        <S.Inputs>
          <S.Input>
            <p>이름</p>
            <input placeholder="이름" onChange={handleInput} name="name" />
          </S.Input>
          <S.Input>
            <p>가로</p>
            <input placeholder="1920" onChange={handleInput} name="w" />
          </S.Input>
          <S.Input>
            <p>세로</p>
            <input placeholder="1080" onChange={handleInput} name="h" />
          </S.Input>

          <S.Input>
            <p>
              오프라인 모드
              <StyledTooltip
                title="오프라인 모드: 초기 데이터 로딩 이후 네트워크 없이 사용 가능, 실시간 업데이트 없음."
                placement={'bottom'}>
                <Info />
              </StyledTooltip>
            </p>
            <div className={'toggle-container'}>
              <ToggleSwitch
                checkedColor={'#15B9F5'}
                checkedBorderColor={'#1490bc'}
              >
                <input
                  type="checkbox"
                  name={'offlineAvailable'}
                  onChange={handleInput}
                />
                <div className="toggle-switch-background">
                  <div className="toggle-switch-handle" />
                </div>
              </ToggleSwitch>
              {inputs.offlineAvailable ? '사용' : '사용 안함'}
            </div>
          </S.Input>
        </S.Inputs>
        <article className="bottoms">
          <button onClick={onClickSubmit}>생성하기</button>
        </article>
      </S.Container>
    );
  }
  if (type === 'delete') {
    return (
      <S.Container className="modalBody">
        <S.Delete>
          <p>정말로 컨텐츠를 삭제하시겠습니까? </p>
          <p>삭제 후 복구하실수 없습니다.</p>
        </S.Delete>
        <article className="bottoms">
          <button onClick={onClickSubmit} className="danger">
            삭제하기
          </button>
        </article>
      </S.Container>
    );
  }

  return <></>;
};

export default WidgetManage;
