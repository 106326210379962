import { WidgetTypes } from '@typedef/Widget/widget.types';
import { EFileType } from '@typedef/Widget/file-type.enum';
import { MINIO_URL } from '@libs/remote';
import { EFileStreamingType } from '@typedef/Widget/file-streaming-type.enum';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import { CustomSlider } from '@components/Widgets/styles/WidgetStyle';
import { useEffect, useRef, useState } from 'react';

type props = {
  widget: WidgetTypes;
};

const FileWidget = ({ widget }: props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: Number(widget.autoPlaySpeed ?? 0) * 1000,
    nextArrow: <></>,
    prevArrow: <></>,
    pauseOnHover: false,
    swipe: false,
  };

  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);

  const videos = [
    `${MINIO_URL}/${widget.file}`,
    ...widget.files?.map(filename => `${MINIO_URL}/${filename}`) ?? [],
  ];

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const handleVideoEnded = () => {
      setCurrentVideoIndex((prevIndex) =>
        (prevIndex + 1) % videos.length,
      );
    };

    videoElement.addEventListener('ended', handleVideoEnded);

    return () => {
      videoElement.removeEventListener('ended', handleVideoEnded);
    };
  }, [videos.length]);

  return (
    <>
      {EWidgetType.FILE.equals(widget.type) &&
        EFileType.IMAGE.validate(widget.file!) &&
        !widget.files?.length &&
        (EFileStreamingType.STREAMING.equals(widget.fileStreamingType!) ? (
          <img src={`${MINIO_URL}/${widget.file}`} alt={widget.i} />
        ) : (
          <img src={widget.fileData!} alt={widget.i} />
        ))}

      {EWidgetType.FILE.equals(widget.type) &&
        EFileType.VIDEO.validate(widget.file!) &&
        (EFileStreamingType.STREAMING.equals(widget.fileStreamingType!) ? (
          <video ref={videoRef} src={videos[currentVideoIndex]} autoPlay={true} muted={true}
                 loop={!widget.files?.length} />
        ) : (
          <video src={widget.fileData!} autoPlay={true} muted={true} loop={true} />
        ))}

      {EWidgetType.FILE_LIST.equals(widget.type) && EFileType.SLIDEIMAGE.validate(widget.file!) ? (
        EFileStreamingType.STREAMING.equals(widget.fileStreamingType!) ? (
          <CustomSlider {...settings}>
            <img src={`${MINIO_URL}/${widget.file}`} alt={widget.i} />
            {widget.files?.map((value: any, index: number) => (
              <img src={`${MINIO_URL}/${value}`} key={index} alt={widget.i} />
            ))}
          </CustomSlider>
        ) : (
          <img src={widget.fileData!} alt={widget.i} />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default FileWidget;
