import { RESOLUTION_LIST } from '@components/Widgets/components/CustomResolution';
import { ResolutionType } from '@typedef/Widget/widget.types';

export const getResolutionValue = (resolution: number | undefined): ResolutionType => {
  if (!resolution || isNaN(resolution)) {
    return 'custom';
  }
  
  const matchingOption = RESOLUTION_LIST.find((option) => parseFloat(option.value) === resolution);
  return matchingOption ? (String(matchingOption.label) as ResolutionType) : '16:9';
};
