import { AccountTypes } from '@typedef/Account/account.types';
import React, { FormEvent } from 'react';
import './styles/Login.scss';
import { CompanyRequestType, CompanyTypes } from '@typedef/Company/company.types';
import Logo from '@assets/images/logo.png';
import { Cancel } from '@assets/icons';

type Props = {
  account: AccountTypes;
  setAccount: React.Dispatch<React.SetStateAction<AccountTypes>>;
  signUpStatus: boolean;
  setSignUpStatus: React.Dispatch<React.SetStateAction<boolean>>;
  signIn: () => void;
  handleSignUp: () => void;
  accountIdDuplicate: () => void;
  companyCheck: () => void;
  setIdDuplicateFlag: React.Dispatch<React.SetStateAction<boolean>>;
  customCompany: CompanyRequestType;
  setCustomCompany: React.Dispatch<React.SetStateAction<CompanyRequestType>>;
  isNewCompany: boolean;
  setIsNewCompany: React.Dispatch<React.SetStateAction<boolean>>;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  searchCompany: (e: FormEvent) => void;
  selectCompany: CompanyTypes | null;
  setSelectCompany: React.Dispatch<React.SetStateAction<CompanyTypes | null>>;
  handleCustomCompany: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Login = ({
  account,
  setAccount,
  signUpStatus,
  setSignUpStatus,
  signIn,
  handleSignUp,
  accountIdDuplicate,
  setIdDuplicateFlag,
  customCompany,
  setCustomCompany,
  isNewCompany,
  setIsNewCompany,
  setCompanyName,
  companyName,
  searchCompany,
  selectCompany,
  setSelectCompany,
  handleCustomCompany,
}: Props) => {
  return (
    <main className="loginContainer">
      <img src={Logo} alt={'logo'} />
      <header>{signUpStatus ? '회원가입' : '로그인'}</header>
      {signUpStatus ? (
        // 회원가입
        <section className="joinWrapper">
          <article className="inputWrapper">
            {
              !isNewCompany &&
              <form onSubmit={searchCompany}>
                <p>
                  사명<span>*</span>
                </p>
                {selectCompany ?
                  <div className={'selected-company'}>
                    {selectCompany.name}
                    <Cancel onClick={() => setSelectCompany(null)} />
                  </div> :
                  <div className={'inputs'}>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      autoFocus
                      placeholder="사명"
                    />
                    <button type={'submit'}>검색</button>
                  </div>
                }
              </form>
            }
            <div className={'checkbox-container'}>
              <input type={'checkbox'} checked={isNewCompany} onChange={e => setIsNewCompany(e.target.checked)} />
              회사 신규 등록
            </div>
          </article>
          {isNewCompany && (
            <>
              <article className="inputWrapper">
                <p>
                  사명<span>*</span>
                </p>
                <input
                  type="text"
                  value={customCompany.name}
                  name={'name'}
                  onChange={handleCustomCompany}
                  autoFocus
                  placeholder="사명"
                />
              </article>

              <article className="inputWrapper">
                <p>
                  사업자 등록 번호<span>*</span>
                </p>
                <input
                  type="text"
                  value={customCompany.number}
                  name={'number'}
                  onChange={handleCustomCompany}
                  autoFocus
                  placeholder="사업자 등록 번호"
                  maxLength={12}
                />
              </article>
            </>
          )}
          <article className="inputWrapper">
            <p>
              아이디<span>*</span>
            </p>
            <div className="inputs">
              <input
                type="text"
                value={account.userId}
                onChange={(e) => {
                  setIdDuplicateFlag(false);
                  setAccount({ ...account, userId: e.target.value });
                }}
                autoFocus={!signUpStatus}
                placeholder="아이디"
              />
              <button onClick={accountIdDuplicate}>중복확인</button>
            </div>
          </article>
          <article className="inputWrapper">
            <p>
              비밀번호<span>*</span>
            </p>
            <input
              type="password"
              value={account.password}
              onChange={(e) => {
                setAccount({ ...account, password: e.target.value });
              }}
              placeholder="비밀번호"
            />
          </article>
          <article className="inputWrapper">
            <p>
              사용자명<span>*</span>
            </p>
            <input
              type="text"
              value={account.userName}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSignUp();
                }
              }}
              onChange={(e) => {
                setAccount({ ...account, userName: e.target.value });
              }}
              placeholder="사용자명"
            />
          </article>
        </section>
      ) : (
        // 로그인
        <section className="loginWrapper">
          <input
            type="text"
            value={account.userId}
            onChange={(e) => {
              setAccount({ ...account, userId: e.target.value });
            }}
            autoFocus
            placeholder="아이디"
          />
          <input
            type="password"
            value={account.password}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                signIn();
              }
            }}
            onChange={(e) => {
              setAccount({ ...account, password: e.target.value });
            }}
            placeholder="비밀번호"
          />
        </section>
      )}
      {signUpStatus ? (
        <section className="btns">
          <button
            onClick={handleSignUp}
            className="brand">
            회원가입
          </button>
          <button
            onClick={() => {
              setSignUpStatus(false);
            }}
          >
            로그인하기
          </button>
        </section>
      ) : (
        <section className="btns">
          <button onClick={signIn} className="brand">
            로그인
          </button>
          <button
            onClick={() => {
              setSignUpStatus(true);
            }}
          >
            회원가입하기
          </button>
        </section>
      )}
    </main>
  );
};

export default Login;
