import styled from 'styled-components';

const space = '15px';

export const Container = styled.main`
  header {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(${space} * 2);

    .left {
      ${({ theme }) => theme.flex.row}
      align-items: center;
    }

    .title {
      ${({ theme }) => theme.flex.row}
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;

      &:after {
        content: ' ';
        width: 3px;
        height: 1rem;
        display: block;
        margin: 0 15px;
        background-color: #191919;
      }
    }
  }
`;

export const ContentContainer = styled.section<{
  padding?: string;
  gap?: string;
}>`
  width: 100%;
  height: 75vh;
  
  padding: ${({ padding }) => padding ?? '40px'};

  border-radius: 7px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ gap }) => gap ?? '43px'};

  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
`;

export const TitleText = styled.p`
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
`;

export const BodyText = styled.p`
  font-size: 20px;
  font-weight: normal;
  height: 400px;
  width: 100%;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #000000;
`;

export const ButtonGrid = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const Button = styled.button<{ height?: number; fontSize?: number }>`
  width: 100%;
  height: ${({ height }) => height ?? 300}px;
  
  border: 0;
  border-radius: 10px;

  font-size: ${({ fontSize }) => fontSize ?? 120}px;
  
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #256662;
  color: #fff;

  svg {
    width: 120px;
    height: 120px;

    margin-right: 30px;

    stroke: #fff;
  }
`;

export const InputContainer = styled.div`
  color: #000000;
  font-size: 22px;

  input {
    width: 100%;
    height: 40px;

    margin-top: 10px;

    padding: 10px 20px;

    border: 1px solid #000000;
    border-radius: 3px;

    font-size: 22px;

    &:focus {
      outline: none;
    }
  }

  .check-container {
    width: 100%;
    
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 22px;

    button {
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .dropdown {
    width: 100%;
    height: 40px;

    margin-top: 10px;

    padding: 10px 20px;

    border: 1px solid #000000;
    border-radius: 3px;

    display: flex;
    align-items: center;
  
    color: #000000;
  }

  .dropdown-placeholder {
    font-size: 22px;
    flex: 1;
  }

  .dropdown-arrow {
    position: relative;
    right: 0;
    top: 0;
    border-color: #000000 transparent transparent;
  }

  .dropdown-menu {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const CheckButton = styled.button<{ checked?: boolean }>`
  margin-top: 10px;
  width: 100%;
  border: 1px solid #000000;
  border-radius: 3px;
  font-size: 22px;
  padding: 10px 20px;
  background-color: ${({ checked }) => (checked ? '#256662' : '#ffffff')};
  color: ${({ checked }) => (checked ? '#FFF' : '#000')};

  &:focus {
    outline: none;
  }
`;

export const Grid = styled.div<{ column?: number }>`
  width: 100%;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(${({ column }) => column ?? 3}, 1fr);
  gap: 28px;
`;

export const EmptyContainer = styled.div`
  height: 85px;
`;

export const Spacer = styled.div`
  flex: 1;
`;
