import styled, { css } from 'styled-components';
import { IframeStyleProps, WidgetTypes } from '@typedef/Widget/widget.types';
import { ContentTypes } from '@typedef/Contents/contents.types';
import { getFontSize } from '@libs/getFontStyle';

type Props = {
  widget: WidgetTypes;
  content: ContentTypes;
}

const Iframe = ({ widget, content }: Props) => {
  return (
    widget?.webUrl ?
      <StyledIframe src={widget?.webUrl} /> :
      <WebContentsDefault
        width={widget.w}
        height={widget.h}
        contentWidth={content.w}
        contentHeight={content.h}
      >
        표출 할 웹 컨텐츠의 주소를 입력해 주세요.
      </WebContentsDefault>
  );
};

export default Iframe;

const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
`;

const WebContentsDefault = styled.div<IframeStyleProps>`
    width: 100%;
    height: 100%;
    ${({ width, height, contentWidth, contentHeight }) => css`
        font-size: ${`
    calc(1 * ${getFontSize(
                width,
                height,
                {
                    w: contentWidth,
                    h: contentHeight,
                },
                true,
        )})`};
    `};
    display: flex;
    align-items: center;
    justify-content: center;
`;