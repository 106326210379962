import styled from 'styled-components';

type Props = {
  templateColor?: string;
};

const Pin = ({ templateColor = '#DE1919' }: Props) => {
  const adjustColor = (amount: number): string => {
    // Hex 코드 -> RGB
    const hexToRgb = (hex: string) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return { r, g, b };
    };

    // RGB 색상값을 Hex 코드로 변환
    const rgbToHex = (r: number, g: number, b: number) => {
      return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
    };

    // 색상값을 RGB로 변환
    const { r, g, b } = hexToRgb(templateColor);

    // 각 채널을 조정
    const adjustChannel = (value: number, amount: number) => Math.min(Math.max(0, value + amount), 255);

    // 새로운 색상값 계산
    const newR = adjustChannel(r, amount);
    const newG = adjustChannel(g, amount);
    const newB = adjustChannel(b, amount);

    // 새로운 색상값을 Hex 코드로 변환
    return rgbToHex(newR, newG, newB);
  };

  const generateColors = () => {
    const darkerColor = adjustColor(-30); // 어두운 색상
    const shadowColor = adjustColor(-60); // 더 어두운 색상

    return { darkerColor, shadowColor };
  };

  const { darkerColor, shadowColor } = generateColors();

  return (
    <Container>
      <svg width="50" height="60" viewBox="0 0 50 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_di_48_403)">
          <circle cx="24.5777" cy="25.4224" r="17.5777" fill={darkerColor} />
        </g>
        <g filter="url(#filter1_i_48_403)">
          <circle cx="24.5777" cy="25.4224" r="16.4155" fill={darkerColor} />
        </g>
        <g filter="url(#filter2_di_48_403)">
          <path
            d="M40.4496 14.3736C40.6287 18.9479 30.7444 32.4899 26.2839 32.6645C21.8235 32.839 18.0624 29.2723 17.8834 24.698C17.7044 20.1237 30.4138 9.53261 34.8743 9.35806C39.3347 9.1835 40.2706 9.7993 40.4496 14.3736Z"
            fill={shadowColor}
          />
        </g>
        <g filter="url(#filter3_i_48_403)">
          <circle cx="35.3277" cy="12.6385" r="12.6385" fill={templateColor} />
        </g>
        <g filter="url(#filter4_i_48_403)">
          <circle cx="35.3277" cy="12.6385" r="11.4764" fill={templateColor} />
        </g>
        <defs>
          <filter
            id="filter0_di_48_403"
            x="0"
            y="7.84473"
            width="49.1554"
            height="52.1553"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="3.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_48_403" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_48_403" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_innerShadow_48_403" />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_48_403" />
          </filter>
          <filter
            id="filter1_i_48_403"
            x="8.16217"
            y="9.00684"
            width="32.8311"
            height="32.8311"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology radius="11" operator="dilate" in="SourceAlpha" result="effect1_innerShadow_48_403" />
            <feOffset dy="17" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_48_403" />
          </filter>
          <filter
            id="filter2_di_48_403"
            x="13.8815"
            y="5.33252"
            width="30.5705"
            height="31.3379"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_48_403" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_48_403" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_48_403" />
          </filter>
          <filter
            id="filter3_i_48_403"
            x="22.6891"
            y="0"
            width="25.277"
            height="25.2769"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_innerShadow_48_403" />
            <feOffset />
            <feGaussianBlur stdDeviation="3.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_48_403" />
          </filter>
          <filter
            id="filter4_i_48_403"
            x="23.8513"
            y="1.16211"
            width="22.9527"
            height="22.9526"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology radius="11" operator="dilate" in="SourceAlpha" result="effect1_innerShadow_48_403" />
            <feOffset dy="17" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_48_403" />
          </filter>
        </defs>
      </svg>
    </Container>
  );
};

export default Pin;

const Container = styled.div`
    width: 10%;
    height: 12.5%;

    /* width: fit-content;
    height: fit-content; */

    display: flex;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    & > svg {
        width: 100%;
        height: 100%;
    }
`;
