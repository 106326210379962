import { DeviceScheduleMapTypes } from '@typedef/Device/device.types';
import { atom, selector } from 'recoil';

const deviceScheduleMapAtom = atom<DeviceScheduleMapTypes>({
  key: 'boardScheduleListAtom',
  default: new Map(),
});

export const deviceScheduleMapSelector = selector<DeviceScheduleMapTypes>({
  key: 'deviceScheduleListSelector',
  get: ({ get }) => {
    return get(deviceScheduleMapAtom);
  },
  set: ({ set }, value) => {
    set(deviceScheduleMapAtom, value);
  },
});
