import React from 'react';
import * as S from './styles/VisitStyle';

type Props = {
  onHomeButtonClicked: () => void;
};

const Visit = ({ onHomeButtonClicked }: Props) => {
  return (
    <S.ContentContainer>
      <S.Spacer />
      <S.TitleText>등록되었습니다.</S.TitleText>
      <S.Spacer />
      <S.ButtonGrid>
        <S.Button height={130} fontSize={50} onClick={onHomeButtonClicked}>
          확인
        </S.Button>
      </S.ButtonGrid>
    </S.ContentContainer>
  );
};

export default Visit;
