import React, { useCallback } from 'react';
import { BoardPostType } from '@typedef/Widget/widget.types';
import { MINIO_URL } from '@libs/remote';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectPost: BoardPostType | null;
  onCreateFileSmartBoard: (file: File) => Promise<string | undefined>;
};

const CustomBackgroundImage = ({ handleOnChange, selectPost, onCreateFileSmartBoard }: Props) => {
  const handleFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const fileName = await onCreateFileSmartBoard(e.target.files[0]);
        if (fileName) {
          handleOnChange('backgroundImage', fileName);
        }
      } catch (error) {
        console.error('파일 업로드 에러', error);
      }
    }
  }, [onCreateFileSmartBoard, handleOnChange]);


  return (
    <div className="custom">
      <div className="background-image">
        <p className="title">
          배경 이미지 설정
          {selectPost?.backgroundImage && <img src={`${MINIO_URL}/${selectPost.backgroundImage}`} alt={'Image'} />}
        </p>
        <div className={'post'}>
          <input type="file" id={'post-file'} onChange={handleFileChange} />
          <label htmlFor={'post-file'} className={'post'}>
            첨부
          </label>
          <label className={'delete'} onClick={() => handleOnChange('backgroundImage', null)}>삭제</label>
        </div>
      </div>
    </div>
  );
};

export default CustomBackgroundImage;
