import './styles/RoutesNavigation.scss';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { accountSelector } from '@stories/account';
import { useRecoilValue } from 'recoil';

import MonitorContainer from '@components/Monitor/containers/MonitorContainer';
import PreviewContentContainer from '@components/PreviewContent/containers/PreviewContentContainer';
import MainContainer from '@components/Main/containers/MainContainer';
import ScheduleContainer from '@components/Schedule/containers/ScheduleContainer';
import DevicesContainer from '@components/Devices/containers/DevicesContainer';
import ContentSettingContainer from '@components/ContentSetting/containers/ContentSettingContainer';
import VisitContainer from '@components/Visit/containers/VisitContainer';
import AccountContainer from '@components/Account/containers/AccountContainer';
import CompanyContainer from '@components/Company/containers/CompanyContainer';
import ProfileContainer from '@components/Profile/containers/ProfileContainer';
import LoginContainer from '@components/Login/containers/LoginContainer';
import GNB from '@components/Common/GNB/GNB';
import ModalContainer from '@components/Common/Modal/containers/ModalContainer';
import MonitoringContainer from '@components/Monitoring/containers/MonitoringContainer';
import RealCityContainer from '@components/Monitoring/containers/RealCityContainer';
import MetaCityContainer from '@components/Monitoring/containers/MetaCityContainer';
import UserStatistics from '@components/Monitoring/UserStatistics';

import Guide from '@components/Guide/Guide';
import RealCityVisitorListContainer from '@components/Monitoring/containers/RealCityVisitorListContainer';
import MetaCityListContainer from '@components/Monitoring/containers/MetaCityListContainer';
import ContentPlaybackListContainer from '@components/Monitoring/containers/ContentPlaybackListContainer';
import ContentPlaybackStatisticsContainer from '@components/Monitoring/containers/ContentPlaybackStatisticsContainer';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { ECompanyType } from '@typedef/Company/company-type.enum';
import List from '@components/Monitoring/List';
import ManageOrderContainer from '@components/CafeOrderList/containers/ManageOrderContainer';

const RoutesNavigation = () => {
  const account = useRecoilValue(accountSelector);

  const router = createBrowserRouter(
    account.accessToken.length
      ? createRoutesFromElements(
        <Route>
          <Route path="/" element={<GNB />}>
            {ECompanyType.YOUTH_WORKSHOP.equals(account.company.type) ||
            ECompanyType.MAKER_SPACE.equals(account.company.type) ? (
              <Route path="/" index element={<MonitoringContainer />} />
            ) : (
              <Route path="/" index element={<MainContainer />} />
            )}
            <Route path="/main" element={<MainContainer />} />
            <Route path="/schedules" element={<ScheduleContainer />} />
            <Route path="/devices" element={<DevicesContainer />} />
            <Route path="/content-setting" element={<ContentSettingContainer />} />
            {(EAccountRole.MASTER.equals(account.role) || EAccountRole.MANAGER.equals(account.role)) &&
              (ECompanyType.YOUTH_WORKSHOP.equals(account.company.type) ||
                ECompanyType.MAKER_SPACE.equals(account.company.type)) && (
                <>
                  <Route path="/visit" element={<VisitContainer />} />
                  <Route path="/monitoring" element={<MonitoringContainer />} />
                  <Route path="/monitoring/real-city" element={<RealCityContainer />} />
                  <Route path="/monitoring/real-city/list" element={<RealCityVisitorListContainer />} />
                  <Route path="/monitoring/meta-city" element={<MetaCityContainer />} />
                  <Route path="/monitoring/meta-city/list" element={<MetaCityListContainer />} />
                  <Route path="/monitoring/user-statistics" element={<UserStatistics />} />
                  <Route path="/monitoring/list/:division" element={<List />} />
                  <Route
                    path="/monitoring/content-playback-statistics"
                    element={<ContentPlaybackStatisticsContainer />}
                  />
                  <Route
                    path="/monitoring/content-playback-statistics/list"
                    element={<ContentPlaybackListContainer />}
                  />
                </>
              )}
            {(EAccountRole.MASTER.equals(account.role) || EAccountRole.MANAGER.equals(account.role)) && (
              <Route path="/visit" element={<VisitContainer />} />
            )}
            {(EAccountRole.ROOT.equals(account.role) || EAccountRole.MASTER.equals(account.role) || EAccountRole.MANAGER.equals(account.role)) && (
              <Route path="accounts" element={<AccountContainer />} />
            )}
            {EAccountRole.ROOT.equals(account.role) && <Route path="/company" element={<CompanyContainer />} />}
            {account.company.isCafe && <Route path="/manage-order" element={<ManageOrderContainer />} />}
            <Route path="profile" element={<ProfileContainer />} />
          </Route>
          <Route path="/monitor/:uniqueKey" element={<MonitorContainer />} />
          <Route path="/board-monitor/:uniqueKey" element={<MonitorContainer />} />
          <Route path="/preview-content/:contentId" element={<PreviewContentContainer />} />
          <Route path="/guide" element={<Guide />} />
        </Route>,
      )
      : createRoutesFromElements(
        <Route>
          <Route path="/*" element={<LoginContainer />} />
        </Route>,
      ),
  );

  return (
    <>
      <ModalContainer />
      <div className="routeNavigation">
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default RoutesNavigation;
