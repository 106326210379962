import NewsSubContainer from '@components/Common/Sub/containers/NewsSubContainer';
import { apiRoute, requestGet } from '@libs/api';
import { NewsItem, NewsType } from '@typedef/Widget/news.types';
import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import Clock from 'react-live-clock';
import WeatherContainer from '@components/Common/Weather/containers/WeatherContainer';
import { SelectBoardPostType, WidgetTypes } from '@typedef/Widget/widget.types';
import { TimeContainer } from '@components/Widgets/styles/WidgetStyle';
import moment from 'moment';
import 'moment/locale/ko';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import FineDustContainer from '@components/Common/FineDust/containers/FineDustContainer';
import { ClockIcon } from '@assets/icons';
import { getResolutionValue } from '@libs/getResolutionValue';
import { ContentTypes } from '@typedef/Contents/contents.types';
import StockIndexContainer from '@components/Common/StockIndex/containers/StockIndexContainer';
import SmartBoard from '@components/Common/SmartBoard/SmartBoard';
import OrderListContainer from '@components/Common/OrderList/containers/OrderListContainer';
import Slider from 'react-slick';
import Iframe from '@components/Common/Iframe/Iframe';

type props = {
  widget: WidgetTypes;
  isSetting?: boolean;
  deviceId?: string;
  content: ContentTypes;
  handleOnChange?: (id: string, value: any) => void;
  selectPost?: SelectBoardPostType | null;
  setSelectPost?: React.Dispatch<React.SetStateAction<SelectBoardPostType | null>>;
  sliderRef?: MutableRefObject<Slider | null>;
  setActiveSlide?: React.Dispatch<React.SetStateAction<number>>;
  activeSlide?: number;
};

const CustomWidgetContainer = ({
  widget,
  isSetting,
  deviceId,
  content,
  selectPost,
  setSelectPost,
  sliderRef,
  setActiveSlide,
  activeSlide,
}: props) => {
  const [news, setNews] = useState<string>('');
  const [intervalID, setIntervalID] = useState<NodeJS.Timer>();

  const resolutionValue = getResolutionValue(widget.resolution);

  const getNews = useCallback(async () => {
    const { data: responseData, config } = await requestGet<NewsType>(
      apiRoute.news.getNewsDataWithoutJWS + '?newsKeyword=' + widget.newsKeyword,
      {},
    );

    if (config.status === 200) {
      const parsingData = JSON.parse(responseData.news);
      const items: NewsItem[] = parsingData['items'] as NewsItem[];
      let titles: string = '';

      items.forEach((value) => {
        titles += value.title + ' // ';
      });
      const converted = titles
        .replace(/&apos;/g, '\'')
        .replace(/&quot;/g, '"')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/<b>/g, '')
        .replace(/<\/b>/g, '');

      setNews(converted);
    }
  }, []);

  useEffect(() => {
    moment.locale('ko');
    if (widget.type.equals(EWidgetType.NEWS)) {
      getNews();
      setIntervalID(setInterval(getNews, 3600000));
    }

    return () => {
      if (intervalID) clearInterval(intervalID);
    };
  }, [widget.location1, widget.location2, widget.location3]);

  return (
    <>
      {widget.type.equals(EWidgetType.TIME) && (
        <TimeContainer
          width={widget.w}
          height={widget.h}
          isSetting={isSetting}
          resolution={getResolutionValue(widget.resolution)}
          contentWidth={content.w}
          contentHeight={content.h}
        >
          <div>
            <div className={'title'}>
              <div className={'icon-container'}>
                <ClockIcon />
              </div>
              <div className={'date-text'}>
                <div>
                  {resolutionValue === '9:16' ? (
                    <>
                      <div>
                        <Clock format={'YYYY년'} ticking={true} timezone={'Asia/Seoul'} />
                        <div className={'divider'} />
                        <Clock format={'DD일 MM월'} ticking={true} timezone={'Asia/Seoul'} />
                      </div>
                    </>
                  ) : (
                    <Clock format={`YYYY년 MM월 DD일`} ticking={true} timezone={'Asia/Seoul'} />
                  )}
                </div>
                <div className={'ddd'}>
                  <Clock format={'ddd요일'} ticking={true} timezone={'Asia/Seoul'} />
                </div>
              </div>
            </div>
            <div className={'time'}>
              <span>
                <Clock className="ampm" format={'A'} ticking={true} locale="en-us" timezone={'Asia/Seoul'} />{' '}
                <Clock className="hhmm" format={'hh:mm'} ticking={true} locale="en-us" timezone={'Asia/Seoul'} />
              </span>
            </div>
          </div>
        </TimeContainer>
      )}
      {widget.type.equals(EWidgetType.NEWS) && <NewsSubContainer widget={{ ...widget, text: news }} />}
      {widget.type.equals(EWidgetType.WEATHER) && (
        <WeatherContainer
          isSetting={isSetting!}
          size={{ width: widget.w, height: widget.h }}
          widget={widget}
          content={content}
        />
      )}
      {widget.type.equals(EWidgetType.FINEDUST) && (
        <FineDustContainer
          size={{ width: widget.w, height: widget.h }}
          isSetting={isSetting!}
          widget={widget}
          content={content}
        />
      )}
      {widget.type.equals(EWidgetType.STOCK_INDEX) && (
        <StockIndexContainer
          size={{ width: widget.w, height: widget.h }}
          isSetting={isSetting!}
          widget={widget}
          content={content}
        />
      )}
      {widget.type.equals(EWidgetType.SMART_BOARD) &&
        <SmartBoard
          widget={widget}
          content={content}
          selectPost={selectPost!}
          setSelectPost={setSelectPost!}
          isSetting={isSetting}
          sliderRef={sliderRef!}
          activeSlide={activeSlide!}
          setActiveSlide={setActiveSlide!}
        />}
      {widget.type.equals(EWidgetType.IFRAME) && <Iframe widget={widget} content={content} />}
      {widget.type.equals(EWidgetType.ORDER_LIST) && <OrderListContainer isSetting={isSetting} content={content} />}
    </>
  );
};

export default CustomWidgetContainer;
