export type EAccountRoleProps = 'ROOT' | 'MASTER' | 'MANAGER' | 'USER' | 'NONE';

export class EAccountRole {
  static readonly ROOT: EAccountRole = new EAccountRole('ROOT', '루트');
  static readonly MASTER: EAccountRole = new EAccountRole('MASTER', '마스터');
  static readonly MANAGER: EAccountRole = new EAccountRole('MANAGER', '매니저');
  static readonly USER: EAccountRole = new EAccountRole('USER', '유저');
  static readonly NONE: EAccountRole = new EAccountRole('NONE', '권한없음');

  private constructor(
    public readonly value: EAccountRoleProps,
    public readonly name: string,
  ) {
  }

  static readonly values = [
    EAccountRole.ROOT,
    EAccountRole.MASTER,
    EAccountRole.MANAGER,
    EAccountRole.USER,
    EAccountRole.NONE,
  ];

  static valueOf(value: EAccountRoleProps | string): EAccountRole | undefined {
    return EAccountRole.values.find((v) => v.value === value);
  }

  static isValue(value: string): boolean {
    return !!EAccountRole.values.find((v) => v.value === value);
  }

  static accessRole(value: EAccountRole) {
    return [EAccountRole.ROOT, EAccountRole.MASTER, EAccountRole.MANAGER].includes(value);
  }

  equals(value: EAccountRoleProps | EAccountRole) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
