import { useEffect, useCallback } from 'react';
import ContentSetting from '../ContentSetting';
import { useSetRecoilState } from 'recoil';
import { saveStatusSelector } from '@stories/contentSetting';

const ContentSettingContainer = () => {

  const setSaveStatus = useSetRecoilState(saveStatusSelector);  

  // ctrl+s 기존 이벤트 막고 컨텐츠 설정 값 저장키로 할당
  const handleUserKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        setSaveStatus(true);

        document.getElementById('widgetSave')?.click();

        return false;
      }
    },
    [setSaveStatus],
  );

  // 컨텐츠 설정 화면 최초 진입시에 새파일, 파일 불러오기 모달 띄우기
  useEffect(() => {
    document.addEventListener('keydown', handleUserKeyDown);

    return () => {
      document.removeEventListener('keydown', handleUserKeyDown);
    };
  }, [handleUserKeyDown]);


  return (
    <>
      <ContentSetting />
    </>
  );
};

export default ContentSettingContainer;
