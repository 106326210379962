import styled from 'styled-components';

type Props = {
  templateColor?: string;
};

const Tape = ({ templateColor = '#EAEAEA' }: Props) => {
  return (
    <Container>
      <svg width="61" height="32" viewBox="0 0 61 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M58.5339 1.8198C58.3831 2.44156 58.0459 17.4159 58.9424 21.3127C58.8868 22.7407 58.8883 25.7709 59.3396 26.4683C41.9811 28.2124 6.92917 31.4824 5.5897 30.6094C4.25022 29.7364 3.05905 29.5841 2.63089 29.6171L1.95529 26.0083L2.31348 20.3369L0.922884 16.4782C0.805726 14.0975 0.457126 8.36871 -3.0361e-05 4.49905C17.3163 3.92761 53.266 2.59174 58.5339 1.8198Z"
          fill={templateColor}
          fill-opacity="0.8"
        />
      </svg>
    </Container>
  );
};

export default Tape;

const Container = styled.div`
    width: 20%;
    height: auto;

    /* width: fit-content;
    height: fit-content; */

    position: absolute;
    top: -3%;
    /* top: -16px; */
    left: 50%;
    transform: translate(-50%, 0);

    & > svg {
        width: 100%;
        height: 100%;
    }
`;
