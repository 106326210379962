import { Delete } from '@assets/icons';
import { accountSearchOptions, AccountTypes } from '@typedef/Account/account.types';
import * as S from './styles/AccountStyle';
import React from 'react';
import SearchTableContainer from '@components/Common/SearchTable/containers/SearchTableContainer';
import { PageTypes } from '@typedef/libs/pagination.types';
import Pagination from '@components/Common/Pagination/Pagination';

type Props = {
  profileData: AccountTypes;
  onClickModalOpen: (id: string, selectAccount?: AccountTypes) => void;
  accountList: AccountTypes[];
  checkedList: AccountTypes[];
  setCheckedList: React.Dispatch<React.SetStateAction<AccountTypes[]>>;
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  onSearchSummit: () => void;
};

const Account = ({
  profileData,
  onClickModalOpen,
  accountList,
  checkedList,
  setCheckedList,
  currentPage,
  setCurrentPage,
  pageInfo,
  onPageChange,
  onSearchSummit,
}: Props) => {
  return (
    <S.Container>
      <S.Header>
        <S.Button
          backgroundColor="#f0614f"
          onClick={() => {
            onClickModalOpen('delete');
          }}
        >
          <Delete title="delete" />
          <p>삭제</p>
        </S.Button>
        <SearchTableContainer searchOptions={accountSearchOptions} onSummit={onSearchSummit} />
      </S.Header>
      <S.Article>
        <div className="background">
          <div className="tableHeader">
            <table>
              <colgroup>
                <col width="5%" />
                <col width="15%" />
                <col width="15%" />
                <col width="45%" />
                <col width="20%" />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          const clone: AccountTypes[] = [];

                          accountList.forEach((account) => {
                            if (account.userId === profileData.userId) return;

                            clone.push(account);
                          });

                          setCheckedList(clone);
                        } else {
                          setCheckedList([]);
                        }
                      }}
                    />
                  </th>
                  <th>아이디</th>
                  <th>사용자명</th>
                  <th>회사명</th>
                  <th>권한</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tableContent">
            <table>
              <colgroup>
                <col width="5%" />
                <col width="15%" />
                <col width="15%" />
                <col width="45%" />
                <col width="20%" />
              </colgroup>
              <tbody>
                {accountList.map((account) => (
                  <S.Tr
                    id={account.userId.toString()}
                    checked={checkedList.filter((item) => item.userId === account.userId).length > 0}
                    key={account.userId}
                    onClick={() => {
                      onClickModalOpen('update', account);
                    }}
                  >
                    <td
                      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      <input
                        type="checkbox"
                        id={account.userId ? account.userId.toString() : '0'}
                        checked={checkedList.filter((item) => item.userId === account.userId).length > 0}
                        onChange={(e) => {
                          e.stopPropagation();
                          if (e.target.checked) {
                            setCheckedList((prev) => {
                              const clone = [...prev];

                              clone.push(account);

                              return clone;
                            });
                          } else {
                            setCheckedList(checkedList.filter((check) => check.userId !== account.userId));
                          }
                        }}
                      />
                    </td>
                    <td>{account.userId}</td>
                    <td>{account.userName}</td>
                    <td>{account.company.name}</td>
                    <td>{account.role.name}</td>
                  </S.Tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          onPageChange={onPageChange}
          pageInfo={pageInfo}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </S.Article>
    </S.Container>
  );
};

export default Account;
