import * as S from './styles/MonitoringStyle';
import { MapContainer, MapLoading, StatisticContainer } from './styles/MonitoringStyle';
import React from 'react';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { LoaderExtendType, MonitoringDeviceType } from '@typedef/Monitoring/monitoring.type';
import { ZonePlaytimeType, ZoneTypes } from '@typedef/Monitoring/zone.type';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';
import { AccountTypes } from '@typedef/Account/account.types';
import { ECompanyType } from '@typedef/Company/company-type.enum';
import Map from '@components/Monitoring/components/Map';
import StatusAndPlaybackHistoryContainer from '@components/Monitoring/containers/StatusAndPlaybackHistoryContainer';
import { PeriodType } from '@components/Common/CommonDate/CommonDate';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

type Props = {
  metaCityPeopleCount: { zepeto: { value: number; loading: boolean }; webGL: { value: number; loading: boolean } };
  deviceList: LoaderExtendType<MonitoringDeviceType[]>;
  realCityPeopleCount: LoaderExtendType<number>;
  realCityCompanionCount: LoaderExtendType<number>;
  zonePlayTime: ZonePlaytimeType[];
  handleDevicePower: (powerOn: EDeviceStatus, ip: string, zone?: ZoneTypes) => void;
  date: PeriodType;
  account: AccountTypes;
};

const Monitoring: React.FC<Props> = ({
  metaCityPeopleCount,
  deviceList,
  realCityPeopleCount,
  realCityCompanionCount,
  zonePlayTime,
  handleDevicePower,
  date,
  account,
}) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.Header>통합관제모니터링</S.Header>
      <S.Article $flexDirection={'column'}>
        <h3>{`${date.startedAt.split('T')[0]} ~ ${date.endedAt.split('T')[0]}`}</h3>
        <div className={'background monitoring'}>
          <StatisticContainer>
            <div>
              <div>
                <div>방문 통계</div>
                <div>메타시티</div>
                <button className={'detail-button'} onClick={() => navigate('/monitoring/meta-city')}>
                  상세정보
                </button>
              </div>

              <div className={'meta-city'}>
                {metaCityPeopleCount.webGL.loading ? (
                  <div className={'loading-message'}>데이터를 조회중입니다...</div>
                ) : (
                  <div>
                    <div>WebGL</div>
                    <div>
                      <span>{metaCityPeopleCount.webGL.value}</span>회
                    </div>
                  </div>
                )}

                {metaCityPeopleCount.zepeto.loading ? (
                  <div className={'loading-message'}>데이터를 조회중입니다...</div>
                ) : (
                  <div>
                    <div>제페토</div>
                    <div>
                      <span>{metaCityPeopleCount.zepeto.value}</span>회
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div>
                <div>방문 통계</div>
                <div>레알시티</div>
                <button className={'detail-button'} onClick={() => navigate('/monitoring/real-city')}>
                  상세정보
                </button>
              </div>

              <div className={'meta-city'}>
                {realCityPeopleCount.loading ? (
                  <div className={'loading-message'}>데이터를 조회중입니다...</div>
                ) : (
                  <div>
                    <div>방문자 수</div>
                    <div>
                      <span>{realCityPeopleCount.data}</span>회
                    </div>
                  </div>
                )}
                {realCityPeopleCount.loading ? (
                  <div className={'loading-message'}>데이터를 조회중입니다...</div>
                ) : (
                  <div>
                    <div>동행자 수</div>
                    <div>
                      <span>{realCityCompanionCount.data}</span>회
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StatisticContainer>

          {ECompanyType.MAKER_SPACE.equals(account.company.type) ? (
            <StatusAndPlaybackHistoryContainer />
          ) : (
            <MapContainer>
              {deviceList.loading ? (
                <MapLoading>데이터를 조회중입니다...</MapLoading>
              ) : (
                <>
                  <div className={'contents-container'}>
                    컨텐츠 현황
                    <button
                      className={'detail-button'}
                      onClick={() => navigate('/monitoring/content-playback-statistics')}
                    >
                      상세정보
                    </button>
                  </div>

                  <Map zonePlayTime={zonePlayTime} deviceList={deviceList.data} handleDevicePower={handleDevicePower} />
                </>
              )}
            </MapContainer>
          )}
        </div>
      </S.Article>
    </S.Container>
  );
};

export default Monitoring;
