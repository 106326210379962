import styled, { css } from 'styled-components';
import { getThemeBackground } from '@libs/getThemeBackground';
import { EBackgroundThemeType } from '@typedef/Contents/contents.types';

export const Container = styled.div<{ backgroundColor: string; backgroundTheme?: EBackgroundThemeType }>`
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    background-color: ${({ backgroundColor }) => backgroundColor};

    ${({ backgroundTheme }) => backgroundTheme && css`
        background-image: url(${getThemeBackground(backgroundTheme)});
        background-size: cover;
    `};
`;

export const Section = styled.div<{
  width: number;
  height: number;
  top: number;
  left: number;
  backgroundColor?: string;
}>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: absolute;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;

    video,
    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: fill;
    }
`;

export const Text = styled.p<{
  textColor?: string;
  textFamily?: string;
  textItalic?: boolean;
  textSize?: number;
  textUnderline?: boolean;
  textWeight?: boolean;
}>`
    color: ${({ textColor }) => textColor};
    font-family: ${({ textFamily }) => textFamily};
    font-style: ${({ textItalic }) => (textItalic ? 'italic' : 'none')};
    text-decoration: ${({ textUnderline }) => (textUnderline ? 'underline' : 'none')};
    font-weight: ${({ textWeight }) => (textWeight ? 'bold' : 'normal')};
    font-size: ${({ textSize }) => textSize}pt;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: pre-wrap;
`;

export const OffMonitor = styled.div<{ $color?: string }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ $color }) => $color ?? '#fff'};
    font-size: 50px;
    font-family: 'Pretendard', 'Apple SD Gothic Neo', serif;
`;
