import { useRecoilState, useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import { deviceListSelector } from '@stories/deviceList';
import { useCallback, useEffect, useState } from 'react';
import { ZepetoLogResponseType, ZepetoLogType } from '@typedef/Monitoring/monitoring.type';
import { defaultPageTypes, PageTypes } from '@typedef/libs/pagination.types';
import { apiRoute, requestSecureGet } from '@libs/api';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { convertDeviceResponseTypeToType, DeviceResponseTypes } from '@typedef/Device/device.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import StatusAndPlaybackHistory from '@components/Monitoring/components/StatusAndPlaybackHistory';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';
import useDevicePowerManage from '@hooks/useDevicePowerManage';
import dayjs from 'dayjs';

const StatusAndPlaybackHistoryContainer = () => {
  const token = useRecoilValue(accountSelector).accessToken;
  const [deviceList, setDeviceList] = useRecoilState(deviceListSelector);
  const [zepetoLogs, setZepetoLogs] = useState<ZepetoLogType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageInfo, setPageInfo] = useState<PageTypes>(defaultPageTypes);
  const [loading, setLoading] = useState<{ device: boolean; zepeto: boolean }>({ device: true, zepeto: true });

  const { updateDevicePower } = useDevicePowerManage();

  const loadDevices = useCallback(async () => {
    const { config, data } = await requestSecureGet<PaginationTypes<DeviceResponseTypes>>(
      `${apiRoute.devices.get}`,
      {},
      token,
    );

    if (config.status === 200) {
      setDeviceList(data.content.map((v) => convertDeviceResponseTypeToType(v)));
      setLoading((prevState) => ({
        ...prevState,
        device: false,
      }));
    }
  }, [token]);

  const loadZepetoLogs = useCallback(async () => {
    const queryParams: Record<string, any> = {
      page: currentPage,
      paged: true,
      size: 7,
      createdAt: `${dayjs('2024-05-08').toISOString()}~${dayjs().toISOString()}`,
    };
    const { config, data } = await requestSecureGet<ZepetoLogResponseType>(
      `${apiRoute.monitoring.zepetoLogs}${parseQueryParamsToString(queryParams)}`,
      {},
      token,
    );

    if (config.status === 200) {
      setZepetoLogs(data.content);
      setPageInfo(data);
      setLoading((prevState) => ({
        ...prevState,
        zepeto: false,
      }));
    }
  }, [currentPage, token]);

  const handleDevicePower = (currentStatus: EDeviceStatus, powerOn: EDeviceStatus, ip: string) => {
    if (currentStatus.value === 'ON' && EDeviceStatus.REQUEST_ON.equals(powerOn)) {
      alert('해당 장비의 전원이 이미 켜져 있습니다.');
    } else if (currentStatus.value === 'OFF' && EDeviceStatus.REQUEST_OFF.equals(powerOn)) {
      alert('해당 장비의 전원이 이미 꺼져 있습니다.');
    } else {
      updateDevicePower(ip, powerOn).then(loadDevices);
    }
  };

  const onPageChange = () => {
    setZepetoLogs([]);
  };

  useEffect(() => {
    void loadDevices();
  }, []);

  useEffect(() => {
    setLoading((prevState) => ({
      ...prevState,
      zepeto: true,
    }));
    void loadZepetoLogs();
  }, [currentPage]);

  return (
    <StatusAndPlaybackHistory
      deviceList={deviceList}
      zepetoLogs={zepetoLogs}
      loading={loading}
      pageInfo={pageInfo}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      onPageChange={onPageChange}
      handleDevicePower={handleDevicePower}
    />
  );
};

export default StatusAndPlaybackHistoryContainer;
