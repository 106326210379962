export type PageTypes = {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: { empty: boolean; sorted: boolean; unsorted: boolean };
    unpaged: boolean;
  };
  size: number;
  sort: { empty: boolean; sorted: boolean; unsorted: boolean };
  totalElements: number;
  totalPages: number;
};

export const defaultPageTypes: PageTypes = {
  empty: false,
  first: false,
  last: false,
  number: -1,
  numberOfElements: -1,
  pageable: {
    offset: -1,
    paged: false,
    pageNumber: -1,
    pageSize: -1,
    sort: { empty: false, sorted: false, unsorted: false },
    unpaged: false,
  },
  size: 1,
  sort: { empty: false, sorted: false, unsorted: false },
  totalElements: -1,
  totalPages: -1,
};

export type PaginationTypes<T> = PageTypes & {
  content: T[];
};
