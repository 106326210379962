import styled from 'styled-components';

export const Container = styled.main`
  width: 600px;
  .delete {
    p {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }

  .warning {
    margin-bottom: 15px;
    color: red;
    font-size: 12px;
    text-align: left;
  }

  .split {
    ${({ theme }) => theme.flex.row};
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .subtitle {
      span {
        color: red;
      }
      font-weight: 600;
    }
  }
`;

export const Input = styled.div`
  select {
    width: 120px;
    border-radius: 3px;
    padding: 3px 5px;
  }

  input {
    border-radius: 3px;
    width: 200px;
    border: 1px solid #333;
    padding: 3px 5px;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  .input-btn {
    width: 250px;
    display: flex;
    flex-direction: row;
    border: 1px solid #333;
    border-radius: 3px;

    input {
      flex: 3;
      min-width: 150px;
      padding: 5px;
      border: none;

      &:focus {
        outline: none;
      }
    }

    button {
      flex: 2;
      width: 100px;
      height: 100%;
      padding: 5px 0;
      border: none;
      background-color: #256662;
      color: #fff;

      &:active {
        opacity: 0.7;
      }
    }
  }
`;

export const DisplaySize = styled.div`
  ${({ theme }) => theme.flex.row};
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  div {
    display: flex;
    flex-wrap: wrap;

    input {
      width: 120px;
    }
  }
`;
