import styled from 'styled-components';
import { PreventDrag } from '@styles/global';

export const CustomCheckBoxStyle = styled.div<{ checked?: boolean }>`
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 4px;
    border: 1px solid ${({ checked }) => checked ? '#15b9f5' : '#a1a1a1'};
    color: ${({ checked }) => checked ? '#15b9f5' : '#a1a1a1'};
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    ${PreventDrag};

    & > svg {
        height: 14px;
        margin-bottom: 2px;

        path {
            fill: ${({ checked }) => checked ? '#15b9f5' : '#a1a1a1'};
        }
    }
`;