import { Option } from 'react-dropdown';

export enum EBackgroundType {
  COLOR = 'COLOR',
  THEME = 'THEME',
}

export enum EBackgroundThemeType {
  CORK = 'CORK',
  PAPER = 'PAPER',
  CEMENT = 'CEMENT',
}

export const BackgroundThemeList: Option[] = [
  { value: EBackgroundThemeType.CORK, label: '코르크', className: EBackgroundThemeType.CORK.toLowerCase() },
  { value: EBackgroundThemeType.PAPER, label: '종이', className: EBackgroundThemeType.PAPER.toLowerCase() },
  { value: EBackgroundThemeType.CEMENT, label: '시멘트', className: EBackgroundThemeType.CEMENT.toLowerCase() },
];

// 컨텐츠 타입
export type ContentTypes = {
  id: string;
  name: string;
  w: number;
  h: number;
  // 오프라인 모드 (처음 로딩 후 다시 데이터를 불러오지 않음)
  offlineAvailable: boolean;
  companyId: string;
  backgroundColor: string;
  backgroundType: EBackgroundType;
  backgroundTheme?: EBackgroundThemeType;
};

export const defaultContentTypes: ContentTypes = {
  id: '',
  name: '',
  w: 1920,
  h: 1080,
  offlineAvailable: false,
  companyId: '',
  backgroundColor: '',
  backgroundType: EBackgroundType.COLOR,
};
