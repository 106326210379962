import ContentPlaybackList from '../ContentPlaybackList';
import { useCallback, useEffect, useState } from 'react';
import { apiRoute, requestGetFile, requestSecureGet } from '@libs/api';
import { PageTypes, PaginationTypes, defaultPageTypes } from '@typedef/libs/pagination.types';
import { MonitoringDeviceType, LoaderExtendType } from '@typedef/Monitoring/monitoring.type';
import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import useSearch from '@hooks/useSearch';
import {
  ContentsPlayListType,
  ContentsPlaySearchTypes,
  contentPlaybackSearchOptions,
} from '@typedef/Monitoring/monitoringContents.type';
import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import { ZoneTypes } from '@typedef/Monitoring/zone.type';

const ContentPlaybackListContainer = () => {
  const profileData = useRecoilValue(accountSelector);

  const [deviceData, setDeviceData] = useState<LoaderExtendType<MonitoringDeviceType[]>>();
  const [contentPlaySummariesData, setContentPlaySummariesData] = useState<ContentsPlayListType[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageInfo, setPageInfo] = useState<PageTypes>(defaultPageTypes);

  const [searchOptions, setSearchOptions] =
    useState<SearchTableType<ContentsPlaySearchTypes>[]>(contentPlaybackSearchOptions);

  const { option, search } = useSearch();

  const loadDevice = useCallback(async () => {
    const { config, data } = await requestSecureGet<PaginationTypes<MonitoringDeviceType>>(
      apiRoute.monitoring.loadDeviceList + '?paged=false',
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setDeviceData({ loading: false, data: data.content });
    }
  }, [profileData.accessToken]);

  const loadZones = useCallback(async () => {
    const { config, data } = await requestSecureGet<PaginationTypes<ZoneTypes>>(
      apiRoute.monitoring.loadZoneList + '?paged=false',
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setSearchOptions((options) => {
        const i = options.findIndex((option) => option.value === 'zoneId');
        if (i > -1) {
          options[i] = {
            ...options[i],
            dropdownValues: data.content
              .map((v) => {
                return { name: v.name, value: v.id };
              })
              .sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              }),
          };
        }
        return [...options];
      });
    }
  }, []);

  const loadContentsPlaySummaries = useCallback(
    async (option?: string, search?: string, page?: number) => {
      const queryParams: Record<string, any> = { page: page, size: 10 };
      if (option) {
        queryParams[option] = search;
      }

      const { config, data } = await requestSecureGet<PaginationTypes<ContentsPlayListType>>(
        `${apiRoute.monitoring.contentsPlaySummaries}${parseQueryParamsToString(queryParams)}`,
        {},
        profileData.accessToken,
      );

      if (config.status === 200) {
        setContentPlaySummariesData(data.content);
        setPageInfo(data);
      } else {
        setContentPlaySummariesData([]);
      }
    },
    [profileData.accessToken],
  );

  const contentsPlayListExcelDownload = useCallback(async () => {
    const { config, data, headers } = await requestGetFile<BlobPart>(
      apiRoute.excel.contentsPlayListExcelDownload + `?paged=false`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      const url = window.URL.createObjectURL(new Blob([data], { type: String(headers['Content-Type']) }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `컨텐츠 재생 조회 데이터.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  }, [profileData.accessToken]);
  const onPageChange = (page: number) => {
    setContentPlaySummariesData([]);
  };

  useEffect(() => {
    void loadContentsPlaySummaries(option!, search!, currentPage);
    void loadDevice();
    void loadZones();
  }, [currentPage, option, search]);

  return (
    <ContentPlaybackList
      contentPlaySummariesData={contentPlaySummariesData}
      searchOptions={searchOptions}
      deviceData={deviceData}
      pageInfo={pageInfo}
      currentPage={currentPage}
      onPageChange={onPageChange}
      setCurrentPage={setCurrentPage}
      contentsPlayListExcelDownload={contentsPlayListExcelDownload}
    />
  );
};

export default ContentPlaybackListContainer;
