import { DefaultTheme } from 'styled-components';

// flex 속성
const flex = {
  row: `
        display: flex;
        flex-direction: row;
      `,
  col: `
        display: flex;
        flex-direction: column;
      `,
};

const colors = {
  brand: '#256662',
  danger: '#F35D3C',
  point: '#C5CAFF',
  // F5F0EA
};

export const theme: DefaultTheme = {
  flex,
  colors,
};
