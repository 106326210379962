import { WidgetTypes } from '@typedef/Widget/widget.types';
import Sub from '../Sub';
import { useRef, useState, useEffect } from 'react';

type Props = {
  widget: WidgetTypes;
};

const SubContainer = ({ widget }: Props) => {
  const subtitleRef = useRef<HTMLParagraphElement>(null);
  const [width, setWidth] = useState<number>(0);

  // 자막 컴포넌트를 사용하는 div의 width값 가져오기
  useEffect(() => {
    if (subtitleRef) {
      const subtitleWidth = subtitleRef.current?.clientWidth;
      setWidth(subtitleWidth ?? 0);
    }
  }, [widget]);

  return <Sub widget={widget} width={width} subtitleRef={subtitleRef} />;
};

export default SubContainer;
