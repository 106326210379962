import { isArray } from 'lodash';

function isValidQueryParams(queryParam: any): boolean {
  return queryParam !== null && queryParam !== undefined && queryParam !== '';
}

export function parseQueryParamsToString(queryParams: { [key: string]: any }, withoutQuestion?: boolean): string {
  const parsedQueryParams: string[] = [];
  Object.keys(queryParams).forEach((key) => {
    if (!isValidQueryParams(queryParams[key])) {
      return;
    }
    if (isArray(queryParams[key])) {
      const arrQueryParam = queryParams[key].filter((item: any) => isValidQueryParams(item));
      if (arrQueryParam.length > 0) {
        parsedQueryParams.push(`${key}=${arrQueryParam.join(',')}`);
      }
      return;
    }
    parsedQueryParams.push(`${key}=${queryParams[key]}`);
  });
  return `${withoutQuestion ? '' : '?'}${parsedQueryParams.join('&')}`;
}
