import React, { useCallback } from 'react';
import '../styles/CustomStyle.scss';
import { BoardPostType, BoardSlidesDefaultValue, WidgetTypes } from '@typedef/Widget/widget.types';
import CustomCheckBox from '@components/Common/CustomCheckBox/CustomCheckBox';
import { EBackgroundType } from '@typedef/Contents/contents.types';
import CustomBackgroundColor from './CustomBackgroundColor';
import CustomBackgroundTheme from './CustomBackgroundTheme';
import { inputNumberOnly } from '@libs/inputValidator';
import CustomTemplate from '@components/Widgets/components/CustomTemplate';
import CustomShadow from '@components/Widgets/components/CustomShadow';
import { CustomCheckbox } from '@components/Widgets/styles/WidgetStyle';

type Props = {
  selectLayout: WidgetTypes | null;
  handleChangeSlide: (id: string, value: any) => void;
  handleSizeChange: (isRow: boolean, plus: boolean) => void;
  handleOnChange: (id: string, value: any) => void;
  handleToggleSlide: (e: React.ChangeEvent<HTMLInputElement>) => void;
  nextSlide: () => void;
  previousSlide: () => void;
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>;
  activeSlide: number;
};

const CustomSmartBoard: React.FC<Props> = ({
  selectLayout,
  handleChangeSlide,
  handleSizeChange,
  handleOnChange,
  handleToggleSlide,
  nextSlide,
  previousSlide,
  setActiveSlide,
  activeSlide,
}) => {
  const slide = activeSlide === 0 ? selectLayout : selectLayout?.boardSlides?.[activeSlide - 1];

  const handleAllPosts = useCallback((id: string, value: any) => {
    const updateBoardFiles = (boardFiles: BoardPostType[][]) =>
      boardFiles.map(row => row.map(item => ({ ...item, [id]: value })));

    if (activeSlide === 0 && slide?.boardFiles) {
      const newBoardFiles = updateBoardFiles(slide.boardFiles);
      handleOnChange('boardFiles', newBoardFiles);
    } else if (selectLayout?.boardSlides) {
      const newBoardSlides = selectLayout.boardSlides.map((slideItem, index) => {
        if (index === activeSlide - 1) {
          return {
            ...slideItem,
            boardFiles: updateBoardFiles(slideItem.boardFiles),
          };
        }
        return slideItem;
      });

      handleOnChange('boardSlides', newBoardSlides);
    }
  }, [activeSlide, handleOnChange, selectLayout, slide]);

  const handleSlides = useCallback((plus: boolean) => {
    if (selectLayout?.boardSlides) {
      let newBoardSlides = [];
      if (plus) {
        newBoardSlides = [...selectLayout.boardSlides, BoardSlidesDefaultValue];
      } else {
        newBoardSlides = selectLayout.boardSlides.slice(0, selectLayout.boardSlides.length - 1);
      }
      handleChangeSlide('boardSlides', newBoardSlides);
    }
  }, [handleChangeSlide, selectLayout]);

  return (
    <div className="custom-board">
      <div className="smart-board">
        <div className={'flex-container'}>
          <p className="title">슬라이드</p>
          <div>
            <CustomCheckbox checked={selectLayout?.boardSlides ? selectLayout?.boardSlides?.length > 0 ?? false : false}
                            onChange={handleToggleSlide} />
          </div>
        </div>
        {selectLayout?.boardSlides && selectLayout?.boardSlides?.length > 0 &&
          <>
            <div className="size">
              <p className="subtitle">슬라이드 개수</p>
              <div className={'count-container'}>
                <button onClick={() => handleSlides(false)}>-</button>
                <span>{selectLayout?.boardSlides?.length! + 1}개</span>
                <button onClick={() => handleSlides(true)}>+</button>
              </div>
            </div>
            <div className="size">
              <p className="subtitle">슬라이드 속도</p>
              <div className="number-input">
                <input value={selectLayout?.autoPlaySpeed}
                       onChange={(e) => handleOnChange('autoPlaySpeed', Number(inputNumberOnly(e.target.value)))} />
              </div>
            </div>
            <div className="size last-element">
              <p className="subtitle">이동</p>
              <div className={'count-container'}>
                <button onClick={previousSlide}>{'<'}</button>
                <span>{selectLayout?.boardSlides?.length! + 1}개 중 {activeSlide + 1}</span>
                <button onClick={nextSlide}>{'>'}</button>
              </div>
            </div>
            <br />
          </>
        }
        <div className="size">
          <p className="subtitle">게시물 열 개수</p>
          <div className={'count-container'}>
            <button onClick={() => handleSizeChange(false, false)}>-</button>
            <span>{slide?.boardFiles?.[0].length ?? 0}개</span>
            <button onClick={() => handleSizeChange(false, true)}>+</button>
          </div>
        </div>
        <div className="size">
          <p className="subtitle">게시물 행 개수</p>
          <div className={'count-container'}>
            <button onClick={() => handleSizeChange(true, false)}>-</button>
            <span>{slide?.boardFiles ? slide.boardFiles?.length : 0}개</span>
            <button onClick={() => handleSizeChange(true, true)}>+</button>
          </div>
        </div>
        <div className="size">
          <p className="subtitle">게시판 여백</p>
          <div className="number-input">
            <input value={slide?.padding}
                   onChange={(e) => handleChangeSlide('padding', Number(inputNumberOnly(e.target.value)))} />
          </div>
        </div>
        <div className="size">
          <p className="subtitle">게시물 사이 여백</p>
          <div className="number-input">
            <input value={slide?.gap}
                   onChange={(e) => handleChangeSlide('gap', Number(inputNumberOnly(e.target.value)))} />
          </div>
        </div>
      </div>
      <div className="smart-board">
        <div className="custom">
          <div className="background-type">
            <p className="title">타입</p>
            <div className={'checkbox-container'}>
              <CustomCheckBox
                label={'색상'}
                checked={slide?.backgroundType === EBackgroundType.COLOR}
                onChange={() => handleChangeSlide('backgroundType', EBackgroundType.COLOR)}
              />
              <CustomCheckBox
                label={'테마'}
                checked={slide?.backgroundType === EBackgroundType.THEME}
                onChange={() => handleChangeSlide('backgroundType', EBackgroundType.THEME)}
              />
            </div>
          </div>
        </div>
        {slide?.backgroundType === EBackgroundType.COLOR && (
          <CustomBackgroundColor
            handleOnChange={(id, value) => handleChangeSlide(id, value)}
            selectLayout={slide}
          />
        )}
        {slide?.backgroundType === EBackgroundType.THEME && (
          <CustomBackgroundTheme
            handleOnChange={(id, value) => handleChangeSlide(id, value)}
            selectLayout={slide}
          />
        )}
      </div>
      <div className={'subtitle-text'}>게시물 전체 설정</div>
      <CustomShadow handleOnChange={handleAllPosts} selectPost={null} />
      <CustomTemplate handleOnChange={handleAllPosts} selectPost={null} globalStyle={true} />
    </div>
  );
};

export default CustomSmartBoard;
