import { deciceSearchOptions, DeviceImageMapTypes, DeviceTypes } from '@typedef/Device/device.types';
import * as S from './styles/MainStyle';
import { StyledTooltip } from './styles/MainStyle';
import { MINIO_URL } from '@libs/remote';
import SearchTableContainer from '@components/Common/SearchTable/containers/SearchTableContainer';
import Pagination from '@components/Common/Pagination/Pagination';
import { PageTypes } from '@typedef/libs/pagination.types';
import React from 'react';
import Spinner from '@components/Common/Spinner/Spinner';
import { LoadingModalContainer } from '@components/Widgets/styles/WidgetStyle';

type Props = {
  loading: boolean;
  devices: DeviceTypes[];
  deviceImageMap: DeviceImageMapTypes;
  onDeviceClick: (device: DeviceTypes) => void;
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  onSearchSummit: () => void;
};

const Main = ({
  loading,
  devices,
  deviceImageMap,
  onDeviceClick,
  pageInfo,
  setCurrentPage,
  currentPage,
  onPageChange,
  onSearchSummit,
}: Props) => {
  return (
    <>
      <S.Container>
        <S.MainHeader>
          <div>전광판 현황</div>
          <SearchTableContainer searchOptions={deciceSearchOptions} onSummit={onSearchSummit} />
        </S.MainHeader>
        <S.Main loading={loading}>
          {
            loading ?
              <LoadingModalContainer>
                <Spinner width={100} height={100} size={50} />
                <div className={'loading-text'}>전광판 리스트를 로딩 중입니다...</div>
              </LoadingModalContainer> :
              devices.length ? (
                <S.DeviceList>
                  {devices.map((device) => (
                    <StyledTooltip title="클릭 시, 전광판 페이지로 이동됩니다." placement={'bottom'}>
                      <S.Device onClick={() => onDeviceClick(device)}>
                        <S.DeviceImageBox>
                          {deviceImageMap.get(device.id)?.fileUrl?.length === 0 ? (
                              <S.DeviceNotWork>전광판 스케줄이 비어 있습니다.</S.DeviceNotWork>
                            ) :
                            device.currentPlayImg ? (
                                <S.DeviceImage
                                  src={`${MINIO_URL}/${deviceImageMap.get(device.id)?.fileUrl}`}
                                  alt={`${device.id}`}
                                />
                              )
                              : (
                                <S.DeviceNotWork>
                                  {
                                    device.isPowerManageable ?
                                      <div>장비가 OFF 되어있습니다.</div> :
                                      <div>전원 관리를<br />지원하지 않는 장비입니다.</div>
                                  }
                                </S.DeviceNotWork>
                              )}
                          <S.ImageSize>{`${device.width} X ${device.height}`}</S.ImageSize>
                        </S.DeviceImageBox>
                        <S.DeviceName>
                          <div className={'device-name'}>
                            <div>{device.name}</div>
                            <S.ContentName>{deviceImageMap.get(device.id)?.contentsName ?? '동작중인 컨텐츠 없음'}</S.ContentName>
                          </div>
                          {device.isPowerManageable &&
                            <S.DeviceStatus status={device.status?.data ?? false}>
                              {device.status.data ? 'ON' : 'OFF'}
                            </S.DeviceStatus>
                          }
                        </S.DeviceName>
                      </S.Device>
                    </StyledTooltip>
                  ))}
                </S.DeviceList>
              ) : (
                <S.DeviceNothing>생성되어 있는 보드가 없습니다.</S.DeviceNothing>
              )}
        </S.Main>
        <Pagination
          onPageChange={onPageChange}
          pageInfo={pageInfo}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </S.Container>
    </>
  );
};

export default Main;
