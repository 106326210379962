import * as S from './styles/CafeOrdetListStyle';
import React from 'react';
import { ArrowLeftWhite, Bell, Delete, Pencil, Plus, Receipt } from '@assets/icons';
import {
  EOrderStatusType,
  OrderDetailTypes,
  OrderListTypes,
  orderStepList,
  OrderTypes,
  SelectedOrderTypes,
} from '@typedef/CafeOrderList/orderTypes';
import Spinner from '@components/Common/Spinner/Spinner';
import { Fade, Snackbar } from '@mui/material';
import { getFormattedDate } from '@libs/dateUtil';
import { LongPressPointerHandlers, LongPressResult } from 'use-long-press';
import { CompanyTypes } from '@typedef/Company/company.types';
import * as Swipe from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

type Props = {
  handleCreateOrder: (e: React.FormEvent<HTMLFormElement>) => void;
  orderNumber: string;
  setOrderNumber: React.Dispatch<React.SetStateAction<string>>;
  selectedOrder: SelectedOrderTypes | null;
  orderListData: OrderListTypes<OrderTypes[]>;
  loadingStatus: OrderListTypes<boolean>;
  editOrderState: OrderDetailTypes | null;
  handleSelectOrder: (status: EOrderStatusType, id: string) => void;
  handleUpdateOrderList: (type: 'prev' | 'next') => Promise<void>;
  handleDeleteOrder: () => Promise<void>;
  longPressHandler: LongPressResult<LongPressPointerHandlers>;
  handleEditNumber: (e: React.FormEvent<HTMLFormElement>) => void;
  setEditOrderState: React.Dispatch<React.SetStateAction<OrderDetailTypes | null>>;
  handleOrderStep: (e: React.MouseEvent<HTMLButtonElement>, id: string, status: EOrderStatusType) => void;
  handleDetailEditOrder: (order: OrderTypes, e: React.MouseEvent<HTMLButtonElement>) => void;
  snackbarAlertState: {
    open: boolean;
    message: string;
    type: 'success' | 'delete' | null;
  };
  handleSnackbarClose: () => void;
  company: CompanyTypes;
  handleOpenModal: () => void;
  handleSwipeProgress: (progress: number, orderId: string) => void;
};

const ManageOrder: React.FC<Props> = ({
  handleCreateOrder,
  orderNumber,
  setOrderNumber,
  selectedOrder,
  orderListData,
  loadingStatus,
  editOrderState,
  handleSelectOrder,
  handleUpdateOrderList,
  handleDeleteOrder,
  longPressHandler,
  handleEditNumber,
  setEditOrderState,
  handleOrderStep,
  handleDetailEditOrder,
  snackbarAlertState,
  handleSnackbarClose,
  company,
  handleOpenModal,
  handleSwipeProgress,
}) => {
  const trailingActions = () => (
    <Swipe.TrailingActions>
      <Swipe.SwipeAction
        destructive={true}
        onClick={() => console.log('delete')}
      >
        <S.DeleteContainer>
          <Delete /> 삭제
        </S.DeleteContainer>
      </Swipe.SwipeAction>
    </Swipe.TrailingActions>
  );

  return (
    <S.Container>
      <S.MainHeader>
        <div>
          <div>주문 번호 관리</div>
          <div>* 버튼 우측의 화살표를 클릭 시, 다음 단계로 이동 됩니다.</div>
          <div>* 버튼을 길게 누르거나 수정 버튼 클릭 시, 해당 주문을 수정/상세정보 확인을 할 수 있습니다.</div>
        </div>
        <form onSubmit={handleCreateOrder}>
          {!company.orderNumberAutoGeneration && (
            <div className={'input-container'}>
              <Receipt />
              <input
                type="number"
                placeholder="주문 번호를 입력하세요."
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                required={true}
              />
            </div>
          )}

          <button type={'submit'}>
            {company.orderNumberAutoGeneration && <Plus />}
            추가
          </button>
        </form>

        <button type={'button'} className={'notice-button'} onClick={handleOpenModal}>
          <Bell />
          공지
        </button>
      </S.MainHeader>
      <S.Main>
        <S.ListWrapper>
          {orderStepList.map((orderList, i) => (
            <div key={`list_${i}`}>
              <S.ButtonContainer $visible={selectedOrder?.status === orderList.status}>
                {orderList.status !== EOrderStatusType.PENDING && (
                  <button className={'prev-button'} onClick={() => handleUpdateOrderList('prev')}>
                    이전 단계
                  </button>
                )}
                {orderList.status !== EOrderStatusType.PICKED_UP && (
                  <button className={'next-button'} onClick={() => handleUpdateOrderList('next')}>
                    다음 단계
                  </button>
                )}
                <button className={'delete-button'} onClick={handleDeleteOrder}>
                  <Delete /> 삭제
                </button>
              </S.ButtonContainer>
              <S.OrderListContainer $itemClicked={selectedOrder?.status === orderList.status}>
                <S.ContainerName $status={orderList.status}>
                  <div className={'text-header'}>
                    {orderList.name}
                    <div>{orderListData[orderList.status].length}</div>
                  </div>
                </S.ContainerName>

                <S.StyledSwipeableList>
                  <div className={'number-list'}>
                    {loadingStatus[orderList.status] ? (
                      <div className={'pre-container'}>
                        <Spinner color={'#2b3e63'} message={`${orderList.name}을(를) 조회중입니다..`} size={30} />
                      </div>
                    ) : orderListData[orderList.status].length === 0 ? (
                      <div className={'none-container'}>
                        <p>{orderList.name}이(가) 없습니다.</p>
                      </div>
                    ) : (
                      orderListData[orderList.status].map((order, i) => {
                        const isEdit = editOrderState?.open && editOrderState.id === order.id;
                        return (
                          <Swipe.SwipeableListItem
                            onSwipeProgress={(progress) => handleSwipeProgress(progress, order.id)}
                            trailingActions={trailingActions()}
                          >
                            <S.OrderButton
                              key={`order_${i}`}
                              className={'order-item'}
                              $selected={selectedOrder?.ids.includes(order.id)}
                              $isEdit={isEdit}
                              onClick={() => handleSelectOrder(orderList.status, order.id)}
                              onContextMenu={(e) => e.preventDefault()}
                              onContextMenuCapture={(e) => e.preventDefault()}
                              {...longPressHandler(order)}
                            >
                              {isEdit && (
                                <div className={'detail-info'}>
                                  <div>생성 일시: {getFormattedDate(order.createdAt)}</div>
                                  {order.updatedAt && <div>수정 일시: {getFormattedDate(order.updatedAt)}</div>}
                                </div>
                              )}
                              <form onSubmit={handleEditNumber}>
                                <div className={'default-container'}>
                                  <input
                                    type={'text'}
                                    readOnly={!isEdit}
                                    required={true}
                                    value={isEdit ? editOrderState?.number : order.number}
                                    onChange={(e) => {
                                      if (isEdit && editOrderState) {
                                        setEditOrderState({ ...editOrderState, number: e.target.value });
                                      }
                                    }}
                                  />

                                  <button
                                    type={'button'}
                                    className={'edit-button'}
                                    onClick={(e) => handleDetailEditOrder(order, e)}
                                  >
                                    <Pencil />
                                  </button>

                                  {orderList.status !== EOrderStatusType.PICKED_UP && (
                                    <button
                                      type={'button'}
                                      className={'next-button'}
                                      onClick={(e) => handleOrderStep(e, order.id, orderList.status)}
                                    >
                                      <ArrowLeftWhite />
                                    </button>
                                  )}
                                </div>
                                {isEdit && (
                                  <div className={'button-container'}>
                                    <button onClick={() => setEditOrderState(null)} type={'button'}>
                                      닫기
                                    </button>
                                    <button type={'submit'}>수정</button>
                                  </div>
                                )}
                              </form>
                            </S.OrderButton>
                          </Swipe.SwipeableListItem>
                        );
                      })
                    )}
                  </div>
                </S.StyledSwipeableList>
              </S.OrderListContainer>
            </div>
          ))}
        </S.ListWrapper>
      </S.Main>
      <Snackbar
        open={snackbarAlertState.open}
        autoHideDuration={3000}
        TransitionComponent={Fade}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <S.SnackbarAlert $type={snackbarAlertState.type}>{snackbarAlertState.message}</S.SnackbarAlert>
      </Snackbar>
    </S.Container>
  );
};

export default ManageOrder;
