import { ContentTypes, EBackgroundType } from '@typedef/Contents/contents.types';
import * as S from './styles/PreviewContentStyle';
import SubContainer from '@components/Common/Sub/containers/SubContainer';
import CustomWidgetContatiner from '@components/Widgets/containers/CustomWidgetContainer';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import React from 'react';
import FileWidget from '@components/Widgets/components/FileWidget';
import { convertToRgba } from '@libs/colorUtiles';

type Props = {
  monitorRef: React.RefObject<HTMLDivElement>;
  widgetList: WidgetTypes[];
  rowWidth: number;
  colHeight: number;
  content: ContentTypes;
};

const PreviewContent = ({ monitorRef, widgetList, rowWidth, colHeight, content }: Props) => {
  return (
    <>
      <S.Container ref={monitorRef} backgroundColor={content.backgroundColor}
                   backgroundTheme={content.backgroundType === EBackgroundType.THEME ? content.backgroundTheme : undefined}>
        {widgetList.map((data) => (
          <S.Section
            key={data.id}
            id={data.i}
            width={rowWidth * data.w}
            height={colHeight * data.h}
            top={colHeight * data.y}
            left={rowWidth * data.x}
            backgroundColor={convertToRgba(data.backgroundColor, data.opacity)}
          >
            {data.type.equals(EWidgetType.FILE) || data.type.equals(EWidgetType.FILE_LIST) ? (
              <FileWidget widget={data} />
            ) : data.type.equals(EWidgetType.SUB) ? (
              <SubContainer widget={data} />
            ) : data.type.equals(EWidgetType.NEWS) ? (
              <CustomWidgetContatiner widget={data} content={content} />
            ) : data.type.equals(EWidgetType.FINEDUST) ? (
              <CustomWidgetContatiner widget={data} isSetting={false} content={content} />
            ) : data.type.equals(EWidgetType.TIME) ? (
              <CustomWidgetContatiner widget={data} content={content} />
            ) : data.type.equals(EWidgetType.WEATHER) ? (
              <CustomWidgetContatiner widget={data} isSetting={false} content={content} />
            ) : data.type.equals(EWidgetType.STOCK_INDEX) ? (
              <CustomWidgetContatiner widget={data} isSetting={false} content={content} />
            ) : data.type.equals(EWidgetType.SMART_BOARD) ? (
              <CustomWidgetContatiner widget={data} content={content} />
            ) : data.type.equals(EWidgetType.IFRAME) ? (
              <CustomWidgetContatiner widget={data} content={content} />
            ) : data.type.equals(EWidgetType.ORDER_LIST) ? (
              <CustomWidgetContatiner widget={data} content={content} />
            ) : (
              <S.Text
                textColor={data.textColor}
                textFamily={data.textFamily}
                textItalic={data.textItalic}
                textSize={data.textSize}
                textUnderline={data.textUnderline}
                textWeight={data.textWeight}
              >
                {data.text}
              </S.Text>
            )}
          </S.Section>
        ))}
      </S.Container>
    </>
  );
};

export default PreviewContent;
