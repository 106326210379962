import { apiRoute, requestSecureGet } from '@libs/api';
import { accountSelector } from '@stories/account';
import { AccountResponseTypes, AccountTypes } from '@typedef/Account/account.types';
import { useCallback, useEffect, useState } from 'react';
import Account from '../Account';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalSelector } from '@stories/modal';
import AccountManageContainer from './AccountManageContainer';
import { defaultPageTypes, PageTypes, PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import useSearch from '@hooks/useSearch';

const AccountContainer = () => {
  const [accountList, setAccountList] = useState<AccountTypes[]>([]);
  const [reload, setReload] = useState<number>(0);

  const [checkedList, setCheckedList] = useState<AccountTypes[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageInfo, setPageInfo] = useState<PageTypes>(defaultPageTypes);

  const { option, search } = useSearch();

  const profileData = useRecoilValue(accountSelector);
  const setModal = useSetRecoilState(modalSelector);

  const isManagerAttemptingMasterAction = useCallback((
    profileRole: EAccountRole,
    targetAccounts: AccountTypes | AccountTypes[],
  ): boolean => {
    if (!EAccountRole.MANAGER.equals(profileRole)) {
      return false;
    }

    const checkMasterRole = (account: AccountTypes) => EAccountRole.MASTER.equals(account.role);

    return Array.isArray(targetAccounts)
      ? targetAccounts.some(checkMasterRole)
      : checkMasterRole(targetAccounts);
  }, []);

  // 모달 오픈
  const onClickModalOpen = useCallback(
    (id: string, selectAccount?: AccountTypes) => {

      if (selectAccount && isManagerAttemptingMasterAction(profileData.role, selectAccount)) {
        alert('마스터 권한은 수정할 수 없습니다.');
        return;
      }

      if (EAccountRole.MANAGER.equals(profileData.role)) {
        return alert('매니저 권한은 수정 및 삭제가 불가능합니다.');
      }

      if (id === 'delete' && isManagerAttemptingMasterAction(profileData.role, checkedList)) {
        alert('마스터 권한은 삭제할 수 없습니다.');
        return;
      }

      const modal: {
        [key: string]: { id: string; header: string; body: JSX.Element };
      } = {
        update: {
          id: 'update',
          header: '계정 수정하기',
          body: (
            <AccountManageContainer
              id="update"
              type="수정"
              checkedList={selectAccount ? [selectAccount] : null}
              reload={() => {
                setReload((prev) => prev + 1);
              }}
            />
          ),
        },
        delete: {
          id: 'delete',
          header: '계정 삭제하기',
          body: (
            <AccountManageContainer
              id="delete"
              type="삭제"
              checkedList={checkedList}
              reload={() => {
                setReload((prev) => prev + 1);
              }}
            />
          ),
        },
        updateRole: {
          id: 'updateRole',
          header: '계정 권한 수정하기',
          body: (
            <AccountManageContainer
              id="updateRole"
              type="권한 수정"
              checkedList={checkedList}
              reload={() => {
                setReload((prev) => prev + 1);
              }}
            />
          ),
        },
      };

      if (id === 'delete' && checkedList.length === 0) {
        return alert('계정을 먼저 선택해 주세요');
      }

      setModal({
        header: modal[id].header,
        close: true,
        body: modal[id].body,
      });
    },
    [profileData.role, checkedList, setModal],
  );

  // 계정 조회
  const loadAccounts = useCallback(
    async (option?: string, search?: string, page?: number) => {
      const queryParams: Record<string, any> = { page: page, size: 8 };
      if (option) {
        queryParams[option] = search;
      }

      const { config, data } = await requestSecureGet<PaginationTypes<AccountResponseTypes>>(
        `${apiRoute.account.getWithRoot}${parseQueryParamsToString(queryParams)}`,
        {},
        profileData.accessToken,
      );

      if (config.status !== 200) {
        alert(config.errorMessage);
        return;
      }

      setAccountList(
        data.content.map((v) => {
          return { ...v, role: EAccountRole.valueOf(v.role)! };
        }),
      );
      setPageInfo(data);
    },
    [profileData.accessToken],
  );

  const onSearchSummit = () => {
    setCurrentPage(1);
  };
  const onPageChange = (page: number) => {
    setAccountList([]);
  };
  useEffect(() => {
    loadAccounts(option!, search!, currentPage);
    setCheckedList([]);
  }, [option, search, currentPage, reload]);

  return (
    <Account
      profileData={profileData}
      onClickModalOpen={onClickModalOpen}
      accountList={accountList}
      checkedList={checkedList}
      setCheckedList={setCheckedList}
      pageInfo={pageInfo}
      currentPage={currentPage}
      onPageChange={onPageChange}
      setCurrentPage={setCurrentPage}
      onSearchSummit={onSearchSummit}
    />
  );
};

export default AccountContainer;
