import { apiRoute, requestGet } from '@libs/api';
import { getCurrentSchedule } from '@libs/getCurrentSchedule';
import { deviceScheduleMapSelector } from '@stories/deviceScheduleList';
import { DeviceScheduleTypes, DeviceTypes } from '@typedef/Device/device.types';
import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import {
  GetSchedulesInputsDefault,
  GetSchedulesInputsTypes,
  ScheduleResponseTypes,
  ScheduleTypes,
} from '@typedef/Schedule/schedule.types';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';

export default function useDeviceScheduleState() {
  const [deviceScheduleMap, setDeviceScheduleMap] = useRecoilState(deviceScheduleMapSelector);

  const updateScheduleWithDeviceId = useCallback(
    async (id: string) => {
      const getSchedulesInputs: GetSchedulesInputsTypes = {
        ...GetSchedulesInputsDefault,
        deviceId: id,
      };

      const {
        data: { content },
        config,
      } = await requestGet<PaginationTypes<ScheduleResponseTypes>>(
        `${apiRoute.schedule.getScheduleDeviceWithoutJWT}${parseQueryParamsToString(getSchedulesInputs)}`,
        {},
      );

      const bs: DeviceScheduleTypes = {
        contentId: '',
        status: false,
      };

      if (config.status === 200) {
        const scheduleData = content.map((v) => {
          return {
            ...v,
            repetitionType: EScheduleRepetitionType.valueOf(v.repetitionType),
          };
        });

        const schedule: ScheduleTypes | undefined = await getCurrentSchedule(scheduleData);

        bs.contentId = schedule ? schedule.contentId : '';
        bs.status = !!schedule;

        setDeviceScheduleMap((v) => {
          v.set(id, bs);
          return new Map(v);
        });
      }

      return bs;
    },
    [setDeviceScheduleMap],
  );

  const updateAllDeviceSchedule = useCallback(
    async (deviceList: DeviceTypes[]) => {
      for (const data of deviceList) {
        updateScheduleWithDeviceId(data.id);
      }
    },
    [updateScheduleWithDeviceId],
  );

  return {
    deviceScheduleMap,
    updateScheduleWithDeviceId,
    updateAllDeviceSchedule,
  };
}
