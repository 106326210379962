import { atom, selector } from 'recoil';

const modalAtom = atom<{
  header: string;
  close: boolean;
  onClose?: () => void;
  body: JSX.Element;
} | null>({
  key: 'modalAtom',
  default: null,
});

export const modalSelector = selector({
  key: 'modalSelector',
  get: ({ get }) => {
    return get(modalAtom);
  },
  set: ({ set }, value) => {
    set(modalAtom, value);
  },
});
