import styled from 'styled-components';

const space = '15px';
const height = '40px';

export const Container = styled.main`
    width: 100%;
    height: 100%;
`;

export const Header = styled.header`
    ${({ theme }) => theme.flex.row};
    align-items: center;
    justify-content: space-between;
`;

export const Button = styled.button<{ backgroundColor: string }>`
    height: ${height};
    padding: 0 ${space};
    border: 1px solid ${({ backgroundColor }) => backgroundColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-right: 10px;

    svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    p {
        color: #f1f1f1;
    }

    &:active {
        opacity: 0.8;
    }
`;

export const Article = styled.article`
    margin-top: 10px;

    .background {
        ${({ theme }) => theme.flex.col};
        padding: ${space};
        background-color: #fff;
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
        border-radius: 7px;
        height: 70vh;

        .tableHeader {
            width: 100%;
            border-bottom: 1px solid #ddd;
        }

        .tableContent {
            height: 100%;
            overflow-x: auto;

            &::-webkit-scrollbar {
                width: 7px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background: #ddd;
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #ccc;
            }
        }

        table {
            width: 100%;
            table-layout: fixed;
            border-collapse: separate;
            border-spacing: 0;
            border-radius: 5px 5px 0 0;
            overflow: hidden;

            thead {
                background-color: #2b3e63;

                tr {
                    th {
                        padding: ${space} 0;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 600;
                        color: #fff;

                        &:first-child {
                            border-top-left-radius: 5px;
                        }

                        &:last-child {
                            border-top-right-radius: 5px;
                        }

                        input[type='checkbox'] {
                            cursor: pointer;
                            transform: scale(1.3);
                        }
                    }
                }
            }
        }
    }
`;

export const Tr = styled.tr<{ checked: boolean }>`
    cursor: pointer;
    background-color: ${({ checked }) => (checked ? '#e0f1eb' : '#ffffff')};
    height: 8vh;

    &:nth-child(even) {
        background-color: #f8f8f8;
    }

    td {
        vertical-align: middle;
        padding: 10px 0;
        text-align: center;
        font-size: 15px;

        &:first-child {
            border-radius: 5px 0 0 5px;
        }

        &:last-child {
            border-radius: 0 5px 5px 0;
        }

        input[type='checkbox'] {
            cursor: pointer;
            scale: 1.3;

            &:checked {
                background-color: #fff;
            }
        }

        select {
            width: 100%;
            border-radius: 5px;
        }

        a {
            color: #8ab4f8;
        }
    }

    &:hover {
        background-color: ${({ checked }) => (checked ? '#e0f1eb' : '#f0f0f0')};
    }
`;
