import * as S from './styles/OrderListStyle';
import { EOrderStatusType, OrderListWidgetTypes, OrderTypes } from '@typedef/CafeOrderList/orderTypes';
import React from 'react';
import { Coffee, ReceiptWhite } from '@assets/icons';

type Props = {
  isSetting?: boolean;
  orderListData: OrderListWidgetTypes<OrderTypes[]>;
  isPlaying: boolean;
  handleTogglePlay: () => void;
};

const OrderList: React.FC<Props> = ({ isSetting = false, orderListData, isPlaying, handleTogglePlay }) => {
  const renderOrderNumbers = (status: Exclude<EOrderStatusType, EOrderStatusType.PICKED_UP>) => {
    const orders = isSetting ? [1111, 1112, 1113] : orderListData[status];

    return orders.map((order) => (
      <div key={isSetting ? (order as number) : (order as OrderTypes).id} className={'order-number'}>
        {isSetting ? (order as number) : (order as OrderTypes).number}
      </div>
    ));
  };

  const renderOrderListHeader = (
    status: Exclude<EOrderStatusType, EOrderStatusType.PICKED_UP>,
    icon: JSX.Element,
    title: string,
  ) => {
    const count = isSetting ? 3 : orderListData[status].length;

    return (
      <S.OrderListHeader $status={status}>
        <div className={'text-header'}>
          {icon}
          {title}
          <div>{count}</div>
        </div>
        {renderOrderNumbers(status)}
      </S.OrderListHeader>
    );
  };

  return (
    <S.OrderListContainer>
      <div className={'container'}>
        {renderOrderListHeader(EOrderStatusType.PENDING, <ReceiptWhite />, '대기')}
      </div>
      <div className={'divider'} />
      <div className={'container'}>
        {renderOrderListHeader(EOrderStatusType.COMPLETED, <Coffee />, '픽업')}
      </div>
      <button className={'audio-play-button'} onClick={handleTogglePlay}>
        {isPlaying ? '음성 안내 중지' : '음성 안내 시작'}
      </button>
    </S.OrderListContainer>
  );
};

export default OrderList;
