import { Search } from '@assets/icons';
import { DropdownStyle } from '@styles/dropdownStyle';
import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import ReactDropdown, { Option } from 'react-dropdown';
import { SearchDropdownStyle, SearchStyle } from './styles/SearchTableStyle';
import { SearchWeatherTypes, WeatherTypes } from '@typedef/Widget/weather.types';
import DatePicker from '@components/Common/DatePicker/DatePicker';
import { PeriodType } from '@components/Common/CommonDate/CommonDate';

export type DefaultFilterTypes = { [key in string]: any };

type Props<T> = {
  searchOptions: SearchTableType<T>[];
  handleOnChange: (option: SearchTableType<T>, input: string) => void;
  handleOnDateChange: (option: SearchTableType<T>, period: PeriodType) => void;
  handleOnSummit: (option?: SearchTableType<T>, input?: string) => void;
  handleResetButton: () => void;
  searchOption: SearchTableType<T>;
  searchInput: string;
  period: PeriodType;
  weatherList: WeatherTypes[];
  searchWeatherList: SearchWeatherTypes[];
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  locationShow: boolean;
  setLocationShow: React.Dispatch<React.SetStateAction<boolean>>;
  locationRef: React.RefObject<HTMLDivElement>;
};

const SearchTable = <T, >({
  searchOptions,
  handleOnChange,
  handleOnDateChange,
  handleOnSummit,
  handleResetButton,
  searchInput,
  period,
  searchOption,
  weatherList,
  searchWeatherList,
  location,
  setLocation,
  locationShow,
  setLocationShow,
  locationRef,
}: Props<T>) => {
  return (
    <SearchStyle>
      <DropdownStyle width={'150px'} fontSize={'14px'}>
        <ReactDropdown
          className="react-dropdown-main"
          menuClassName="react-dropdown-menu"
          controlClassName="react-dropdown-control"
          options={searchOptions.map((v) => {
            return { label: v.name, value: v.value as string };
          })}
          onChange={(value: Option) => {
            handleOnChange(searchOptions.find((v) => v.value === value.value)!, '');
          }}
          value={searchOption.value as string}
        />
      </DropdownStyle>
      {searchOption.type === 'string' && (
        <form>
          <div className="search-input">
            <label htmlFor="searchInput">
              <Search title="search" />
            </label>
            <input
              id="searchInput"
              type="text"
              placeholder="Search..."
              value={searchInput}
              onChange={(e) => {
                handleOnChange(searchOption, e.target.value);
              }}
            />
          </div>
        </form>
      )}
      {searchOption.type === 'dropdown' && (
        <SearchDropdownStyle isSelected={searchInput.length !== 0}>
          <ReactDropdown
            className="search-dropdown-main"
            menuClassName="search-dropdown-menu"
            controlClassName="search-dropdown-control"
            placeholder={'Select To Search'}
            placeholderClassName="search-dropdoen-placeholder"
            options={searchOption.dropdownValues?.map((v) => ({ label: v.name, value: v.value })) ?? []}
            onChange={(value: Option) => {
              handleOnChange(searchOption, value.value);
            }}
            value={searchInput}
          />
        </SearchDropdownStyle>
      )}
      {searchOption.type === 'date' && ( // 날짜
        <>
          <DatePicker
            type="startedAt"
            value={period.startedAt}
            handleOnChange={(value: string) => handleOnDateChange(searchOption, { ...period, startedAt: value })}
          />
          ~
          <DatePicker
            type="endedAt"
            value={period.endedAt}
            handleOnChange={(value: string) => handleOnDateChange(searchOption, { ...period, endedAt: value })}
          />
        </>
      )}
      {searchOption.type === 'weather' && ( // weather
        <form className="weather-search-form">
          <div className="search-input" ref={locationRef}>
            <label htmlFor="searchInput">
              <Search title="search" />
            </label>
            <input
              id="searchInput"
              type="text"
              placeholder="입력 후 클릭해주세요"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              onFocus={(e) => {
                e.preventDefault();
                setLocationShow(true);
              }}
            />
          </div>
          {locationShow && (
            <div className="weather-search-container">
              {searchWeatherList.map((data) => {
                if (!location) {
                  return (
                    <div
                      className="location"
                      onClick={() => {
                        setLocation(data.value);
                        handleOnChange(searchOption, data.id!);
                      }}
                    >
                      {data.value}
                    </div>
                  );
                } else if (data.value.indexOf(location!) !== -1) {
                  const [start, end] = data.value.split(location!);
                  return (
                    <div
                      className="location"
                      onClick={() => {
                        setLocation(data.value);
                        handleOnChange(searchOption, data.id!);
                      }}
                    >
                      {start}
                      <span className="highlight-location">{location}</span>
                      {end}
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          )}
        </form>
      )}
      <button className="search-button" onClick={() => handleOnSummit(searchOption)}>
        검색
      </button>
      <button className="reset-button" onClick={handleResetButton}>
        초기화
      </button>
    </SearchStyle>
  );
};

export default SearchTable;
