import { AccountTypes } from '@typedef/Account/account.types';
import React from 'react';
import * as S from '../styles/AccountManageStyle';

import 'react-dropdown/style.css';
import styled from 'styled-components';
import ReactDropdown, { Option } from 'react-dropdown';
import { DropdownStyle } from '@styles/dropdownStyle';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { Info } from '@assets/icons';

type Props = {
  id: string;
  type: string;
  accountData: AccountTypes;
  setAccountData: React.Dispatch<React.SetStateAction<AccountTypes>>;
  setDuplicateFlag: React.Dispatch<React.SetStateAction<boolean>>;
  onClickBtn: () => void;
  accountIdDuplicate: () => void;
  roleList: Option[];
};

const AccountManage = ({ id, type, accountData, setAccountData, onClickBtn, roleList }: Props) => {
  console.log(roleList);
  return (
    <S.Container className="modalBody">
      <article className="contents">
        {id === 'delete' ? (
          <div className="delete">
            <p>선택하신 계정들을 삭제하시겠습니까?</p>
          </div>
        ) : (
          <>
            <div className="split">
              <p className="title">아이디</p>
              <S.Input readonly={true}>
                <input
                  type="text"
                  value={accountData.userId}
                  readOnly={true}
                />
                <div className={'info-message'}><Info />아이디는 수정 할 수 없습니다.</div>
              </S.Input>
            </div>
            <div className="split">
              <p className="title">유저네임</p>
              <S.Input>
                <input
                  type="text"
                  value={accountData.userName}
                  onChange={(e) => {
                    setAccountData({
                      ...accountData,
                      userName: e.target.value,
                    });
                  }}
                  placeholder={'유저네임을 입력하세요.'}
                />
              </S.Input>
            </div>
            <div className="split">
              <p className="title">권한</p>
              <SelectBody>
                <DropdownStyle>
                  <ReactDropdown
                    options={roleList}
                    onChange={(value) => {
                      setAccountData({
                        ...accountData,
                        role: EAccountRole.valueOf(value.value)!,
                      });
                    }}
                    value={accountData.role.name}
                    placeholder="권한을 선택하세요"
                  />
                </DropdownStyle>
              </SelectBody>
            </div>
          </>
        )}
      </article>
      <article className="bottoms">
        <button className={id === 'delete' ? 'danger' : ''} onClick={onClickBtn}>
          {type}하기
        </button>
      </article>
    </S.Container>
  );
};

export default AccountManage;

const SelectBody = styled.div`
    width: 250px;
    height: 40px;

    .Dropdown-root, .Dropdown-control {
        height: 40px;
        background-color: #fcfcfc;
    }

    .Dropdown-option {
        padding: 10px 15px;
    }

    .Dropdown-control {
        padding: 0 15px;
        display: flex;
        align-items: center;
    }

    .Dropdown-placeholder {
        font-weight: 600;
    }
`;
