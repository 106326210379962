import React, { useCallback, useEffect, useRef, useState } from 'react';
import OrderList from '@components/Common/OrderList/OrderList';
import {
  EOrderStatusType,
  OrderListWidgetTypes,
  OrderTypes,
  orderWidgetStepList,
} from '@typedef/CafeOrderList/orderTypes';
import { apiRoute, requestGet } from '@libs/api';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { ContentTypes } from '@typedef/Contents/contents.types';
import { useSetRecoilState } from 'recoil';
import { modalSelector } from '@stories/modal';
import OrderListModal from '@components/Common/OrderList/OrderListModal';
import dayjs from 'dayjs';
import { CompatClient, IMessage, Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client/dist/sockjs';
import { API_URL } from '@libs/remote';

type Props = {
  isSetting?: boolean;
  content: ContentTypes;
};

const OrderListContainer: React.FC<Props> = ({ isSetting, content }) => {
  const companyId = content.companyId;

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const client = useRef<CompatClient>({} as CompatClient);
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioBufferRef = useRef<{ [key: string]: AudioBuffer | null }>({
    order: null,
    message: null,
  });

  const setModal = useSetRecoilState(modalSelector);

  const handleOpenModal = useCallback((message: string, type: 'order' | 'message') => {
    setModal({
      header: type === 'order' ? '제조 완료' : '공지',
      close: false,
      body: <OrderListModal numbers={message} type={type} />,
    });

    setTimeout(
      () => {
        setModal(null);
      },
      type === 'order' ? 6000 : 10000,
    );
  }, []);

  const [orderListData, setOrderListData] = useState<OrderListWidgetTypes<OrderTypes[]>>({
    PENDING: [],
    COMPLETED: [],
  });

  const loadOrderList = useCallback(
    async (status: EOrderStatusType, ttsAvailable: boolean) => {
      const startDate = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
      const endDate = dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

      const queryParams: Record<string, any> = {
        companyId: companyId,
        paged: false,
        status: status,
        startDate: startDate,
        endDate: endDate,
      };
      const { config, data } = await requestGet<PaginationTypes<OrderTypes>>(
        `${apiRoute.order.orderList}${parseQueryParamsToString(queryParams)}`,
        {},
      );

      if (config.status === 200) {
        setOrderListData((prev) => {
          const newState = { ...prev, [status]: data.content };

          if (status === EOrderStatusType.COMPLETED) {
            let prevCompletedOrders = prev[EOrderStatusType.COMPLETED];
            const prevOrderIds = new Set(prevCompletedOrders.map((order) => order.id));
            const newOrders = data.content.filter((order) => !prevOrderIds.has(order.id));

            if (newOrders.length > 0 && ttsAvailable) {
              const numberList = newOrders.map((order) => order.number).join(', ');
              playAudio('order');
              handleOpenModal(numberList, 'order');
            }
          }

          return newState;
        });
      }
    },
    [companyId],
  );

  const loadAudio = useCallback(async () => {
    const audioFiles = {
      order: '/assets/sound/sound-effect-order.mp3',
      message: '/assets/sound/sound-effect.mp3',
    };

    for (const [key, url] of Object.entries(audioFiles)) {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      if (audioContextRef.current) {
        audioBufferRef.current[key] = await audioContextRef.current.decodeAudioData(arrayBuffer);
      }
    }
  }, []);

  const playAudio = useCallback((type: 'order' | 'message') => {
    if (audioContextRef.current && audioBufferRef.current[type]) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBufferRef.current[type];
      source.connect(audioContextRef.current.destination);
      source.start();
      setIsPlaying(true);
      source.onended = () => setIsPlaying(false);
    } else {
      console.error(`Audio buffer for ${type} is not loaded`);
    }
  }, []);

  const handleTogglePlay = useCallback(() => {
    playAudio('order');
  }, [playAudio]);

  // 소켓 연결
  const connectWebsocket = () => {
    client.current = Stomp.over(() => new SockJS(API_URL + '/websocket'));

    client.current.connect({}, () => {
      // callback 함수로 sub을 해줍니다.
      client.current.subscribe(
        apiRoute.order.subscribeAlert(companyId),
        (message: IMessage) => {
          const object = JSON.parse(message.body);
          playAudio('message');
          handleOpenModal(object.message, 'message');
        },
        {},
      );

      client.current.subscribe(
        apiRoute.order.subscribeOrderFlag(companyId),
        (message: IMessage) => {
          const object = JSON.parse(message.body);
          if (object.flag) {
            orderWidgetStepList.forEach((status) => loadOrderList(status.status, true));
          }
        },
        {},
      );
    });

    client.current.onDisconnect = () => {
      console.log('Disconnected');
      setTimeout(connectWebsocket, 5000);
    };
  };

  useEffect(() => {
    connectWebsocket();
  }, []);

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
    loadAudio();

    orderWidgetStepList.forEach((status) => loadOrderList(status.status, false));
  }, [loadOrderList, loadAudio]);

  return (
    <>
      <OrderList
        isSetting={isSetting}
        orderListData={orderListData}
        isPlaying={isPlaying}
        handleTogglePlay={handleTogglePlay}
      />
    </>
  );
};

export default OrderListContainer;
