import { WidgetTypes } from '@typedef/Widget/widget.types';
import { FormEvent, useCallback, useEffect, useState } from 'react';

type Props = {
  selectLayout: WidgetTypes | null;
  handleOnChange: (id: string, value: any) => void;
}

const CustomIframe = ({ selectLayout, handleOnChange }: Props) => {
  const [currentValue, setCurrentValue] = useState<string>('');

  const handleSaveUrl = useCallback((e: FormEvent) => {
    e.preventDefault();
    handleOnChange('webUrl', currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (selectLayout?.webUrl) {
      setCurrentValue(selectLayout.webUrl);
    }
  }, []);

  return (
    <div className="custom">
      <form className="iframe-input" onSubmit={handleSaveUrl}>
        <p className="title">URL</p>
        <input
          type="text"
          value={currentValue}
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          placeholder={'URL을 입력해주세요.'}
        />
        <button type={'submit'}>저장</button>
      </form>
    </div>
  );
};

export default CustomIframe;