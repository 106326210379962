import * as S from '@components/CafeOrderList/styles/CafeOrdetListStyle';
import React from 'react';

type Props = {
  numbers: string;
  type: 'order' | 'message';
};

const OrderListModal: React.FC<Props> = ({ numbers, type }) => {
  return (
    <S.OrderListModalStyle className={'modalBody'}>
      <div className={type === 'order' ? 'numbers' : 'message'}>{numbers}</div>
      {type === 'order' && <div className={'text'}>고객님의 주문하신 음료가 준비되었습니다.</div>}
    </S.OrderListModalStyle>
  );
};

export default OrderListModal;
