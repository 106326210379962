import { EBackgroundThemeType } from '@typedef/Contents/contents.types';
import Cork from '@assets/images/contents-theme/cork.png';
import Paper from '@assets/images/contents-theme/paper.png';
import Cement from '@assets/images/contents-theme/cement.png';

export const getThemeBackground = (theme: EBackgroundThemeType) => {
  switch (theme) {
    case EBackgroundThemeType.CORK:
      return Cork;
    case EBackgroundThemeType.PAPER:
      return Paper;
    case EBackgroundThemeType.CEMENT:
      return Cement;
    default:
      return Cork;
  }
};