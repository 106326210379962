import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;

  .contents {
    text-align: center;
    p {
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: bold;
    }
    span {
      font-size: 16px;
      color: #f35d3c;
    }
  }
`;
