import { useRecoilValue, useSetRecoilState } from 'recoil';
import ScheduleManage from '../components/ScheduleManage';
import { useCallback, useEffect, useState } from 'react';
import { modalSelector } from '@stories/modal';
import { apiRoute, requestSecureDelete, requestSecurePatch, requestSecurePost } from '@libs/api';
import {
  DayOfTheWeek,
  EMonthlyRepeatType,
  RepetitionPeriodType,
  ScheduleResponseTypes,
  ScheduleTypes,
} from '@typedef/Schedule/schedule.types';
import { accountSelector } from '@stories/account';
import { ContentTypes } from '@typedef/Contents/contents.types';
import dayjs from 'dayjs';
import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import useDeviceScheduleState from '@hooks/useDeviceScheduleState';
import { DateTimeValidationError } from '@mui/x-date-pickers';
import { AccountTypes } from '@typedef/Account/account.types';

type Props = {
  account: AccountTypes;
  type: '추가' | '수정';
  reload: () => void;
  deviceId?: string;
  schedule?: ScheduleTypes;
  contentList: ContentTypes[];
};

const ScheduleManageContainer = ({ account, type, reload, deviceId, schedule, contentList }: Props) => {
  const [inputs, setInputs] = useState<ScheduleTypes>({
    deviceId: '',
    contentId: '',
    dailyEndTime: '',
    dailyStartTime: '',
    endDate: '',
    endTime: '',
    id: '',
    repetitionDayOfTheWeek: [],
    repetitionEndTime: '',
    repetitionType: EScheduleRepetitionType.ONCE,
    startDate: '',
    startTime: '',
    createdAt: '',
    updatedAt: '',
  });

  const token = useRecoilValue(accountSelector).accessToken;
  const setModal = useSetRecoilState(modalSelector);
  const { updateScheduleWithDeviceId } = useDeviceScheduleState();
  const [errorMessage, setErrorMessage] = useState<string | null>('');

  // 시작시간과 종료시간 비교하기 위해 밀리세컨드로 변환
  const timeTransform = useCallback((time: string) => {
    const hour = time.split(':')[0];
    const minute = time.split(':')[1];

    return Number(hour) * 60 * 60 * 1000 + Number(minute) * 60 * 1000;
  }, []);

  // 데이터 유무 검사, 정규식 검사, 중복 데이터 검사
  const validateInputs = useCallback(() => {
    if (!inputs.contentId) {
      alert('컨텐츠를 선택하세요');
      return false;
    }

    // 시간 입력
    if (!inputs.startTime || (!inputs.endTime && !inputs.dailyEndTime)) {
      alert('시간을 입력하세요');
      return false;
    }

    if (inputs.repetitionType === EScheduleRepetitionType.EVERY_MONTH && inputs.monthlyRepeatType === EMonthlyRepeatType.SPECIFIC_DATE && !inputs.repetitionDate) {
      alert('매월 반복 시 반복 일을 입력하세요');
      return false;
    }

    // // 반복 선택 시 종료일 입력
    // if (inputs.repetitionType && !inputs.repetitionEndTime) {
    //   alert('반복 종료 시간을 입력하세요');
    //   return false;
    // }

    // 반복 종료일이 종료일보다 이전인지 체크
    if (inputs.repetitionEndTime && inputs.endTime) {
      const repetitionEndTime = dayjs(inputs.repetitionEndTime);
      const endTime = dayjs(inputs.endTime);

      if (repetitionEndTime.isBefore(endTime)) {
        alert('반복 종료일이 종료일보다 이전입니다.');
        return false;
      }
    }

    return true;
  }, [inputs, timeTransform]);

  // 반복 유형 변경 콜백
  const onRepetitionTypeChanged = useCallback(
    (repetitionType: EScheduleRepetitionType) => {
      // if (!inputs.startTime || !inputs.endTime) {
      //   alert('시간을 입력하세요');
      //   return;
      // }
      if (inputs.repetitionType.equals(repetitionType)) {
        setInputs((prev) => ({
          ...prev,
          repetitionType: EScheduleRepetitionType.ONCE,
        }));
        return;
      }

      const startTime = dayjs(inputs.startTime).startOf('day');
      const endTime = dayjs(inputs.endTime).startOf('day');

      if (!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE)) {
        if (startTime.add(1, repetitionType.dayjsValue).isBefore(endTime)) {
          alert('반복주기가 기간보다 짧습니다.');
          return;
        }
      }

      setInputs((prev) => ({ ...prev, repetitionType: repetitionType }));
    },
    [inputs],
  );

  const onDayWeeksChanged = useCallback(
    (day: DayOfTheWeek) => {
      setInputs((prev) => {
        const { repetitionDayOfTheWeek = [] } = prev;

        // 배열에 day가 이미 포함되어 있다면 제거, 그렇지 않다면 추가
        const updatedRepetitionDayOfTheWeek = repetitionDayOfTheWeek.includes(day)
          ? repetitionDayOfTheWeek.filter((d) => d !== day)
          : [...repetitionDayOfTheWeek, day];

        return {
          ...prev,
          repetitionDayOfTheWeek: updatedRepetitionDayOfTheWeek,
        };
      });
    },
    [inputs],
  );

  const handleSelectMonth = useCallback(() => {
    if (!inputs.repetitionPeriod) {
      setInputs({
        ...inputs,
        repetitionPeriod: RepetitionPeriodType.ONE,
      });
    }
    if (inputs.repetitionDayOfTheWeek?.length === 0) {
      setInputs({
        ...inputs,
        repetitionDayOfTheWeek: [DayOfTheWeek.MON],
      });
    }
  }, [inputs]);

  // 스케줄 추가
  const onAddScheduleClicked = useCallback(async () => {
    if (!validateInputs()) return;

    const { config } = await requestSecurePost<ScheduleResponseTypes>(
      apiRoute.schedule.post,
      {},
      {
        deviceId: deviceId,
        contentId: inputs.contentId,
        startDate: inputs.startDate,
        startTime: inputs.startTime,
        endDate: inputs.endDate,
        endTime: inputs.endTime ?? inputs.dailyEndTime,
        repetitionDayOfTheWeek: inputs.repetitionDayOfTheWeek,
        repetitionEndDate: inputs.repetitionEndTime
          ? dayjs(inputs.repetitionEndTime).format('YYYY-MM-DDTHH:mm:ss')
          : null,
        repetitionType: inputs.repetitionType.value,
        monthlyRepeatType: inputs.monthlyRepeatType,
        repetitionPeriod: inputs.repetitionPeriod,
        repetitionDate: inputs.repetitionDate,
      },
      token,
    );

    if (config.status === 200) {
      setModal(null);

      reload();

      alert('스케줄이 추가되었습니다.');
    } else {
      alert(config.message);
    }
  }, [token, inputs, deviceId]);

  // 스케줄 수정
  const onUpdateScheduleClicked = useCallback(async () => {
    if (!validateInputs()) return;

    const { config } = await requestSecurePatch<ScheduleTypes>(
      apiRoute.schedule.update + schedule?.id,
      {},
      {
        deviceId: deviceId,
        contentId: inputs.contentId,
        startDate: inputs.startDate,
        startTime: inputs.startTime,
        endDate: inputs.endDate,
        endTime: inputs.endTime,
        repetitionDayOfTheWeek: inputs.repetitionDayOfTheWeek,
        repetitionEndDate: inputs.repetitionEndTime
          ? dayjs(inputs.repetitionEndTime).format('YYYY-MM-DDTHH:mm:ss')
          : null,
        repetitionType: inputs.repetitionType.value,
        monthlyRepeatType: inputs.monthlyRepeatType,
        repetitionPeriod: inputs.repetitionPeriod,
        repetitionDate: inputs.repetitionDate,
      },
      token,
    );

    if (config.status === 200) {
      setModal(null);

      reload();

      alert('스케줄이 수정되었습니다.');
    }
  }, [token, deviceId, inputs]);

  // 스케줄 삭제
  const onDeleteScheduleClicked = useCallback(async () => {
    const { config } = await requestSecureDelete(apiRoute.schedule.delete + schedule?.id, {}, {}, token);

    if (config.status === 200) {
      setModal(null);

      reload();

      alert('스케줄이 삭제되었습니다.');
    }
  }, [inputs, deviceId, token]);

  // 컨텐츠 미리보기
  const onPreviewClicked = useCallback(() => {
    window.open(`/preview-content/${inputs.contentId}`, '_blank', 'width=500,height=500');
  }, [inputs]);

  const handleErrorMessage = (error: DateTimeValidationError) => {
    setErrorMessage(
      error !== null && ['minTime', 'maxTime'].includes(error) ? '시작시간과 종료시간을 확인해 주세요.' : null,
    );
    console.error(
      error !== null && ['minTime', 'maxTime'].includes(error) ? '시작시간과 종료시간을 확인해 주세요.' : null,
    );
  };

  useEffect(() => {
    if (schedule) {
      setInputs(schedule);
    }
  }, []);

  return (
    <ScheduleManage
      account={account}
      type={type}
      onRepetitionTypeChanged={onRepetitionTypeChanged}
      onClickAddSchedule={onAddScheduleClicked}
      onClickUpdateSchedule={onUpdateScheduleClicked}
      onClickDeleteSchedule={onDeleteScheduleClicked}
      onDayWeeksChanged={onDayWeeksChanged}
      inputs={inputs}
      setScheduleData={setInputs}
      timeTransform={timeTransform}
      contentList={contentList}
      onClickPreview={onPreviewClicked}
      handleErrorMessage={handleErrorMessage}
      errorMessage={errorMessage}
      handleSelectMonth={handleSelectMonth}
    />
  );
};

export default ScheduleManageContainer;
