import React, { MutableRefObject, useMemo } from 'react';
import CustomBackgroundColor from '../components/CustomBackgroundColor';
import FontStyle from '../components/FontStyle';
import SubSpeed from '../components/SubSpeed';
import CustomNews from '../components/CustomNews';
import CustomWeather from '../components/CustomWeather';
import { FontStyleTypes } from '@typedef/Widget/font.types';
import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import { Bold, Italic, Underline } from '@assets/icons';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import CustomFileContainer from '@components/Widgets/containers/CustomFileContainer';
import CustomOpacity from '../components/CustomOpacity';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { weatherListSelector } from '@stories/weatherList';
import CustomResolution from '@components/Widgets/components/CustomResolution';
import CustomSmartBoardContainer from '@components/Widgets/containers/CustomSmartBoardContainer';
import { ContentTypes } from '@typedef/Contents/contents.types';
import Slider from 'react-slick';
import CustomIframe from '@components/Widgets/components/CustomIframe';

type Props = {
  selectLayout: WidgetTypes | null;
  selectContent: ContentTypes | null;
  layout: WidgetTypes[];
  handleOnChange: (id: string, value: any) => void;
  customDisplaySize: { width: number; height: number };
  sliderRef: MutableRefObject<Slider | null>;
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>;
  activeSlide: number;
};

const CustomContainer = ({
  selectLayout,
  selectContent,
  handleOnChange,
  customDisplaySize,
  sliderRef,
  setActiveSlide,
  activeSlide,
}: Props) => {
  const ROLE = useRecoilValue(accountSelector).role;
  const weatherList = useRecoilValue(weatherListSelector);


  // 굵기, 기울임, 밑줄 텍스트 스타일 리스트
  const textStyleList: FontStyleTypes[] = useMemo(() => {
    return [
      {
        name: 'weight',
        value: selectLayout?.textWeight ?? false,
        image: <Bold title="bold" />,
        id: 'textWeight',
      },
      {
        name: 'italic',
        value: selectLayout?.textItalic ?? false,
        image: <Italic title="italic" />,
        id: 'textItalic',
      },
      {
        name: 'underline',
        value: selectLayout?.textUnderline ?? false,
        image: <Underline title="underline" />,
        id: 'textUnderline',
      },
    ];
  }, [selectLayout]);

  if (!selectLayout) {
    return null;
  }

  return (
    <>
      {(selectLayout.type.equals(EWidgetType.FILE) || selectLayout.type.equals(EWidgetType.FILE_LIST)) && (
        <CustomFileContainer selectedLayout={selectLayout} handleOnChange={handleOnChange} />
      )}

      {!selectLayout.type.equals(EWidgetType.FILE) &&
        !selectLayout.type.equals(EWidgetType.FILE_LIST) &&
        !selectLayout.type.equals(EWidgetType.WEATHER) &&
        !selectLayout.type.equals(EWidgetType.STOCK_INDEX) &&
        !selectLayout.type.equals(EWidgetType.IFRAME) &&
        !selectLayout.type.equals(EWidgetType.SMART_BOARD) && (
          <CustomBackgroundColor handleOnChange={handleOnChange} selectLayout={selectLayout} />
        )}

      {selectLayout &&
        !selectLayout.type.equals(EWidgetType.FILE) &&
        !selectLayout.type.equals(EWidgetType.FILE_LIST) &&
        !selectLayout.type.equals(EWidgetType.TIME) &&
        !selectLayout.type.equals(EWidgetType.WEATHER) &&
        !selectLayout.type.equals(EWidgetType.STOCK_INDEX) &&
        !selectLayout.type.equals(EWidgetType.IFRAME) &&
        !selectLayout.type.equals(EWidgetType.SMART_BOARD) && (
          <CustomOpacity handleOnChange={handleOnChange} selectLayout={selectLayout} />
        )}
      {!(
        selectLayout.type.equals(EWidgetType.FILE) ||
        selectLayout.type.equals(EWidgetType.FILE_LIST) ||
        selectLayout.type.equals(EWidgetType.TIME) ||
        selectLayout.type.equals(EWidgetType.WEATHER) ||
        selectLayout.type.equals(EWidgetType.FINEDUST) ||
        selectLayout.type.equals(EWidgetType.STOCK_INDEX) ||
        selectLayout.type.equals(EWidgetType.SMART_BOARD) ||
        selectLayout.type.equals(EWidgetType.IFRAME)
      ) && <FontStyle textStyleList={textStyleList} handleOnChange={handleOnChange} selectLayout={selectLayout} />}

      {selectLayout.type.equals(EWidgetType.SMART_BOARD) && (
        <CustomSmartBoardContainer
          selectLayout={selectLayout}
          handleOnChange={handleOnChange}
          sliderRef={sliderRef}
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
        />
      )}

      {(selectLayout.type.equals(EWidgetType.TIME) ||
        selectLayout.type.equals(EWidgetType.WEATHER) ||
        selectLayout.type.equals(EWidgetType.FINEDUST) ||
        selectLayout.type.equals(EWidgetType.STOCK_INDEX) ||
        selectLayout.type.equals(EWidgetType.FILE) ||
        selectLayout.type.equals(EWidgetType.FILE_LIST)) && (
        <CustomResolution
          handleOnChange={handleOnChange}
          selectLayout={selectLayout}
          selectContent={selectContent}
          customDisplaySize={customDisplaySize}
        />
      )}

      {selectLayout.type.equals(EWidgetType.SUB) && (
        <SubSpeed handleOnChange={handleOnChange} selectLayout={selectLayout} />
      )}
      {selectLayout.type.equals(EWidgetType.NEWS) && (
        <div>
          <SubSpeed handleOnChange={handleOnChange} selectLayout={selectLayout} />
          {!EAccountRole.USER.equals(ROLE) && (
            <CustomNews handleOnChange={handleOnChange} selectLayout={selectLayout} />
          )}
        </div>
      )}
      {selectLayout.type.equals(EWidgetType.IFRAME) && (
        <CustomIframe selectLayout={selectLayout} handleOnChange={handleOnChange} />
      )}
      {(selectLayout.type.equals(EWidgetType.WEATHER) || selectLayout.type.equals(EWidgetType.FINEDUST)) && (
        <div>
          {!EAccountRole.USER.equals(ROLE) && (
            <CustomWeather handleOnChange={handleOnChange} selectedLayout={selectLayout} weatherList={weatherList} />
          )}
        </div>
      )}
    </>
  );
};

export default CustomContainer;
