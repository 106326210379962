export type EDeviceOrientationProps = 'COLUMN' | 'ROW';

export class EDeviceOrientation {
  static readonly COLUMN: EDeviceOrientation = new EDeviceOrientation('COLUMN', '세로');
  static readonly ROW: EDeviceOrientation = new EDeviceOrientation('ROW', '가로');

  private constructor(
    public readonly value: EDeviceOrientationProps,
    public readonly name: string,
  ) {}
  static readonly values = [EDeviceOrientation.ROW, EDeviceOrientation.COLUMN];

  static valueOf(value: EDeviceOrientationProps | string): EDeviceOrientation | undefined {
    return EDeviceOrientation.values.find((v) => v.value === value);
  }

  static isValue(value: string): boolean {
    return !!EDeviceOrientation.values.find((v) => v.value === value);
  }

  equals(value: EDeviceOrientationProps | EDeviceOrientation) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
