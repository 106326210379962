import styled from 'styled-components';

import React, { useState } from 'react';
import { DeleteRed } from '@assets/icons';
import { getFileNameFromMinioURL } from '@libs/fileUtil';
import { EFileType } from '@typedef/Widget/file-type.enum';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import { MINIO_URL } from '@libs/remote';

interface Props {
  selectedLayout: WidgetTypes;
  value: string;
  index: number;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>, isMultiple: boolean, value: string) => void;
  handleFileRemove: (value: string) => void;
}

const FileBox = ({ selectedLayout, value, index, handleFileChange, handleFileRemove }: Props) => {
  const [isHovering, setIsHovering] = useState(false);

  const isImage = EFileType.IMAGE.validate(selectedLayout.file!);
  const isVideo = EFileType.VIDEO.validate(selectedLayout.file!);

  return (
    <FileBoxWrapper>
      <input type={'file'} onChange={(e) => handleFileChange(e, false, value)} />
      <label htmlFor={value} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <span>{getFileNameFromMinioURL(value)}</span>
        <button onClick={() => handleFileRemove(value)}>
          <DeleteRed className={'delete-image'} />
        </button>
      </label>
      {isHovering && (
        <Preview>
          <p>미리보기</p>
          {isImage && <img src={`${MINIO_URL}/${value}`} alt="preview" />}
          {isVideo && <video src={`${MINIO_URL}/${value}`} autoPlay={true} muted={true} loop={true} />}
        </Preview>
      )}
    </FileBoxWrapper>
  );
};

const FileBoxWrapper = styled.div`
    position: relative;
    margin-top: 10px;
    width: 100%;
    height: 40px;

    &:first-child {
        margin-top: 0;
    }

    & > input[type='file'] {
        display: none;
    }

    & > label {
        border-radius: 7px;
        width: 100%;
        height: 100%;
        border: 1px dashed rgb(43, 62, 99);
        color: rgb(43, 62, 99);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        background: #fff;

        > span {
            width: 140px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
        }

        > button {
            height: 100%;
            background: none;
            border: none;

            > .delete-image {
                margin-top: 5px;
                width: 20px;
                cursor: pointer;
            }
        }
    }
`;

const Preview = styled.div`
    position: fixed;
    padding: 7px;
    width: 200px;
    height: auto;
    background: rgba(0, 0, 0, .6);
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 50;
    backdrop-filter: blur(1px);

    &::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid rgba(0, 0, 0, .6);
        z-index: 51;
    }

    p {
        margin-bottom: 5px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
    }

    img, video {
        width: 100%;
        object-fit: contain;
        border-radius: 4px;
    }
`;

export default FileBox;