import '../styles/CustomStyle.scss';
import ReactDropdown, { Option } from 'react-dropdown';
import { EFileStreamingType } from '@typedef/Widget/file-streaming-type.enum';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import React, { ChangeEvent } from 'react';
import { AddImage, Video } from '@assets/icons';
import styled from 'styled-components';
import { EFileType } from '@typedef/Widget/file-type.enum';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import FileBox from '@components/Widgets/components/FileBox';

type Props = {
  selectedLayout: WidgetTypes;
  handleOnChange: (id: string, value: any) => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>, add: boolean, uuid?: string) => void;
  handleFileRemove: (uuid: string) => void;
};

const CustomFile = ({ selectedLayout, handleOnChange, handleFileChange, handleFileRemove }: Props) => {
  return (
    <div className="custom">
      <div className="weather-custom">
        <div className="container video">
          {EFileType.VIDEO.validate(selectedLayout.file!) && (
            <>
              <p className="title">파일 스트리밍 방식</p>
              <ReactDropdown
                options={EFileStreamingType.values.map((fileStreamingType) => ({
                  value: fileStreamingType.value,
                  label: fileStreamingType.name,
                }))}
                className={'weather-dropdown'}
                value={selectedLayout.fileStreamingType}
                onChange={(value: Option) => {
                  handleOnChange('fileStreamingType', value.value);
                }}
              />

              <>
                <p className="title">동영상 재생 리스트</p>
                <FileAddBox $button={true}>
                  <input id={'image-slide-file'} type={'file'} onChange={(e) => handleFileChange(e, true)} />
                  <label htmlFor={'image-slide-file'}>
                    <Video className={'add-image'} /> 동영상 추가
                  </label>
                </FileAddBox>

                <FileBoxContainer>
                  {selectedLayout.files?.map((value, index) => (
                    <FileBox key={index} selectedLayout={selectedLayout} value={value}
                             handleFileChange={handleFileChange}
                             handleFileRemove={handleFileRemove} index={index} />
                  ))}
                </FileBoxContainer>
              </>
            </>
          )}
          {(EFileType.IMAGE.validate(selectedLayout.file!) || EFileType.SLIDEIMAGE.validate(selectedLayout.file!)) && (
            <div className={'slide-controller'}>
              <p className="title image-slide">
                이미지 슬라이드
                <label className="switch">
                  <input
                    type="checkbox"
                    name="showSlide"
                    checked={selectedLayout.type.equals(EWidgetType.FILE_LIST)}
                    onChange={(v) => {
                      if (v.target.checked) handleOnChange('type', EWidgetType.FILE_LIST);
                      else handleOnChange('type', EWidgetType.FILE);
                    }}
                  />
                  <span className="slider" />
                </label>
              </p>

              {selectedLayout.type.equals(EWidgetType.FILE_LIST) && (
                <>
                  <SlideSpeedBox>
                    <div>슬라이드 초 설정</div>
                    <input
                      placeholder={'0.0'}
                      name="autoPlaySpeed"
                      onChange={(v) => handleOnChange('autoPlaySpeed', v.target.value)}
                      value={selectedLayout.autoPlaySpeed}
                    />
                  </SlideSpeedBox>

                  <FileAddBox $button={true}>
                    <input id={'image-slide-file'} type={'file'} onChange={(e) => handleFileChange(e, true)} />
                    <label htmlFor={'image-slide-file'}>
                      <AddImage className={'add-image'} /> 이미지 추가
                    </label>
                  </FileAddBox>

                  <FileBoxContainer>
                    {selectedLayout.files?.map((value, index) => (
                      <FileBox key={index} index={index} handleFileRemove={handleFileRemove}
                               handleFileChange={handleFileChange} value={value} selectedLayout={selectedLayout} />
                    ))}
                  </FileBoxContainer>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SlideSpeedBox = styled.div`
    width: 100%;
    height: 25px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > input {
        width: 60px;
        display: flex;
        border: none;
        height: 25px;
        background: #f7f7f7;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;

        &:focus {
            outline: 2px solid rgb(43, 62, 99);
        }
    }
`;

const FileAddBox = styled.div<{ $button?: boolean }>`
    margin-top: 10px;
    width: 100%;
    height: 40px;

    &:first-child {
        margin-top: 0;
    }

    & > button {
        width: 100%;
        height: 100%;
        border-radius: 7px;
        border: 1px solid rgb(43, 62, 99);
        background: #fff;
        color: rgb(43, 62, 99);
        transition: background 0.25s,
        color 0.25s;
        cursor: pointer;

        &:hover {
            background: rgb(43, 62, 99);
            color: #fff;
        }
    }

    & > input[type='file'] {
        display: none;
    }

    & > label {
        border-radius: 7px;
        width: 100%;
        height: 100%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        background: rgb(43, 62, 99);

        > .add-image {
            width: 18px;
            margin: 0 5px 1px 0;
        }

        > span {
            width: 140px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
        }

        > button {
            height: 100%;
            background: none;
            border: none;
        }
    }
`;

const FileBoxContainer = styled.div`
    margin-top: 10px;
    width: 100%;
    height: auto;
    max-height: 240px;
`;

export default CustomFile;
