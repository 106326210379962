import styled from 'styled-components';

const space = '15px';
const height = '40px';

export const Container = styled.main`
  width: 100%;
  height: 100%;

  .dropdown-arrow {
    position: relative;
    right: 0;
    top: 0;
    border-color: #999 transparent transparent;
    border-width: 5px 5px 0;
  }
`;

export const Header = styled.header`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  display: flex;
  justify-content: flex-start;
  font-size: 24px;
  font-weight: bold;

  & > .title {
    display: flex;

    & > div {
      font-size: 24px;
      font-weight: bold;
    }

    & > div:first-child {
      cursor: pointer;
      display: flex;

      &:after {
        content: ' ';
        width: 3px;
        height: 1em;
        display: block;
        margin: 0 15px;
        background-color: #191919;
      }
    }
  }

  .search {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: ${height};

    & > .download-button {
      width: 105px;
      height: 40px;
      background: #256662;
      border-radius: 6px;
      border: none;
      color: #fff;
      font-size: 15px;
    }

    & .react-dropdown-main {
      font-size: 1rem;
    }

    .search-input {
      ${({ theme }) => theme.flex.row};
      align-items: center;
      background-color: #fff;
      border-radius: 5px;

      label {
        cursor: text;
        margin-left: 15px;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      input[type='text'] {
        height: ${height};
        padding: 0 ${space} 0 5px;
        font-size: 15px;
        border: none;
        border-radius: 5px;

        &::placeholder {
          font-size: 16px;
          color: #d3d3d3;
          font-family: transparent;
        }

        &:focus {
          outline: none;
        }
      }

      button {
        margin-right: 10px;
        background-color: transparent;
        border: none;
        padding: 0;

        svg {
          display: flex;
          align-items: center;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;

export const Article = styled.article`
  margin-top: 30px;

  .background {
    ${({ theme }) => theme.flex.col};
    padding: ${space};
    background-color: #fff;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    border-radius: 7px;
    height: 70vh;

    .tableHeader {
      /* Content에서 생기는 scroll만큼 밀어냄 */
      width: calc(100% - 7px);
      padding-bottom: 5px;

      border-bottom: 1px solid #ddd;
    }

    .tableContent {
      height: 100%;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #ccc;
      }
    }

    table {
      width: 100%;
      table-layout: fixed;

      thead {
        tr {
          th {
            padding: ${space} 0;
            text-align: center;
            font-size: 17px;
            font-weight: 600;

            input[type='checkbox'] {
              cursor: pointer;
              scale: 1.3;
            }
          }
        }
      }
    }
  }
`;

export const Tr = styled.tr<{ $checked: boolean }>`
  cursor: pointer;
  background-color: ${({ $checked }) => ($checked ? '#e0f1eb' : '#ffffff')};
  height: 6.22vh;

  td {
    vertical-align: middle;
    padding: 10px 0;
    text-align: center;
    font-size: 15px;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    input[type='checkbox'] {
      cursor: pointer;
      scale: 1.3;

      &:checked {
        background-color: #fff;
      }
    }

    select {
      width: 100%;
      border-radius: 5px;
    }

    a {
      color: #8ab4f8;
    }

    & > span {
      color: #b1b1b1;
    }
  }

  &:hover {
    background-color: ${({ $checked }) => ($checked ? '#e0f1eb' : '#f0f0f0')};
  }
`;

export const PaginationButton = styled.button<{ $active: boolean }>`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  border: none;
  font-size: 20px;
  transition:
    background 0.25s,
    color 0.25s;

  background-color: ${({ $active }) => ($active ? '#256662' : '#E6E6E6')};
  color: ${({ $active }) => ($active ? '#fff' : '#555')};
`;
