import React, { useEffect, useState } from 'react';
import Weather from '../Weather';
import {
  WeatherCloudyDay,
  WeatherCloudyNight,
  WeatherOvercastDay,
  WeatherOvercastNight,
  WeatherRainy,
  WeatherRainySnowy,
  WeatherSnowy,
  WeatherSunnyDay,
  WeatherSunnyNight,
} from '@assets/icons';
import { apiRoute, requestGet } from '@libs/api';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import { getResolutionValue } from '@libs/getResolutionValue';
import { ContentTypes } from '@typedef/Contents/contents.types';

type Props = {
  isSetting: boolean;
  size: {
    width: number;
    height: number;
  };
  widget: WidgetTypes;
  content: ContentTypes;
};

export type weatherProps = {
  precipitationForm: number | undefined;
  temperaturePerHour: number | undefined;
  minTemperatureInDay: number | undefined;
  maxTemperatureInDay: number | undefined;
  skyStatus: number | undefined;
  snowPerHour: string | undefined;
  precipitationPerHour: string | undefined;
};

const WeatherContainer = ({ isSetting, size, widget, content }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [weatherData, setWeatherData] = useState<weatherProps>({
    temperaturePerHour: undefined,
    maxTemperatureInDay: undefined,
    minTemperatureInDay: undefined,
    precipitationForm: undefined,
    precipitationPerHour: undefined,
    skyStatus: undefined,
    snowPerHour: undefined,
  });
  const [error, setError] = useState<boolean>(false);

  const resolutionIconLocation: { top: string[]; right: string[] } = {
    top: ['9:16', '1:1'],
    right: ['16:9', '32:9', '4:3'],
  };

  const getWeatherData = async () => {
    const { data, config } = await requestGet<any>(
      apiRoute.weather.getWeatherDataWithoutJWT.replace('{weatherId}', String(widget.weatherId)),
      {},
    );

    if (config.status === 200) {
      setWeatherData({
        temperaturePerHour: data.temperaturePerHour,
        maxTemperatureInDay: data.maxTemperatureInDay,
        minTemperatureInDay: data.minTemperatureInDay,
        precipitationForm: data.precipitationForm,
        precipitationPerHour: data.precipitationPerHour,
        skyStatus: data.skyStatus,
        snowPerHour: data.snowPerHour,
      });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    getWeatherData().then(() => setLoading(false));
  }, []);

  const getIcon = (): React.ReactElement | null => {
    const currentHours = new Date().getHours();
    const weather = weatherData;

    if (weather.snowPerHour !== '적설없음' && weather.precipitationPerHour !== '강수없음') return <WeatherRainySnowy />;
    else if (weather.snowPerHour !== '적설없음') return <WeatherSnowy />;
    else if (weather.precipitationPerHour !== '강수없음') return <WeatherRainy />;
    else if (weather.skyStatus! >= 0 && weather.skyStatus! <= 5) {
      if (currentHours >= 19) return <WeatherSunnyNight />;
      else return <WeatherSunnyDay />;
    } else if (weather.skyStatus! >= 6 && weather.skyStatus! <= 8) {
      if (currentHours >= 19) return <WeatherCloudyNight />;
      else return <WeatherCloudyDay />;
    } else {
      if (currentHours >= 19) return <WeatherOvercastNight />;
      else return <WeatherOvercastDay />;
    }
  };

  // const is;

  return (
    <Weather
      weatherData={weatherData}
      error={error}
      isSetting={isSetting}
      size={size}
      getIcon={getIcon}
      loading={loading}
      resolution={getResolutionValue(widget.resolution)}
      content={content}
      resolutionIconLocation={resolutionIconLocation}
    />
  );
};

export default WeatherContainer;
