import { Dayjs } from 'dayjs';
import { EDeviceOrientation } from './device-orientation.enum';
import { EDeviceType } from './device-type.enum';
import { EDeviceStatus } from './device-status.enum';
import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import { MonitoringDeviceType, MonitoringResponseDeviceType } from '@typedef/Monitoring/monitoring.type';

export type DeviceTypes = {
  id: string;
  name: string;
  location: string;
  ip: string;
  macAddress?: string;
  displayType?: EDeviceOrientation;
  width?: string;
  height?: string;
  status: EDeviceStatus;
  isPowerManageable: boolean;
  currentPlayImg?: string;
  companyId: string;
  uniqueKey?: string;
  zoneId?: string;
  createdAt?: string;
  updatedAt?: string;
  statusUpdatedAt: string;
  type: EDeviceType;
};

export type DeviceResponseTypes = Omit<DeviceTypes, 'displayType' | 'status' | 'type'> & {
  displayType?: string;
  status: string;
  type: string;
};

export const defaultDeviceTypes: DeviceTypes = {
  id: '',
  name: '',
  location: '',
  ip: '',
  displayType: EDeviceOrientation.ROW,
  isPowerManageable: false,
  companyId: '',
  type: EDeviceType.BOARD,
  status: EDeviceStatus.OFF,
  statusUpdatedAt: '',
};

export type DeviceImageTypes = {
  deviceStatus: EDeviceStatus;
  fileUrl?: string;
  lastDeviceImageUpdate: Dayjs;
  contentsName?: string;
};

export type DeviceImageMapTypes = Map<string, DeviceImageTypes>;

export type DeviceScheduleTypes = {
  status: boolean;
  contentId: string;
};

export type DeviceScheduleMapTypes = Map<string, DeviceScheduleTypes>;

export function convertDeviceResponseTypeToType(
  device: DeviceResponseTypes | MonitoringResponseDeviceType,
): DeviceTypes | MonitoringDeviceType {
  return {
    ...device,
    displayType: device.displayType ? EDeviceOrientation.valueOf(device.displayType) : undefined,
    type: EDeviceType.valueOf(device.type)!,
    status: EDeviceStatus.valueOf(device.status)!,
  };
}

export function convertDeviceTypeToResponseType(
  device: DeviceTypes | MonitoringDeviceType,
): DeviceResponseTypes | MonitoringResponseDeviceType {
  return {
    ...device,
    displayType: device.displayType?.value,
    type: device.type.value!,
    status: device.status?.value,
  };
}

export const deciceSearchOptions: SearchTableType<DeviceTypes>[] = [
  {
    value: 'name',
    name: '이름',
    type: 'string',
  },
  {
    value: 'location',
    name: '위치',
    type: 'string',
  },
  {
    value: 'displayType',
    name: '디스플레이 방향',
    type: 'dropdown',
    dropdownValues: EDeviceOrientation.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
  {
    value: 'type',
    name: '장비 유형',
    type: 'dropdown',
    dropdownValues: EDeviceType.values.map((v) => {
      return { name: v.name, value: v.value };
    }),
  },
  {
    value: 'ip',
    name: 'IP',
    type: 'string',
  },
  {
    value: 'macAddress',
    name: 'MAC',
    type: 'string',
  },
  {
    value: 'uniqueKey',
    name: '장비 키',
    type: 'string',
  },
];
