export function calculateBoardSize(
  maxWidth: number,
  maxHeight: number,
  targetRatio: number,
): { width: number; height: number } {
  if (maxWidth <= 0 || maxHeight <= 0 || targetRatio <= 0) {
    return { width: 0, height: 0 };
  }

  let boxWidth: number, boxHeight: number;

  if (maxWidth / maxHeight > targetRatio) {
    boxWidth = maxHeight * targetRatio;
    boxHeight = maxHeight;
  } else {
    boxWidth = maxWidth;
    boxHeight = maxWidth / targetRatio;
  }

  return { width: boxWidth, height: boxHeight };
}
