import './styles/Modal.scss';
import { useRecoilState } from 'recoil';
import { modalSelector } from '@stories/modal';
import { Close } from '@assets/icons';

const Modal = () => {
  const [modal, setModal] = useRecoilState(modalSelector);

  return (
    modal && (
      <div className="modalBg">
        <div className="modal">
          <header>
            <p>{modal.header}</p>
            {modal.close && (
              <button
                onClick={() => {
                  modal.onClose && modal.onClose();
                  setModal(null);
                }}
              >
                <Close />
              </button>
            )}
          </header>
          {modal.body}
        </div>
      </div>
    )
  );
};

export default Modal;
