import * as S from '@components/Common/SmartBoard/styles/SmartBoardStyle';
import 'react-quill/dist/quill.snow.css';
import { ContentTypes, EBackgroundType } from '@typedef/Contents/contents.types';
import { SelectBoardPostType, WidgetTypes } from '@typedef/Widget/widget.types';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Contents } from '@assets/icons';
import WidgetTemplate from '../WidgetTemplate/WidgetTemplate';
import { CustomSlider } from '@components/Widgets/styles/WidgetStyle';
import Slider from 'react-slick';

type Props = {
  widget: WidgetTypes;
  content: ContentTypes;
  posts?: { id: string }[];
  selectPost: SelectBoardPostType | null;
  setSelectPost: React.Dispatch<React.SetStateAction<SelectBoardPostType | null>>;
  isSetting?: boolean;
  sliderRef: MutableRefObject<Slider | null>;
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>;
  activeSlide: number;
};

const SmartBoard: React.FC<Props> = ({
  widget,
  content,
  setSelectPost,
  sliderRef,
  setActiveSlide,
  activeSlide,
  selectPost,
  isSetting,
}) => {
  const [postWidth, setPostWidth] = useState<number>();

  const [isAddLength, setIsAddLength] = useState<{ widthLength?: number; heightLength?: number }>();

  const postRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<(HTMLDivElement | null)[]>([]);

  const getSmartBoardRowStyle = () => {
    let num = 0;

    rowRef.current.map((row) => {
      if (widget.boardFiles?.length === 1) return { width: '100%', height: 'auto' };
      else if (row !== null && postRef.current?.offsetWidth) {
        if (row.offsetWidth > postRef.current?.offsetWidth) {
          if (
            row.outerHTML.includes(`calc(${Math.floor(100 / ((widget.boardFiles?.length || 1) + num))}`) &&
            row.offsetWidth > (postRef.current?.offsetWidth || 1)
          ) {
            console.log('here!');
            console.log(`${row.offsetWidth} > ${postRef.current?.offsetWidth}`);
            num = num + 1;
          }
        }

        console.log(`num = ${num}`);
        row.style.setProperty('width', 'max-content');
        row.style.setProperty('height', `calc(${100 / ((widget.boardFiles?.length || 1) + num)}% - ${widget.gap}px`);
      }
    });

    if (widget.boardFiles?.length === 1) return { width: '100%', height: 'auto' };
  };

  useEffect(() => {
    getSmartBoardRowStyle();
  }, [widget.boardFiles]);

  useEffect(() => {
    if (widget.boardFiles) {
      setPostWidth(((postRef.current?.offsetWidth || 1) - (widget.padding || 0) * 2) / (widget.boardFiles.length || 1));
    }
  }, [widget, postRef.current?.offsetWidth]);

  const mergedBoardFiles = [
    widget,
    ...(widget.boardSlides?.map((slide) => ({ ...slide })) ?? []),
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: !isSetting,
    autoplaySpeed: Number(widget.autoPlaySpeed ?? 1) * 1000,
    nextArrow: <></>,
    prevArrow: <></>,
    pauseOnHover: false,
    swipe: false,
    beforeChange: (currentSlide: number, nextSlide: number) => {
      isSetting && setActiveSlide(nextSlide);
    },
  };

  useEffect(() => {
    if (isAddLength?.widthLength !== widget.boardFiles?.find((_, i) => i === 0)?.length) {
      setIsAddLength({
        widthLength: widget.boardFiles?.find((_, i) => i === 0)?.length,
        heightLength: isAddLength?.heightLength,
      });
    } else if (isAddLength?.heightLength !== widget.boardFiles?.length) {
      setIsAddLength({ widthLength: isAddLength?.widthLength, heightLength: widget.boardFiles?.length });
    }
  }, [widget.boardFiles]);

  return (
    <CustomSlider
      {...settings}
      ref={slider => {
        if (sliderRef) sliderRef.current = slider;
      }}
    >
      {mergedBoardFiles.map((boardSlide, index) => (
        <S.SmartBoardStyle
          ref={(ref) => ref !== null && rowRef.current.push(ref)}
          backgroundTheme={boardSlide.backgroundType === EBackgroundType.THEME ? boardSlide.backgroundTheme : undefined}
          padding={boardSlide.padding}
          gap={boardSlide.gap}
          backgroundColor={boardSlide.backgroundColor}
          key={`board-slide-${index}`}
          style={getSmartBoardRowStyle()}
        >
          {boardSlide.boardFiles?.map((data, i) => (
            <S.SmartBoardRow key={`post-row-${i}`} gap={boardSlide.gap}>
              {data.map((post, j) => (
                <WidgetTemplate
                  onClick={(e) => {
                    if (!isSetting) return;
                    e.stopPropagation();
                    setSelectPost({ ...post, index: { row: i, column: j } });
                  }}
                  template={post.template}
                  templateColor={post.templateColor}
                >
                  <S.SmartBoardPost
                    key={`post-${j}`}
                    width={widget.w / data.length}
                    contentWidth={content.w}
                    contentHeight={content.h}
                    height={Math.round((postWidth ? postWidth : widget.w) * 1.414)}
                    fileUrl={post.backgroundImage}
                    active={selectPost?.index.row === i && selectPost?.index.column === j}
                    boxShadow={post.shadow}
                    isSetting={isSetting}
                    style={{
                      height: '100%',
                      aspectRatio: 1 / 1.414,
                    }}
                  >
                    {
                      !post?.backgroundImage && isSetting &&
                      <>
                        <Contents />
                        <div className={'message'}>컨텐츠 추가</div>
                      </>
                    }
                  </S.SmartBoardPost>
                </WidgetTemplate>
              ))}
            </S.SmartBoardRow>
          ))}
        </S.SmartBoardStyle>
      ))}
    </CustomSlider>
  );
};

export default SmartBoard;
